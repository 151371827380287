import './Timeseries.css';

const EquipmentDtlTable = () =>{

    return(<>
        <table className="eqipmntTable" style={{borderRadius:'10px'}}>
            <thead>
                <tr>
                    <th>EQUIPMENT TYPE</th>

                    <th>EQUIPMENT ID</th>
                    <th>PRODUCT</th>
                </tr>
            </thead>
            <tbody>
                    <tr>
                        <td style={{borderBottomLeftRadius:'10px'}}>Heater</td>
                        <td>E-100</td>
                        <td style={{borderBottomRightRadius:'10px'}}>Crude Oil</td>
                    </tr>
            </tbody>
         
        </table>
    </>);
};

export default EquipmentDtlTable;