// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-overlay-quest {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.553);
    z-index: 10000;
  }
  
  .modal-doa-quest {
    background-color: transparent;
    margin-left: 30px;
    margin-top: 50px;
    width: 900px;
    height: 450px;
    padding: 20px;
    border-radius: 8px;
    /* box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5); */
  }
  
  .close-icon-quest{
    /* top: 120px;
    right: 140px; */
    margin-left: 320px;
    width: 20px;
    font-size: 24px;
     position: absolute;
    cursor: pointer;
    color: blueviolet;
    background-color: transparent;
    border: none;
    border-radius: 5px;
  }
  
  .modal-content-quest{
    margin-top: 20px;
    padding-left: 30px;
    padding-right: 30px;
    overflow-y: auto;
    background-color:  rgba(30, 32, 61, 1);
    width: 900px;
    height: 350px;
    border-radius: 10px;
  }
  
.analysis-head2-quest{
    color: #FFFFFF;
    font-family: 'Graphik';
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 10px;
    padding-bottom: 10px;
    padding-top: 10px;
    text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/CorrosionQuest.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,sCAAsC;IACtC,cAAc;EAChB;;EAEA;IACE,6BAA6B;IAC7B,iBAAiB;IACjB,gBAAgB;IAChB,YAAY;IACZ,aAAa;IACb,aAAa;IACb,kBAAkB;IAClB,qDAAqD;EACvD;;EAEA;IACE;mBACe;IACf,kBAAkB;IAClB,WAAW;IACX,eAAe;KACd,kBAAkB;IACnB,eAAe;IACf,iBAAiB;IACjB,6BAA6B;IAC7B,YAAY;IACZ,kBAAkB;EACpB;;EAEA;IACE,gBAAgB;IAChB,kBAAkB;IAClB,mBAAmB;IACnB,gBAAgB;IAChB,sCAAsC;IACtC,YAAY;IACZ,aAAa;IACb,mBAAmB;EACrB;;AAEF;IACI,cAAc;IACd,sBAAsB;IACtB,gBAAgB;IAChB,eAAe;IACf,mBAAmB;IACnB,oBAAoB;IACpB,iBAAiB;IACjB,kBAAkB;AACtB","sourcesContent":[".modal-overlay-quest {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(0, 0, 0, 0.553);\n    z-index: 10000;\n  }\n  \n  .modal-doa-quest {\n    background-color: transparent;\n    margin-left: 30px;\n    margin-top: 50px;\n    width: 900px;\n    height: 450px;\n    padding: 20px;\n    border-radius: 8px;\n    /* box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5); */\n  }\n  \n  .close-icon-quest{\n    /* top: 120px;\n    right: 140px; */\n    margin-left: 320px;\n    width: 20px;\n    font-size: 24px;\n     position: absolute;\n    cursor: pointer;\n    color: blueviolet;\n    background-color: transparent;\n    border: none;\n    border-radius: 5px;\n  }\n  \n  .modal-content-quest{\n    margin-top: 20px;\n    padding-left: 30px;\n    padding-right: 30px;\n    overflow-y: auto;\n    background-color:  rgba(30, 32, 61, 1);\n    width: 900px;\n    height: 350px;\n    border-radius: 10px;\n  }\n  \n.analysis-head2-quest{\n    color: #FFFFFF;\n    font-family: 'Graphik';\n    font-weight: 500;\n    font-size: 14px;\n    margin-bottom: 10px;\n    padding-bottom: 10px;\n    padding-top: 10px;\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
