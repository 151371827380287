import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HC_exporting from 'highcharts/modules/exporting';
import HC_exportData from 'highcharts/modules/export-data';
HC_exporting(Highcharts);
HC_exportData(Highcharts);

const BarChartCustomer = ({ responseData }) => {

  try{
    const chartOptions = {
      chart: {
        type: 'column',
        width: 400,
        height:'50%',
        marginRight:30,
        backgroundColor: 'transparent'
      },
      title: {
        text: '',
      },
      credits: {
          enabled:false
      },
      exporting: {
        enabled: true // Disable the exporting functionality (burger menu)
      },
    //   xAxis: {
    //     categories:  responseData.response_data.bar.data.map((item) => item[0]), // Circuit names
    //     title: {
    //       text: responseData.response_data.bar.columns[0],
    //     },
    //     labels:{
    //       style:{
    //         fontSize:'12px'
    //       }
    //     }
    //   },
      
      xAxis: {
        categories: ['USA', 'China', 'Brazil'],
        crosshair: true,
        accessibility: {
            description: 'Countries'
        }
    },
      yAxis: {
        // title: {
        //   text: responseData.response_data.bar.columns[1],
        // },
        min: 0,
        title: {
            text: '1000 metric tons (MT)'
        }
      },
      colors:['#DCAFFF','#A100FF'],
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true
          },
         
        },
      },
      legend: {
        enabled: true,
      },
      series: [
          {
            // name: responseData.response_data.bar.columns[1],
            // data: responseData.response_data.bar.data.map((item, index) => ({
            //   y: item[1],         
            // })),

            name: 'Corn',
            data: [387749, 280000, 129000]
          },
        ],
    };
    
    return (
      <>
      <div className='col-sm-8 mt-2 customer-barChartContainer'>
        <div className='cust-pie-wrapper'>
            <HighchartsReact
                  highcharts={Highcharts}
                  options={{                
                   ...chartOptions
                  }}
                />
        </div>      
      </div>
      </>
    );  
  }
  catch (error) {
    console.error('Error in Stacked bar chart component:', error);
    // Render a placeholder or error message
    return <div></div>;
  }
 
};

export default BarChartCustomer;