import React, { props } from "react";
import scrm from "./images/scrm.svg";
import { Link } from "react-router-dom";
import "./App.css";
import BPLAM from "./images/formulationsss.svg";
import Quaimg from "./images/Qualisght.jpg";
import Optiimg from "./images/Optimus.jpg";
import retail from './images/retail.jpg';

const isMainScreen = true;
const App = (props) => {
  // Energy.js

  // Other code for Energy.js

  return (
    <div className="energy-m">
      <div className="sub-H">
        <div className="head1">Home</div>
        <div className="head">Planning & Strategy</div>
        <div className="head">Core Operations</div>
        <div className="head">Financial Management</div>
        <div className="head">Supply Chain</div>
        <div className="head">ESG</div>
        <div className="head">Enterprise Functions</div>
      </div>
      <div className=" icons-m">
        <a
          href="https://industrygenai.accenture.com/formulation"
          className="icon-container1-m"
        >
          <img
            className="img-class-m2"
            src={BPLAM}
            alt="primary header"
            onClick={props.closeSideBar}
          />
          <p className="landing-text">PRISE</p>
        </a>
        <a
          href="https://industrygenai.accenture.com/ScrmSap"
          className="icon-container1-m"
        >
          <img
            className="img-class-m2"
            src={scrm}
            alt="primary header"
            onClick={props.closeSideBar}
          />{" "}
          <p className="landing-text">STRIDE</p>
        </a>
        <a
          href="https://industrygenai.accenture.com/lifescience"
          className="icon-container1-m"
        >
          <img
            className="img-class-m2"
            src={Quaimg}
            alt="primary header"
            onClick={props.closeSideBar}
          />{" "}
          <p className="landing-text">Qualisght</p>
        </a>
        <a
          href="https://industrygenai.accenture.com/fieldservice"
          className="icon-container1-m"
        >
          <img
            className="img-class-m2"
            src={Optiimg}
            alt="primary header"
            onClick={props.closeSideBar}
          />{" "}
          <p className="landing-text">Optimus</p>
        </a>
      </div>
      <div className=" icons-m">
      <a
          href="https://industrygenai.accenture.com/ewm"
          className="icon-container1-m"
        >
          <img
            className="img-class-m2"
            src={retail}
            alt="primary header"
            onClick={props.closeSideBar}
          />
          <p className="landing-text">Warehouse Co-Pilot</p>
        </a>
      </div>
    </div>
  );
};

export default App;
