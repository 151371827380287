// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flexrow-bot {
    /* margin-left: 23px; */
    margin-top: 5px;
    color: white;
    display: flex;
    /* background-color: rgba(255, 255, 255, 0.05); */
    width: auto;
    padding: 15px;
    border-radius: 8px;
    border-width: 1px;
    /* border-style: solid; */
    /* border-color: rgba(116, 123, 169, 1); */
    height: auto;
    flex-direction: column;
}

.heading-chart-bot {
    font-size: 14px;
    font-weight: 500;
    font-family: Graphik;
    color: white;
    margin-left: 20px;
}

.heading-analysis-bot {
    font-size: 14px;
    font-weight: 500;
    font-family: Graphik;
    color: white;
    margin-left: 10px;
}

.chart-box-bot {
    margin-left: -10px;
}

.points-bot {
    font-size: 12px;
    font-weight: 350;
    font-family: Graphik;
    color: rgb(199, 239, 246);
    width: 500px;
}`, "",{"version":3,"sources":["webpack://./src/CorrosionTrendChart.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,eAAe;IACf,YAAY;IACZ,aAAa;IACb,iDAAiD;IACjD,WAAW;IACX,aAAa;IACb,kBAAkB;IAClB,iBAAiB;IACjB,yBAAyB;IACzB,0CAA0C;IAC1C,YAAY;IACZ,sBAAsB;AAC1B;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,oBAAoB;IACpB,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,oBAAoB;IACpB,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,oBAAoB;IACpB,yBAAyB;IACzB,YAAY;AAChB","sourcesContent":[".flexrow-bot {\n    /* margin-left: 23px; */\n    margin-top: 5px;\n    color: white;\n    display: flex;\n    /* background-color: rgba(255, 255, 255, 0.05); */\n    width: auto;\n    padding: 15px;\n    border-radius: 8px;\n    border-width: 1px;\n    /* border-style: solid; */\n    /* border-color: rgba(116, 123, 169, 1); */\n    height: auto;\n    flex-direction: column;\n}\n\n.heading-chart-bot {\n    font-size: 14px;\n    font-weight: 500;\n    font-family: Graphik;\n    color: white;\n    margin-left: 20px;\n}\n\n.heading-analysis-bot {\n    font-size: 14px;\n    font-weight: 500;\n    font-family: Graphik;\n    color: white;\n    margin-left: 10px;\n}\n\n.chart-box-bot {\n    margin-left: -10px;\n}\n\n.points-bot {\n    font-size: 12px;\n    font-weight: 350;\n    font-family: Graphik;\n    color: rgb(199, 239, 246);\n    width: 500px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
