import React, { useState, useRef, useEffect } from 'react';
import MicRecorder from 'mic-recorder-to-mp3'; // Import MicRecorder library
import axios from 'axios'; // Import axios for HTTP requests
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone, faMicrophoneSlash } from '@fortawesome/free-solid-svg-icons'; // Import microphone icons
import decline from "../images/Decline.svg";
import './CallHistory.css';

const AudioRecorder = ({ onApiResponse, onUrlResponse, setLoading, businessPartnerID, clearChatMessages }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [recorder, setRecorder] = useState(null);
  const [blobURL, setBlobURL] = useState(null);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter') { // Change 'Enter' to any key you want to use
        if (isRecording) {
          stopRecording();
        }
      }
    };

    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [isRecording]);

  const startRecording = () => {
    const newRecorder = new MicRecorder({ bitRate: 128 });
    setRecorder(newRecorder);

    newRecorder.start()
      .then(() => {
        setIsRecording(true);
      })
      .catch((error) => {
        console.error('Error starting recording:', error);
      });
  };

  const stopRecording = () => {
    recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        console.log(buffer, blob);
        const file = new File(buffer, 'music.mp3', {
          type: blob.type,
          lastModified: Date.now()
        });

        const audioURL = URL.createObjectURL(file);
        setBlobURL(audioURL);
        setIsRecording(false);
        sendRecordingToAPI(blob);
      })
      .catch((error) => {
        console.error('Error stopping recording:', error);
      });
  };

  const handleButtonClick = () => {
    if (isRecording) {
      setIsRecording(false);
    } else {
      startRecording();
    }
  };

  const stopConversation = async () => {
    if (isRecording) {
      setIsRecording(false);
    } 
    try {
      const response = await fetch('https://industrygenai.accenture.com/customercareaiprod/clearmemory', {
        method: 'POST',
      });

      if (response.ok) {
        console.log('Memory cleared successfully.');
        clearChatMessages(); // Clear chat messages
      } else {
        console.error('Failed to clear memory.');
      }
    } catch (error) {
      console.error('Error clearing memory:', error);
    }
  };


  const sendRecordingToAPI = async (blob) => {
    setLoading(true); // Set loading to true when API call starts
    try {
      if (!recorder) {
        console.error('Recorder object is not initialized.');
        return;
      }

      const formData = new FormData();
      formData.append('audio_file', blob, 'music.mp3');
      formData.append('modelType', 'speech');
      formData.append('BusinessPartnerID', businessPartnerID);

      let chatbotResponse;

      const response = await fetch('https://industrygenai.accenture.com/customercareaiprod/customercareai', {
        method: 'POST',
        body: formData,
      });
      if (response.ok) {
        let recommendationData1 = "";
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        const metadata = response.headers.get('Metadata');
        if (metadata) {
          console.log(metadata);
          chatbotResponse = JSON.parse(metadata);
          onApiResponse(chatbotResponse);
          onUrlResponse(url);
        }
      }

      console.log('API response:', chatbotResponse);
      
    } catch (error) {
      console.error('Error sending recording to API:', error);
    } finally {
      setLoading(false); // Set loading to false when API call completes
    }
  };

  return (
    <div style={{ paddingTop: "9px", paddingLeft: "50%" }}>
      <button onClick={handleButtonClick}>
        {!isRecording ? 
          <FontAwesomeIcon icon={faMicrophoneSlash} /> // Show microphone slash icon when not recording
          :
          <FontAwesomeIcon icon={faMicrophone} /> // Show microphone icon when recording
        }
      </button>
      {isRecording &&
      <button className="stop-image" onClick={stopConversation}>
        
          <img className="stop-image-icon" src={decline} alt="Stop" />
        
      </button>
}
    </div>
  );
};

export default AudioRecorder;
