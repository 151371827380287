import React, { useState, useEffect } from 'react';
import Iicon from './images/Group 3440.svg';
import axios from 'axios';
import ReactPlayer from 'react-player';
import Info from './Info.js';

function Saleslead({ title }) {
  const [showModal, setShowModal] = useState(false);
  const [videoBlob, setVideoBlob] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getVideo = async (title) => {
      try {
        const response = await axios.get(`https://industrygenai.accenture.com/demovideoprod/video/${title}`, {
          responseType: 'blob', // Set responseType to 'blob' to receive binary data
        });
        const blob = new Blob([response.data], { type: 'video/mp4' });
        setVideoBlob(blob);
        setLoading(false); // Video loaded, set loading to false
      } catch (error) {
        console.error('Error fetching video from server:', error);
      }
    };

    // Call getVideo function when the component mounts
    getVideo(title);
  }, []); // Empty dependency array ensures the effect runs only once on mount

  return (
    <div className="main-container">
      <div className='In-main-container'>
        <p style={{ marginTop: "30px", fontSize: "28px", marginLeft: "20px", fontWeight: "550", color: "#AD8BFF", fontFamily: "Graphik", width: "1000px", fontSize: "15px" }}>{title}</p>
        {/* Code for modal and icon */}
      </div>
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "200px" }}>
          <Spinner />
        </div>
      ) : (
        videoBlob && (
          <video controls width="100%" height="85%" style={{ margin: "10px 20px" }}>
            <source src={URL.createObjectURL(videoBlob)} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )
      )}
    </div>
  );
}

// Spinner Component
const Spinner = () => {
  return (
    <div className="spinner-border" role="status" style={{ color: 'white' }}>
      <span className="sr-only"></span>
    </div>
  );
};

export default Saleslead;
