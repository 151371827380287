import React, { useEffect, useState,useMemo } from "react";
import { useNavigate } from "react-router-dom";
import infoIcon from "../images/Group 3440.svg";
import Info from "../Info.js";
import CustomerCareAccordian from "./CustomerCareAccordian.js";
import ChatbotDiscussion from "./ChatbotDiscussion.js";
import CallHistory from "./CallHistory.js";
import PieChartCustomer from "./PieChartCustomer.js";
import ConfirmationBox from "./ConfirmationBox.js";
import CustomerInformationTable from "./CustomerInformationTable.js";
import BarChartCustomer from "./BarChartCustomer.js";
import LineChartCustomer from "./LineChartCustomer.js";
import "./CustomerCareHome.css";

const CustomerCareHome = () => {
  const navigate = useNavigate();
  const [infoModal, setShowInfoModal] = useState(false);
  const [activeTab, setActiveTab] = useState("tab1");
  const [activeCallHistoryTab, setActiveCallHistoryTab] = useState("call");
  const [inputValue, setInputValue] = useState({});
  const [assistChat, setAssistChat] = useState([]);
  const [nextId, setNextId] = useState(0);

  const infocontent = {
    description: [
      "GenAI simplifies the process of handover between shifts and to have a Quick glance of summary with region wise information",
      "Business can focus more on critical operational task for prompt decision making on high priority drilling jobs.",
      "Based on the Live Streaming Data (WITSML) or DDR (Daily Drilling Reports), GenAI can be leveraged to quickly glance over the summary of the operational wells/rigs with region wise information.",
    ],
    BusinessValueProposition: [
      "Simplified Shift Handover Process",
      "Quick Access to Region-Wise Operational Summaries",
      "Enhanced Focus on Critical Operational Tasks",
      "Improved Decision-Making for High-Priority Drilling Jobs",
      "Mitigation of Communication Gaps and Documentation Issues",
    ],
    problemStatement: [
      "Effective handover is the key for successful execution of next phase. GenAI simplifies this handover process",
      "This will avoid issues due to communication Gaps, Lack of documentation, incomplete information, language Barrier, Shift overlap, lack of standardization, Training Gaps",
    ],
    Howdoesitwork: [
      " Data collection (e.g., Live Streaming Data (WITSML) or DR (Daily Drilling Reports))",
      "Data Processing",
      "Embedding input data into the Vector Database",
      "GenAI Model interaction with the user (e.g., upload a report, prompt a query)",
      "Text retrieval, graph visualization etc.",
    ],
    Technologychoices: [
      "Containerized cloud",
      "deployment",
      "Cloud agnostic",
      "LLM: OpenAI models",
      "Embedding",
      "Vector DB",
    ],
  };

  const handlePrevNav = (event) => {
    navigate("/internal");
  };

  const handleToggleModal = () => {
    setShowInfoModal(!infoModal);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleCallHistoryTabChange = (tab) => {
    setActiveCallHistoryTab(tab);
  };

  const handleAudioResponse = (responseText) => {
    setInputValue(responseText);
  };

  useEffect(()=>{ 
    const msg = inputValue?.response;  
    const respTyp = inputValue?.response?.response_type;
    const outputMsg = inputValue?.transcript?.output_response;
   // console.log('inputValue array:',inputValue);
    if (msg && respTyp) {
       if(respTyp === 'answer'){
        const message = inputValue?.response?.answer;
        if(message === outputMsg){
          setAssistChat((prevMessages) => [
            ...prevMessages,
            { id: nextId, assistmsg: msg, responseType: respTyp , outputMsg:outputMsg, showmsg: false}
          ]);
        }
       }
       else{
        setAssistChat((prevMessages) => [
          ...prevMessages,
          { id: nextId, assistmsg: msg, responseType: respTyp , outputMsg:outputMsg, showmsg: true}
        ]);
       }
   
      setNextId(prevId => prevId + 1);
    }
  },[inputValue]);

  const filteredAssistChat = useMemo(() => {
    if (assistChat.length > 0) {  
      // Filter records based on showmsg and keep the latest record
      return assistChat.filter(chat => chat.showmsg);
    }
    return [];
  }, [assistChat]);

  const renderAssistChat = (message) =>{  
   
    if(message?.responseType === 'answer'){
      return(
        <div className="assist-msg-style">
          <span className="assist-text-style">{message?.assistmsg?.answer}</span>
        </div>        
      );
    }
    else if( message?.responseType === 'line_chart'){
      return(
        <>
          <LineChartCustomer responseData={message}></LineChartCustomer>   
        </>      
      );
    }
    else if( message?.responseType === 'table'){
      return(
        <>
          <CustomerInformationTable responseData={message?.assistmsg}></CustomerInformationTable>
        </>
      )
    }
  };

  return (
    <div className="main-container-scrm">
      <div className="In-main-container">
        <div className="Heading-v">
          <span
            onClick={handlePrevNav}
            style={{ color: "#AD8BFF", cursor: "pointer" }}
          >
            Cross Industry{" "}
          </span>{" "}
          / CustomerServiceUsecase
        </div>
        <img
          className="top-sum chatboticon i-icon"
          src={infoIcon}
          alt="Sample"
          onClick={handleToggleModal}
        />
      </div>
      {/* <div className="col-sm-12 customer-dashboard">
          <div className="col-sm-12 customer-head-box">
              <div className="col-sm-10" style={{padding: "10px 0px"}}>
                <div className='col-sm-12'>  
                  <span className="customer-heading">AI Customer Service Dashboard</span>
                </div>
                <div className='col-sm-12'>
                   <span className="customer-dtTime">June 14th, 2023 | 11:11 AM</span>
                </div>
              </div>
              <div className="col-sm-2"></div>
          </div>
          <div className="col-sm-3">
              <div className="customer-tabs">
                                <button
                                    className={`customer-tab-button ${activeTab === 'tab1' ? 'active' : ''}`}
                                    onClick={() => handleTabChange('tab1')}
                                >
                                    Assist
                                </button>
                                <button
                                    className={`customer-tab-button ${activeTab === 'tab2' ? 'active' : ''}`}
                                    onClick={() => handleTabChange('tab2')}
                                >
                                    Resources
                                </button>
                           
              </div>
          </div>
          <div className="col-sm-8 customer-assitBox">
          {activeTab === 'tab1' && (
             <>
              <ConfirmationBox></ConfirmationBox>
              <CustomerInformationTable></CustomerInformationTable>
             </>
          )}
            </div>
            <div className="col-sm-4 customer-assitBox">

            </div>
         


      </div> */}

      <div className="customer-dashboard">
        <div className="customer-head-box">
          <div className="customer-header">
            <span className="customer-heading">
              AI Customer Service Dashboard
            </span>
            <span className="customer-dtTime">June 14th, 2023 | 11:11 AM</span>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
          <div style={{ flex: "1" }}>
            <div className="customer-tabs">
              <button
                className={`customer-tab-button ${
                  activeTab === "tab1" ? "active" : ""
                }`}
                onClick={() => handleTabChange("tab1")}
              >
                Assist
              </button>
              <button
                className={`customer-tab-button ${
                  activeTab === "tab2" ? "active" : ""
                }`}
                onClick={() => handleTabChange("tab2")}
              >
                Resources
              </button>
            </div>
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <div className="customer-assist-box">
                {activeTab === "tab1" && (
                  <>
                      {filteredAssistChat.map((message, index) => (
                      <div key={message.id}>
                        {renderAssistChat(message)}
                      </div>
                    ))}
                   
                    {/* <ConfirmationBox></ConfirmationBox>
                    <CustomerInformationTable></CustomerInformationTable>      
                    <PieChartCustomer></PieChartCustomer>       
                    <BarChartCustomer></BarChartCustomer>       */}
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="interaction-column">
            <div className="">
              <CustomerCareAccordian title="Call History"
              customHeader={
                <div className="call-tabs right-tab-header">
                  <button
                    className={`call-history-tab-button ${
                      activeCallHistoryTab === 'call' ? 'active' : ''
                    }`}
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent accordion from toggling
                      handleCallHistoryTabChange('call');
                    }}
                  >
                    Call
                  </button>
                  <button
                    className={`call-history-tab-button ${
                      activeCallHistoryTab === 'history' ? 'active' : ''
                    }`}
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent accordion from toggling
                      handleCallHistoryTabChange('history');
                    }}
                  >
                    History
                  </button>
                </div>
              }
              >
                
                {/* Add your content for each tab here */}
                {activeCallHistoryTab === "call" && <CallHistory />}
                {activeCallHistoryTab === "history" && (
                  <div>History Content</div>
                )}
              </CustomerCareAccordian>
            </div>

            <div className="">
              <CustomerCareAccordian title="Transcription">
                {/* Transcription Content */}
                <ChatbotDiscussion  audioResponse={handleAudioResponse}/>
              </CustomerCareAccordian>
            </div>
          </div>
        </div>
      </div>

      {infoModal && (
        <Info
          onClose={() => setShowInfoModal(false)}
          infocontent={infocontent}
        />
      )}
    </div>
  );
};

export default CustomerCareHome;
