// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-info-main {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-info {
  background-color: rgba(30, 32, 61, 1);
  border: 1px solid #6B5CD1;
  border-radius: 8px;
}

.modal-content-info {
  /* background: rgba(32, 35, 66, 1); */
  border-radius: 15px;
  /* width:100%;
  height: 100%; */
  position: relative;
  margin: 20px;
}
.close-btn-info {
  position: absolute;
  margin-left: 95%;
  cursor: pointer;
  color: white;
  border: none;
  border-radius: 5px;
  width: 30px;
}
.percent-logo{
  width:5%;
  padding-bottom:30px;
}

.para {
  font-size: 11px;
  font-weight: 355;
  color: white;
  font-family: Graphik;
}

.info-header-left{
  font-size: 14px;
  font-weight: 600;
  color: white;
  font-family: Graphik;
  margin-left: -20px;
  padding-bottom: 5px;
}
.info-header{
  font-size: 14px;
  font-weight: 600;
  color: white;
  font-family: Graphik;
  padding-bottom: 5px;
}

.shri-pic{
  width: 75px;
  margin-left: 20px;
}
.jegan-pic{
  width: 75px;
}`, "",{"version":3,"sources":["webpack://./src/Info.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;EACf,cAAc;EACd,MAAM;EACN,OAAO;EACP,UAAU;EACV,YAAY;EACZ,oCAAoC;AACtC;;AAEA;EACE,qCAAqC;EACrC,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,qCAAqC;EACrC,mBAAmB;EACnB;iBACe;EACf,kBAAkB;EAClB,YAAY;AACd;AACA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,WAAW;AACb;AACA;EACE,QAAQ;EACR,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,oBAAoB;AACtB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,oBAAoB;EACpB,kBAAkB;EAClB,mBAAmB;AACrB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,oBAAoB;EACpB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,iBAAiB;AACnB;AACA;EACE,WAAW;AACb","sourcesContent":[".modal-info-main {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  position: fixed;\n  z-index: 10000;\n  top: 0;\n  left: 0;\n  width:100%;\n  height: 100%;\n  background-color: rgba(0, 0, 0, 0.5);\n}\n\n.modal-info {\n  background-color: rgba(30, 32, 61, 1);\n  border: 1px solid #6B5CD1;\n  border-radius: 8px;\n}\n\n.modal-content-info {\n  /* background: rgba(32, 35, 66, 1); */\n  border-radius: 15px;\n  /* width:100%;\n  height: 100%; */\n  position: relative;\n  margin: 20px;\n}\n.close-btn-info {\n  position: absolute;\n  margin-left: 95%;\n  cursor: pointer;\n  color: white;\n  border: none;\n  border-radius: 5px;\n  width: 30px;\n}\n.percent-logo{\n  width:5%;\n  padding-bottom:30px;\n}\n\n.para {\n  font-size: 11px;\n  font-weight: 355;\n  color: white;\n  font-family: Graphik;\n}\n\n.info-header-left{\n  font-size: 14px;\n  font-weight: 600;\n  color: white;\n  font-family: Graphik;\n  margin-left: -20px;\n  padding-bottom: 5px;\n}\n.info-header{\n  font-size: 14px;\n  font-weight: 600;\n  color: white;\n  font-family: Graphik;\n  padding-bottom: 5px;\n}\n\n.shri-pic{\n  width: 75px;\n  margin-left: 20px;\n}\n.jegan-pic{\n  width: 75px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
