// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chartborderright-bot {
    /* margin-left: 13px; */
    border-width: 1px;
}
.heading-topic-bot {
    font-size: 14px;
    font-weight: 500;
    font-family: Graphik;
    color: white;
    margin-left: 10px;
    padding: 2px
}
.points-bot {
    font-size: 12px;
    font-weight: 350;
    font-family: Graphik;
    color: rgb(199, 239, 246);
}`, "",{"version":3,"sources":["webpack://./src/Botanalysis.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,iBAAiB;AACrB;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,oBAAoB;IACpB,YAAY;IACZ,iBAAiB;IACjB;AACJ;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,oBAAoB;IACpB,yBAAyB;AAC7B","sourcesContent":[".chartborderright-bot {\n    /* margin-left: 13px; */\n    border-width: 1px;\n}\n.heading-topic-bot {\n    font-size: 14px;\n    font-weight: 500;\n    font-family: Graphik;\n    color: white;\n    margin-left: 10px;\n    padding: 2px\n}\n.points-bot {\n    font-size: 12px;\n    font-weight: 350;\n    font-family: Graphik;\n    color: rgb(199, 239, 246);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
