import React, { useState, useEffect, useNavigate } from 'react';
import Iicon from './images/Group 3440.svg'
import Info from './Info.js';

// import ReactPlayer from 'react-player';

function Esg({ inputVideoUrl }) {
  const [showModal, setShowModal] = useState(false);
  const handleToggleModal = () => {
    setShowModal(!showModal);
  };

  const infocontent = {
    "description": [
      "Gen AI can be leveraged to conduct competitive benchmarking that can help determine whether your sustainability strategies are effective, provide recommendations for closing potential gaps, and assess the business value to be gained by addressing those gaps.",
      "It provides context for a company's ESG strategies, targets, and performance and facilitates future ESG strategy decision-making."
    ],
    "BusinessValueProposition": [
      "ESG Performance Evaluation",
      "Risk Assessment and Mitigation",
      "Enhanced Stakeholder confidence",
      "Identification of weak links",
      "Culture of improvement",
    ],
    "problemStatement": [
      " As climate change progresses rapidly, businesses across sectors are increasingly adopting sustainability practices",
      "By benchmarking their Sustainability/ESG performance, companies can assess their risks of negatively affecting their brand reputation, losing out on deals to competitors, climate risks, & more. This information enables them to develop strategies to mitigate these risks proactively"
    ],
    "Howdoesitwork": [
      " Data collection (e.g., ESG/Sustainability/CDP reports)",
      "Data Processing",
      "Embedding input data into the Knowledge Graph Database",
      "GenAI Model interaction with the user (e.g., upload a report, prompt a query)",
      "Text, graph extraction"
      
    ],
    "Technologychoices": [
      "Containerized cloud",
      "deployment",
      "Cloud agnostic",
      "LLM: OpenAI models",
      "Vector DB",
      "Embedding"
    ]
  }

  return (
    <div className="main-container">
      <div className='In-main-container'>
        <p style={{ marginTop: "30px", fontSize: "28px", marginLeft: "20px", fontWeight: "550", color: "#AD8BFF", fontFamily: "Graphik", width: "1200px", fontSize: "15px" }}>ESG Domain Model</p>

        <div style={{marginTop:"30px", marginLeft:"140px"}}><img className="i-icon" src={Iicon} onClick={handleToggleModal} /></div>

        {showModal && (
          <Info
            onClose={() => setShowModal(false)} infocontent={infocontent}
          />
        )}
        <div>

        </div>

      </div>
      <div className='container-iframe'>
      {/* <iframe id="kmsembed-1_b4p3wlq2"  width="700" height="400" src="https://mediaexchange.accenture.com/embed/secure/iframe/entryId/1_b4p3wlq2/uiConfId/27188232/st/0" class="kmsembed" allowfullscreen webkitallowfullscreen mozAllowFullScreen allow="autoplay *; fullscreen *; encrypted-media *" referrerPolicy="no-referrer-when-downgrade" frameborder="0" title="Regulatory Documentation Authoring platform - Demo video"></iframe> */}
      <iframe id="kmsembed-1_pdqdzywz" width="700" height="400"  src="https://mediaexchange.accenture.com/embed/secure/iframe/entryId/1_pdqdzywz/uiConfId/27188232/st/0" class="kmsembed" allowfullscreen webkitallowfullscreen mozAllowFullScreen allow="autoplay *; fullscreen *; encrypted-media *" referrerPolicy="no-referrer-when-downgrade"  frameborder="0" title="ESG Domain Model Overview"></iframe>
    </div>
    </div>
  )
}

export default Esg