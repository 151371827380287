import './Timeseries.css';

const AccordianTable = (props) =>{

   // const { columns, data } = props?.responseData;
   const { responseData } = props;
    const { columns = [], data = [] } = props?.responseData || {};

    
    if (responseData === undefined) {
        return (
            <div>
                <span style={{ color: 'white', display: 'flex', justifyContent: 'center', marginTop: '10px' }}>Data not present</span>
            </div>
        );
    }
    console.log('table response:',props?.responseData?.length)
    return(<>
        {props?.responseData?.length === 0 ? 
            <div>
                <span style={{color:'white',display:'flex', justifyContent:'center', marginTop:'10px'}}>Data is not present</span>        
            </div>
        
            : 
            <div className="table-container">
            <table className="accordianTable" style={{borderRadius:'10px'}}>
                <thead>
                    {/* <tr>
                        <th>TIMESTAMP</th>
                        <th>PRESSURE (kPa)</th>
                        <th>TEMPERATURE (C)</th>
                        <th>VOLUME (M3/h)</th>
                    </tr> */}

                        <tr>
                        {columns?.map((column, index) => (
                            <th key={index}>{column}</th>
                        ))}
                        </tr>
                </thead>
                <tbody>
                        {data?.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                                {row?.map((cell, cellIndex) => (
                                    <td key={cellIndex}>{cell}</td>
                                ))}
                            </tr>
                        ))}
                </tbody>
            
            </table>
            </div>      
        }         
    </>);
};

export default AccordianTable;