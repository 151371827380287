import React, { useState, useEffect, useNavigate } from 'react';
import Iicon from './images/Group 3440.svg'
import Info from './Info.js';

// import ReactPlayer from 'react-player';

function ExtVideo({inputVideoUrl}) {
  const [showModal, setShowModal] = useState(false);
  const handleToggleModal = () => {
    setShowModal(!showModal);
  };

  const infocontent = {
    "description": [
      "process for streamlining data collection and analysis in order to identify cost-saving opportunities in 3rd party contracts."
    ],
    "BusinessValueProposition": [
      "Reduces  3rd party Spend",
      "Reduces time to value realization",
      "Increase workforce prodoctivity"
    
    ],
    "problemStatement": [
      "Manual data overload: Businesses drown in unanalyzed 3rd party spending data, hindering cost savings and informed decisions.",
      "Siloed information and limited access: Fragmented data hampers collaboration and slows down identification of cost reduction opportunities.",
"Unscalable and reactive processes: Manual analysis is inefficient, unable to handle large volumes, and misses proactive optimization chances."
    ],
    "Howdoesitwork": [
      "Rapid Financial Data Curation",
      "Embedding input data into the Knowledge Graph Database",
      "GenAI Model interaction with the user (e.g., prompt a query)",
      "Text, Meta data"
    ],
    "Technologychoices": [
      "Containerized cloud",
      "deployment",
      "Cloud agnostic",
      "LLM: OpenAI models"
    ]
  }

  return (
    <div className="main-container">
      <div className='In-main-container'>
        {/* <div className='Heading-v' style={{ width: "400px" }}>Contract and Financial Insight Assistant</div> */}
        <p style={{ marginTop:"30px", fontSize: "28px", marginLeft: "20px", fontWeight: "550",color: "#AD8BFF", fontFamily: "Graphik", width:"1000px", fontSize:"15px"}}>Contract and Financial Insight Assistant</p>
        <div style={{marginTop:"30px", marginLeft:"100px"}}><img className="i-icon" src={Iicon} onClick={handleToggleModal} /></div>

        {showModal && (
          <Info
            onClose={() => setShowModal(false)} infocontent={infocontent}
          />
        )}
        <div>

        </div>

      </div>
      <div className='container-iframe'>
      <iframe id="kmsembed-1_iat16f3l" width="700" height="400" src="https://mediaexchange.accenture.com/embed/secure/iframe/entryId/1_iat16f3l/uiConfId/27188232/st/0" class="kmsembed" allowfullscreen webkitallowfullscreen mozAllowFullScreen allow="autoplay *; fullscreen *; encrypted-media *" referrerPolicy="no-referrer-when-downgrade"  frameborder="0" title="Cost & Productivity Reinvention with Gen AI"></iframe>
    </div>
    </div>
  )
}

export default ExtVideo