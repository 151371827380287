import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HC_exporting from 'highcharts/modules/exporting';
import HC_exportData from 'highcharts/modules/export-data';
HC_exporting(Highcharts);
HC_exportData(Highcharts);

const LineChartCustomer = ({ responseData }) => { 
  try{
    const chartOptions = {
      chart: {
        type: 'line',
        width:470,
        height:300,
        backgroundColor: 'transparent'  ,
        marginTop:10     
      },
      title: {
        text: '',
      },
      credits: {
          enabled:false
      },
      exporting: {
        enabled: true // Disable the exporting functionality (burger menu)
      },  
      legend :{
        enabled:false
      },
      colors:['#DCAFFF','#A100FF'],
      plotOptions: {
        line: {
          dataLabels: {
            enabled: true
          },       
        },
      },
      xAxis: {
        categories: responseData?.assistmsg?.line?.data?.map((item) => item[0]),// Circuit names
        title: {
        //   text: responseData?.line.columns[0]
          text:''
        },
       },
      yAxis: {
        title: {
          text: responseData?.assistmsg?.line?.columns[1]        
        },
      },
      tooltip: {
        pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ' +`${responseData?.assistmsg?.units}`,
        shared: true
    },
      series: [
        {
          name:  responseData?.assistmsg?.line?.columns[1],
          data: responseData?.assistmsg?.line?.data?.map((item) => parseFloat(item[1])), // Total Customer Served values
        },
      ],
    };
  
    return (
      <>
        {responseData?.assistmsg?.analysis !== responseData?.outputMsg &&(
          <div className="assist-msg-style">      
              <span className="assist-text-style">{responseData?.assistmsg?.analysis}</span>   
          </div>
         )}
      <div className='col-sm-10 lineChartContainer mt-2'>
        <div className='cust-pie-wrapper'>
            <HighchartsReact
                    highcharts={Highcharts}
                    options={{                
                    ...chartOptions
                    }}
                    />
        </div>           
      </div>
      </>
    );
  }
  catch (error) {
    console.error('Error in Line chart component:', error);
    // Render a placeholder or error message
    return <div></div>;
  } 
 
};

export default LineChartCustomer;
