import React from "react";
import XMLViewer from "react-xml-viewer";
import no_data_icon from "../images/no-data-image.svg";

const darkTheme = {
  tagColor: "#FFD700",            // Gold color for tags
  attributeKeyColor: "#00FF00",   // Lime green for attribute keys
  attributeValueColor: "#1E90FF", // Dodger blue for attribute values
  textColor: "#FFFFFF",           // White for text
  separatorColor: "#FFA500",      // Orange for separators
  backgroundColor: "#282244",     // Dark background matching your current setup
};

const XMLViewerComponent = ({ xmlApiData }) => {
  return (
    <div
      className="xml-box-margin"
      style={{
        whiteSpace: "pre-wrap",
        color: darkTheme.textColor,
        backgroundColor: darkTheme.backgroundColor,
        borderRadius: "5px",
        maxHeight: "450px",
        overflowY: "scroll",
        margin: "10px"
      }}
    >
      {xmlApiData ? (
        typeof xmlApiData === "string" ? (
          <XMLViewer xml={xmlApiData.trim()} theme={darkTheme} collapsible />
        ) : (
          <>
        <div className="no-data-show">
          <img src={no_data_icon} />
          <h3 className="text-inside-no-data">
            No data Available
          </h3>
        </div>
      </>
        )
      ) : (
        <>
        <div className="no-data-show">
          <img src={no_data_icon} />
          <h3 className="text-inside-no-data">
            No data Available
          </h3>
        </div>
      </>
      )}
    </div>
  );
};

export default XMLViewerComponent;