import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Navbar, Nav, NavDropdown, Offcanvas, Form, Button, Stack, Dropdown, Accordion, InputGroup } from 'react-bootstrap';
import equipement from '../images/refinery_equipemt.png';
import browser from '../images/upload_FILL0_wght400_GRAD0_opsz24 1.svg';
import deleteicon from '../images/delete.svg';
import uploadNew from '../images/uploadNew.svg';
import regenerate from '../images/regenerate.svg';
import send from '../images/send_FILL0_wght400_GRAD0_opsz24 1.svg';

function Metadata(props) {

    const site = ["Site1", "Site2", "Site3"];
    const equipementID = ["e1", "e2", "e3"];
    const reporttype = ["Visual Inspection Report"]
    const [equipementType, setEquipementType] = useState(props.metadata.equipementType[0]);
    const history = [{ equipment: "Air-Cooled Heat Exchanger", timestamp: "28/01/2024 10.00.05 AM" },
    { equipment: "Fired Heater", timestamp: "28/01/2024 12.00.05 AM" },
    { equipment: "Shell & Tube Heat Exchanger", timestamp: "28/01/2024 4.00.05 PM" },
    { equipment: "Fired Heater", timestamp: "28/01/2024 12.00.05 AM" },
    { equipment: "Regeneration Tower", timestamp: "28/01/2024 1.00.05 PM" }];

    const [metadata, setmetadata] = useState(props.metadata);
    const [showTableLoading, setShowTableLoading] = useState(false);

    const handleEquipmentTypeChange = async (type) => {
        props.handleCurrMetadata(type);
        setFilledMetadata({  equipmenttype: type });
        setQuestion_report([]);
        setEquipementType(type);
        setShowTableLoading(true)
        try {
            const dropdownResponse = await fetch('https://industrygenai.accenture.com/inspection/dropdownpredict', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ equip_name: type }),
            });
            console.log(dropdownResponse);
            if (dropdownResponse.ok) {
                const dropdown = await dropdownResponse.json();
                // const dropdown = {
                //     "Dropdown_List": {
                //         "EquipmentID": [
                //             "TNK0000121",
                //             "TNK0000122",
                //             "TNK0000125"
                //         ],
                //         "EquipmentType": [
                //             "Cone Roof Tank"
                //         ],
                //         "LLM_Model_Name": "GPT4Vision",
                //         "ReportType": [
                //             "Visual Inspection Report"
                //         ],
                //         "Site": [
                //             "Refinery - A",
                //             "Refinery - B"
                //         ],
                //         "Timestamp_End": "2024-02-0410:43:14",
                //         "Timestamp_Start": "2024-02-0410:43:11"
                //     },
                //     "Equipment_Name": "Cone Roof Tank"
                // }
                if (dropdown && !dropdown.Exception) {
                    const data = {
                        equipementType: type,
                        dropdown: dropdown
                    }
                    console.log(data);
                    setmetadata(data);
                    setShowTableLoading(false);
                }
                else {
                    throw new Error('Empty dropdown data');
                }

            } else {
                throw new Error('Fetch dropdown processing failed');
            }

        } catch (error) {
            console.log(error);
        }

    }
    const [modalVisible, setModalVisible] = useState(false);

    const handleIconClick = () => {
        setModalVisible(true);
    };

    const closeModal = () => {
        setModalVisible(false);
    };
    const [imagelist, setImageList] = useState([]);

    const handleImageUpload = async (event) => {
        const file = event.target.files[0];
        if(file && file.name){
        const fileExtension = file.name.split('.').pop().toLowerCase();

        if (fileExtension === 'png' || fileExtension === 'jpeg' || fileExtension === 'jpg') {
            const formData = new FormData();
            formData.append('image_file', file);

            const metadata = {
                "equip_name": equipementType,
                "question": choosenAdditionalInfoPath

            }
            console.log(metadata);
            formData.append('equip_name', equipementType);
            formData.append('question', choosenAdditionalInfoPath);
            console.log(formData);
            const url = URL.createObjectURL(file)
            const showimagedata = {
                url: url,
                path: choosenAdditionalInfoPath
            }
            setImageList((prevImageData) => [...prevImageData, showimagedata])
            setFormDataList((prevFormData) => [...prevFormData, formData]);
        } else {
            alert('Please upload a PNG, JPEG, or JPG file.');
        }}
        else {
            console.log('File name not found...');
        }

    };
    const deleteImage =(path)=>{

    }

    const [selectedTile, setSelectedTile] = useState(null);
    const handleTileClick = (tileId) => {
        setSelectedTile(tileId);
    };
    const [formDataList, setFormDataList] = useState([]);

    const [filledMetadata, setFilledMetadata] = useState({
        "site": null,
        "equipmentID": null,
        "reportType": null,
        "equipmenttype": equipementType
    });
    const handleMetadataChange = (event) => {
        console.log(event);
        const { id, value } = event.target;

        switch (id) {
            case 'site':
                setFilledMetadata((prevMetadata) => ({ ...prevMetadata, site: value }));
                break;
            case 'equipmentID':
                setFilledMetadata((prevMetadata) => ({ ...prevMetadata, equipmentID: value }));
                break;
            case 'reportType':
                setFilledMetadata((prevMetadata) => ({ ...prevMetadata, reportType: value }));
                break;
            default:
                break;
        }
        console.log(filledMetadata);
    };
    const [question_report, setQuestion_report] = useState([]);
    const callQuestPredict = async () => {
        try {
            setLoadAdditionalSection(true);
            console.log("callQuestPredict")
            const response = await fetch('https://industrygenai.accenture.com/inspection/questpredict', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "input_meta": {
                        "equip_name": equipementType,
                        "reporttype": filledMetadata.reportType
                    }, "tag": 1
                }),
            });
            console.log(response);
            if (response.ok) {
                const questionResponse = await response.json();
                // const questionResponse = {
                //     "Equipment_Name": "Cone Roof Tank",
                //     "Question_List": {
                //         "LLM_Model_Name": "GPT4Vision",
                //         "Questions": [
                //             "Additional Equipment/Design Data#****#Nameplate Present",
                //             "Additional Equipment/Design Data#****#Repair(s)/Clamp(s)/Sleeve(s) Present",
                //             "Additional Equipment/Design Data#****#Tank Label",
                //             "Contractor Information#****#Inspector Certification",
                //             "Contractor Information#****#Technician Name",
                //             "Equipment General#****#Access Structure(s)",
                //             "Equipment General#****#Biological Growth",
                //             "Equipment General#****#Flange(s) and Bolting",
                //             "Equipment General#****#Foundation",
                //             "Equipment General#****#Nozzle(s)/Manway(s)",
                //             "Equipment General#****#Rivets",
                //             "Equipment General#****#Secondary Containment Area",
                //             "Equipment General#****#Shell",
                //             "Equipment General#****#Shell  Mounted Vent(s)",
                //             "Equipment General#****#Shell Appurtenances",
                //             "Equipment General#****#Weld(s)",
                //             "Equipment General#****#Wind Girder",
                //             "Equipment Inspection Access#****#Inspection Access",
                //             "Equipment Insulation#****#Insulation Issues",
                //             "Loss of Containment#****#Historical Leakage/Seepage",
                //             "Loss of Containment#****#Leakage",
                //             "Loss of Containment#****#Seepage",
                //             "Roof#****#EFR",
                //             "Roof#****#Fixed",
                //             "Roof#****#Geodesic Dome",
                //             "Roof#****#IFR"
                //         ],
                //         "Timestamp_End": "2024-02-0410:44:36",
                //         "Timestamp_Start": "2024-02-0410:44:31"
                //     }
                // }

                console.log(questionResponse)
                if (questionResponse && questionResponse.Question_List && questionResponse.Question_List.Questions &&
                    Array.isArray(questionResponse.Question_List.Questions)
                    && questionResponse.Question_List.Questions.length !== 0) {
                    const questions = questionResponse.Question_List.Questions
                    const outputData = questions.reduce((result, str) => {
                        const [parent, child] = str.split("#****#");
                        // const existingParent = result.find(item => item.name === parent);
                        // if (!existingParent) {
                        result.push({ name: parent, child: child, path: str });
                        // } else {
                        //     existingParent.child.push(child);
                        // }

                        return result;
                    }, []);
                    setQuestion_report(outputData);
                    console.log(outputData);
                    setLoadAdditionalSection(false);
                    setChoosenAdditionalInfoPath(outputData[0].path)
                    setSelectedTile(outputData[0].child)
                } else {
                    throw new Error('Question Prediction processing failed- empty input');
                }
            } else {
                throw new Error('Fetch dropdown processing failed');
            }

        }
        catch (error) {
            console.log(error);
        }
    }
    const [choosenAdditionalInfo, setChoosenAdditionalInfo] = useState(null);
    const [choosenAdditionalInfoPath, setChoosenAdditionalInfoPath] = useState(null);
    const [viewAdditionalInfo, setViewAdditionalInfo] = useState(false);
    const [loadAdditionalSection, setLoadAdditionalSection] = useState(false);
    useEffect(() => {
        if (filledMetadata.site && filledMetadata.equipmentID && filledMetadata.reportType) {
            setViewAdditionalInfo(true);
            callQuestPredict();
        } else {
            setViewAdditionalInfo(false);
        }
    }, [filledMetadata]);
    const Loading = () => {
        return (
            <div className="loader-sv">
                <div className="dot-cq-sv red-c-sv"></div>
                <div className="dot-cq-sv green-c-sv"></div>
                <div className="dot-cq-sv blue-c-sv"></div>
            </div>
        );
        ;
    };
    const LoadingSidebar = () => {
        return (
            <div className="loader-ref-sidebar">
                <div className="dot-cq-sv red-c-sv"></div>
                <div className="dot-cq-sv green-c-sv"></div>
                <div className="dot-cq-sv blue-c-sv"></div>
            </div>
        );
        ;
    };
    const handleSendClick = () => {
        handleEquipmentTypeChange(equipementType)
    }
    const [slicekey, setSlicekey] = useState(3);
    const [slicekeystart, setSlicekeyStart] = useState(0);
    const [viewGenerate, setViewGenerate] = useState(true);
    const changeSlice = () => {
        setSlicekey(6);
        setSlicekeyStart(3);
        setViewGenerate(false);
    }
    return (
        <Container className='metdata-container-ria'>
            <Row xl className='metadata-ria'>
                <Col sm={3}>
                    <div className="equipement-display-bg">
                        <img className="equipement-display-bg-image" src={props.selectedImage} />
                    </div>

                </Col>
                <Col sm={9}>
                    <table className='key-table-ria' style={{ marginLeft: "70px" }}>
                        <tbody>
                            <tr>
                                <td><span style={{ fontWeight: "455" }}>{`Equipment Type \u00A0 \u00A0 :\u00A0 \u00A0 \u00A0 `} </span></td>
                                <td><InputGroup className="mb-3 InputGroup-ria-sm">
                                        <Form.Control
                                            placeholder="Enter Equipement Type"
                                            aria-label="Enter Equipement Type"
                                            aria-describedby="basic-addon2"
                                            value={equipementType}
                                            onChange={(e) => setEquipementType(e.target.value)} 
                                        />
                                        <InputGroup.Text id="basic-addon2" className='input-text-ria' onClick={handleSendClick}>
                                            <img src={send} className='send-ria'/>
                                        </InputGroup.Text>
                                    </InputGroup>
                                </td>
                                {/* <td style={{ display: "flex", flexDirection: "column" }}>
                                    {props.metadata.equipementType[0]}
                                </td> */}
                            </tr>
                        </tbody></table>

                    {/* <tr> */}
                    {/* <td><span style={{ fontWeight: "455" }}>{`\u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0`} </span></td> */}
                    {/* <td > */}
                    <div style={{ marginLeft: "10%" }}>
                        {/* {props.metadata.equipementType.map((item, index) => (
                            <Button className='priority-ria' onClick={() => { handleEquipmentTypeChange(item) }}>{item}</Button>
                        ))} */}
                        
                        {props.metadata.equipementType && Array.isArray(props.metadata.equipementType) && props.metadata.equipementType.length !== 0 && props.metadata.equipementType.length >= 3 ?
                            props.metadata.equipementType.slice(slicekeystart, slicekey).map((item, index) => (
                                <Button className='priority-ria' onClick={() => { handleEquipmentTypeChange(item) }}>
                                    {item}
                                </Button>
                            )) :
                            props.metadata.equipementType.length !== 0 && props.metadata.equipementType.map((item, index) => (
                                <Button className='priority-ria' onClick={() => { handleEquipmentTypeChange(item) }}>{item}</Button>
                            ))
                        }
                        { viewGenerate && <img src={regenerate} onClick={changeSlice} className='regenerate-ria' />}
                        </div>
                    {showTableLoading ? <div style={{ marginTop: "60px", marginBottom: "50px" }}><Loading /></div> : <table className='key-table-ria' style={{ marginLeft: "70px" }}>
                        <tbody>
                            <tr >
                                <td><span style={{ fontWeight: "455" }}>{`Site\u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 :\u00A0 \u00A0`}</span></td>
                                <td>
                                    <div>
                                    <select id="site" className='dropdown-dd' onChange={handleMetadataChange}>
                                        <option value="" disabled selected style={{ display: "none", padding:"10px" }}>Select Site</option>
                                        {metadata && metadata.dropdown && metadata.dropdown.Dropdown_List && metadata.dropdown.Dropdown_List.Site && Array.isArray(metadata.dropdown.Dropdown_List.Site)
                                            && metadata.dropdown.Dropdown_List.Site.length !== 0 &&
                                            metadata.dropdown.Dropdown_List.Site.map((item, index) => (<>
                                                <option key={index}>{item}
                                                </option></>
                                            ))}
                                    </select>
                                    </div>
                                </td>
                            </tr>
                            <tr >
                                <td><span style={{ fontWeight: "455" }}>{`Equipment ID\u00A0 \u00A0 \u00A0 \u00A0 \u00A0 :\u00A0 \u00A0`}</span></td>
                                <td>
                                    <select id="equipmentID" className='dropdown-dd' onChange={handleMetadataChange}>
                                        <option value="" disabled selected style={{ display: "none" }}>Equipment ID</option>
                                        {metadata && metadata.dropdown && metadata.dropdown.Dropdown_List && metadata.dropdown.Dropdown_List.EquipmentID && Array.isArray(metadata.dropdown.Dropdown_List.EquipmentID)
                                            && metadata.dropdown.Dropdown_List.EquipmentID.length !== 0 &&
                                            metadata.dropdown.Dropdown_List.EquipmentID.map((item, index) => (<>
                                                <option key={index}>{item}
                                                </option></>
                                            ))}
                                    </select>
                                </td>
                            </tr>
                            <tr >
                                <td><span style={{ fontWeight: "455" }}>{`Report Type\u00A0 \u00A0  \u00A0 \u00A0 \u00A0 \u00A0 \u00A0:\u00A0 \u00A0`}</span></td>
                                <td><select id="reportType" className='dropdown-dd' onChange={handleMetadataChange}>
                                    <option value="" disabled selected style={{ display: "none" }}>Select Report Type</option>
                                    metadata && metadata.dropdown && metadata.dropdown.Dropdown_List && metadata.dropdown.Dropdown_List.ReportType && Array.isArray(metadata.dropdown.Dropdown_List.ReportType)
                                    && metadata.dropdown.Dropdown_List.ReportType.length !== 0 &&
                                    {metadata.dropdown.Dropdown_List.ReportType.map((item, index) => (<>
                                        <option key={index}>{item}
                                        </option></>
                                    ))}
                                </select></td>
                            </tr>
                        </tbody>
                    </table>}
                </Col>
            </Row>
            {viewAdditionalInfo && <> <Accordion defaultActiveKey="0" className='metdata-accordian-ria'>
                <Accordion.Item eventKey="1">
                    <Accordion.Header style={{ color: "white" }}>
                        <span style={{ fontWeight: "450" }}>ADDITIONAL INFO </span>
                        <span style={{ marginLeft: "50%" }}>Uploaded Images for {imagelist.length}/{question_report.length} sections</span>
                    </Accordion.Header>
                    <Accordion.Body style={{ display: "flex" }}>
                        {loadAdditionalSection ? <div style={{ marginLeft: "45%" }}><Loading /></div> : <> <div>
                            <Stack className='stack-question-ria'>
                                {question_report && Array.isArray(question_report)
                                    && question_report.length !== 0 &&
                                    question_report.map((item, index) => (
                                        <div className="p-2 stack-ria" key={index} style={{ background: selectedTile === item.child ? 'linear-gradient(131.99deg, #8E2DE2 6.23%, #6A19D8 85.46%)' : 'transparent' }}
                                            onClick={() => {
                                                handleTileClick(item.child);
                                                setChoosenAdditionalInfo(item.child);
                                                setChoosenAdditionalInfoPath(item.path)
                                            }}>
                                            {item.child}
                                        </div>
                                    ))}
                            </Stack>
                        </div>
                            <div style={{ borderLeft: "1px solid #6B5CD1" }}>
                                <div>
                                    {imagelist && (imagelist.some(item => item.path === choosenAdditionalInfoPath)) ?
                                        <div>
                                            <div style={{ width: "42vw", marginLeft: "10px" }}>
                                                <div style={{ position: "relative" }}>
                                                    <span className='gallery-text'>Image Gallery</span>
                                                    <img src={uploadNew} className='icon-ria' />
                                                    <img src={deleteicon} className='icon-ria-delete' onclick={()=>{deleteImage(choosenAdditionalInfoPath)}}/>
                                                </div>
                                            </div>
                                            <img src={imagelist.find(item => item.path === choosenAdditionalInfoPath).url} className='image-choosen' />
                                        </div> :
                                        <div className="right-sub-container-ria right-metadata-container-ria-diplay">
                                            <div className='upload-file-container'>
                                                <p className='upload-image'>Upload Images</p>
                                                <div className='upload-image-header'>
                                                    <div className='drag-n-drop-files-containers'>
                                                        <div><img className="browse-logo" src={browser} /></div>
                                                        <div className='placeholders'>
                                                            <div className='Placeholder1'> Drag and Drop files here</div>

                                                        </div>

                                                    </div>
                                                    <div className='or-option'>OR</div>
                                                    <div className='browse-files-containers-ria'>
                                                        {/* <div><img className="browse-logo" src={browse}/></div> */}
                                                        <div className='placeholders'>
                                                            <div className='Placeholder4'> Choose Files<br /> from your System </div>
                                                        </div>

                                                        <label htmlFor="imageUpload" className="upload-file-button-ria">
                                                            <p className='browse-text-ria'>Browse Files</p> </label>
                                                        <input className="file-class-c"
                                                            type="file"
                                                            id="imageUpload"
                                                            accept=".png, .jpeg, .jpg"
                                                            onChange={handleImageUpload}
                                                        />


                                                    </div>

                                                    <br />
                                                    <br />

                                                </div>
                                            </div>
                                        </div>}
                                    {/* <Button className='upload-ria-button' onclick={handleadditionalpredict}>Upload</Button> */}
                                </div>
                            </div> </>}
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
                <Button className='process-ria-button' onClick={() => { props.ProcessReport(filledMetadata, formDataList, question_report) }}>Proceed to Inspection</Button> </>}

        </Container>
    )
}

export default Metadata