import React, { useState } from 'react';
import { Viewer } from '@react-pdf-viewer/core';
import { Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import "./Trading.css";

const DynamicTable = ({ data, pdfData}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [currentPageNum, setCurrentPageNum] = useState(1);

  if (!data || !data.TranfFields || !Array.isArray(data.TranfFields) || data.TranfFields.length === 0) {
    return <p>No data available</p>;
  }

  // Get keys from the first item to create dynamic headers
  const headers = Object.keys(data.TranfFields[0]);
  const trimmedHeaders = headers.slice(0, headers.length - 1);

  const initialScale = 1.5; // Adjust this scale as needed
  const newPlugin = defaultLayoutPlugin();

  // Function to handle button click to open the modal
  const handleButtonClick = (rowData) => {
    setSelectedRowData(rowData);
    if (rowData.page_nums.length === 1) {
      setCurrentPageNum(rowData.page_nums[0] - 1); // Set currentPageNum if only one value
    }
    console.log(selectedRowData);
    setIsModalOpen(true);
  };

  const handleSelectChange = (item, selectedPage) => {
    setCurrentPageNum(selectedPage - 1); // Update the current page number based on the dropdown selection
    setSelectedRowData(item); // Update the selected row data to reflect the current selection
  };

  // Function to close the modal
  const onClose = () => {
    setIsModalOpen(false);
    setSelectedRowData(null);
  };

  return (
    <>
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          <tr style={{ backgroundColor: 'rgba(61, 31, 115, 1)' }}>
            {trimmedHeaders.map((header, index) => (
              <th key={index} style={tableHeaderStyle}>
                {header.charAt(0).toUpperCase() + header.slice(1)} {/* Capitalize first letter */}
              </th>
            ))}
            <th style={tableHeaderStyle}>Show_in_pdf</th> {/* Last column for buttons */}
          </tr>
        </thead>
        <tbody>
        {data.TranfFields.map((item, index) => (
          <tr key={index}>
            {trimmedHeaders.map((header, idx) => (
                      <td key={idx} style={tableCellStyle}>
                       {header === 'page_nums' ? (
                    // Conditional rendering for page_nums
                    item[header].length > 1 ? ( // Check if there are multiple page numbers
                      <select
                      style={{
                        backgroundColor: '#1b1938',
                        color: 'white',
                      }}
                        onChange={(e) => handleSelectChange(item, e.target.value)}
                        defaultValue=""
                      >
                        <option value="" disabled>Select Page</option> {/* Default option */}
                        {item[header].map((pageNum, vIdx) => (
                          <option key={vIdx} value={pageNum}>
                            {pageNum}
                          </option>
                        ))}
                      </select>
                    ) : (
                      // If there is only one page number, show it directly
                      item[header][0]  // Display the single value or 'N/A'
                    )
                  ) : Array.isArray(item[header]) ? (
                    // If the value is an array, render each item in a new line
                    item[header].map((val, i) => (
                      <div key={i}>{val}</div>
                    ))
                  )  : (
                    item[header]  // Display item value or 'N/A' if not present
                  )}
                    </td>
            ))}
            <td style={tableCellStyle}>
              <button onClick={() => handleButtonClick(item)} className="form-submit">
                Link Source
              </button>
            </td>
          </tr>
        ))}
      </tbody>
      </table>
      {isModalOpen && (
        <div className="modal-overlay-doa">
          <div className="modal-doa">
            <div className="modal-content-doa">
              <h5>PDF Preview</h5>
              <div style={{ width: '750px', height: '51vh' }}>
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                <Viewer fileUrl={pdfData} plugins={[newPlugin]} scale={initialScale} initialPage={currentPageNum}/>
              </Worker>
              </div>
              
              <button type="button" className="form-submit" onClick={onClose}>
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

// CSS styles in JavaScript for reusability
const tableHeaderStyle = {
  border: '1px solid #ddd',
  padding: '8px',
  textAlign: 'left',
  fontWeight: 'bold',
  color: '#fff',
};

const tableCellStyle = {
  border: '1px solid #ddd',
  padding: '8px',
};

export default DynamicTable;
