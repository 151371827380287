import React, { useState } from "react";
import warningIcon from '../../src/images/Warning.png';
import './CustomerCareHome.css';

const ConfirmationBox = () =>{
    const [activeBtn, setActiveBtn] = useState("yes");

    return(
        <div className='col-sm-6 cust-conf-box'>
            <div className='cust-conf-header'>
                <span className='cust-reason'>Bill</span>
                <span className='cust-reason'>High Bill</span>
            </div>
            <div className='cust-conf-body'>
                <div className='cust-conf-subBody'>
                    <span className='cnf-text'>
                        <img className='cust-warningIcon' src={warningIcon} alt=''></img>Confirm if they are calling due to billing concern</span>
                </div>                
            </div>
            <div className='cust-conf-footer'>
                <button 
                    className={`cust-conf-button ${
                        activeBtn === "yes" ? "active" : ""
                      }`}>
                {/* className='cust-conf-button' active> */}
                    Yes</button>
                <button 
                    className={`cust-conf-button ${
                        activeBtn === "no" ? "active" : ""
                    }`}>
                {/* className='cust-conf-button'> */}
                    No</button>
            </div>
        </div>
    );
}

export default ConfirmationBox;