import React from 'react';
import styled from 'styled-components';

// Styled Components
const StyledSwitchButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  button {
    width: 31px;
    height: 15px;
    background-color: ${props => props.active ? '#FFFFFF' : '#FFFFFF'};
    border-radius: 11px;
    border: 1px solid ${props => props.active ? '#22E222' : '#ECECEC'};
    box-sizing: border-box;
    padding: 0;
    transition: all 300ms ease-in-out;
    cursor: pointer;
    outline: none;
    &::after {
      content: '';
      width: 12px;
      height: 12px;
      background-color: #868B90;
      border-radius: 50%;
      box-shadow: 0px 1px 3px rgba(30, 30, 30, 0.3);
      transition: all 300ms ease-in-out;
      transform: ${props => props.active ? 'translate(16px)' : 'translate(0)'};
      display: block;
    }
  }
  span {
    color: white;
    font-size: 12px;
    margin-left: 10px;
  }
`;


// Switch Button Component
const SwitchButton = (props) => (
  <StyledSwitchButton active={props.active}>
    {/* {props.disdisableBtn === 'disabled' && <button disabled type={props.type} onClick={props.clicked}></button>}
    {props.disdisableBtn !== 'disabled' && <button type={props.type} onClick={props.clicked}></button>} */}
    <button 
      type={props.type} 
      onClick={props.clicked} 
      disabled={props.disableBtn === true} // Conditionally set disabled attribute
    />
    <span>{props.active ? 'Enable Forecast' : 'Disabled Forecast'}</span>
  </StyledSwitchButton>
);

export default SwitchButton;