import React, { useState, useEffect, useNavigate } from 'react';
import Iicon from './images/Group 3440.svg'
import Info from './Info.js';

// import ReactPlayer from 'react-player';

function FieldForge({inputVideoUrl}) {
  const [showModal, setShowModal] = useState(false);
  const handleToggleModal = () => {
    setShowModal(!showModal);
  };

  const infocontent = {
    "description": [
      "process that utilizes various techniques such as generative modeling, automated document generation, and conversational optimization scenarios to achieve its goals. It also highlights potential benefits like increased equipment uptime and reduced operational expenditure."
    ],
    "BusinessValueProposition": [
      "Enhances Field production Potential",
      "Reduces Infrastructure CAPEX",
      "Reduces OPEX and maintenance costs"
    
    ],
    "problemStatement": [
      "Suboptimal production and decision-making: Current field development processes lack real-time optimization, leading to missed opportunities for higher production and inefficient resource allocation..",
      "Siloed data and fragmented workflows: Disconnected data sources and manual analysis hinder comprehensive understanding of field performance and limit timely and informed decision-making",
"High operational costs and equipment downtime: Manual optimization efforts are inefficient, leading to unnecessary costs and extended equipment downtime, impacting overall profitability."
    ],
    "Howdoesitwork": [
      "Data Integration and Real-Time Monitoring",
      "Generative Modeling and Optimization Scenarios",
      "Conversational Optimization and Automated Documentation",
      "Continuous Improvement and Feedback Loop"
    ],
    "Technologychoices": [
      "deployment",
      "LLM: OpenAI models"
    ]
  }

  return (
    <div className="main-container">
      <div className='In-main-container'>
        <p style={{ marginTop:"30px", fontSize: "28px", marginLeft: "20px", fontWeight: "550",color: "#AD8BFF", fontFamily: "Graphik", width:"1000px", fontSize:"15px"}}>Field Development Optimization | FieldForge</p>

        <div style={{marginTop:"30px", marginLeft:"100px"}}><img className="i-icon" src={Iicon} onClick={handleToggleModal} /></div>

        {showModal && (
          <Info
            onClose={() => setShowModal(false)} infocontent={infocontent}
          />
        )}
        <div>

        </div>

      </div>
      <div className='container-iframe'>
      <iframe id="kmsembed-1_0uqdrkdx" width="700" height="400" src="https://mediaexchange.accenture.com/embed/secure/iframe/entryId/1_0uqdrkdx/uiConfId/27188232/st/0" class="kmsembed" allowfullscreen webkitallowfullscreen mozAllowFullScreen allow="autoplay *; fullscreen *; encrypted-media *" referrerPolicy="no-referrer-when-downgrade"  frameborder="0" title="FIELD FORGE: Gen AI Field Planning and Optimization Tool"></iframe>
    </div>
    </div>
  )
}

export default FieldForge