import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HC_exporting from 'highcharts/modules/exporting';
import HC_exportData from 'highcharts/modules/export-data';
HC_exporting(Highcharts);
HC_exportData(Highcharts);

const PieChartCustomer = ({ responseData }) => {
  console.log('Response data in pie:',responseData);

  try{
     // const totalValue = responseData.response_data.pie.data.reduce((total, value) => total + value, 0);
  const chartOptions = {
    chart: {
      type: 'pie',
      width:400,
      height:200,
     // borderRadius:'10px',
      backgroundColor: 'transparent',
    },
    title: {
      text:'',
    },
    // subtitle:{
    //     text:`Total: ${totalValue}`,
    //     y:110,
    //     x:-60,
    //     floating: true,
    //     // verticalAlign: 'left',
    // },
    credits: {
        enabled: false
    },
    exporting: {
      enabled: true // Disable the exporting functionality (burger menu)
    },
    //  colors :[
    //   '#258CF3', '#68D8EE', '#3316C6', '#6952E0', '#9F97F7',
    //   '#FF5733', '#FFD700', '#36D7B7', '#FF4500', '#8B4513',
    //   '#FF69B4', '#4B0082', '#00FF00', '#8A2BE2', '#D2691E',
    //   '#00CED1', '#A52A2A', '#1E90FF', '#FF6347', '#008080'
    // ],
    colors: ['#258CF3','#68D8EE','#3316C6','#6952E0', '#9F97F7'],
    // legend: {
    //     layout: 'vertical',
    //     align: 'right',
    //     verticalAlign: 'middle',
    //   },
    plotOptions: {
        pie: {
          // innerSize: '60%', // Adjust the inner size to control the thickness of the donut
          dataLabels: {
            enabled: true
            },
          showInLegend: false
        }      
      },
      tooltip: {
        pointFormat: '<b>{point.name}</b>: {point.percentage:.1f}'
        // +`${responseData.response_data.units}`
      },
    series: [
      {
       // name: responseData.response_data.pie.columns.map((index) => ( responseData.response_data.pie.columns[index])),
        // data: responseData.response_data.pie.columns.map((columnName,index) => ({
        //   name: columnName, // Circuit name
        //   y: responseData.response_data.pie.data[index], // Total Customer Served value
        // })),

        data: [
            {
                name: 'Water',
                y: 55.02
            },
            {
                name: 'Fat',
                sliced: true,
                selected: true,
                y: 26.71
            },
            {
                name: 'Carbohydrates',
                y: 1.09
            },
            {
                name: 'Protein',
                y: 15.5
            },
            {
                name: 'Ash',
                y: 1.68
            }
        ]
      },          
    ],
  };

  return (
    <>
    <div className='col-sm-8 cust-pieChartContainer mt-2'>
        <div className='cust-pie-wrapper'>
            <HighchartsReact
                    highcharts={Highcharts}
                    options={{                
                    ...chartOptions
                    }}
                />
        </div>
    </div>
    </>
  );
  }
  catch (error) {
    console.error('Error in Pie chart component:', error);  
    return <div></div>;
  }

};

export default PieChartCustomer;
