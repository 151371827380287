import React, { useState, useEffect, useNavigate } from 'react';
import Iicon from './images/Group 3440.svg'
import Info from './Info.js';

// import ReactPlayer from 'react-player';

function Esg({ inputVideoUrl }) {
  const [showModal, setShowModal] = useState(false);
  const handleToggleModal = () => {
    setShowModal(!showModal);
  };

  const infocontent = {
    "description": [
      "The regulatory summarization and impact assessment tool leverages numerous sources to quickly deliver the characteristics and insights from new regulatory mandates"
    ],
    "BusinessValueProposition": [
      "Improves reporting accuracy",
      "Increases workforce productivity",
      "Improves regulatory compliance"
    ],
    "problemStatement": [
      "~ 18000+* manual hours are spent on authoring of Common Technical Document – Module 3, Module 2(quality) and Module 1(Labeling) per year ",
      "Effort intensive",
      "Time-consuming",
      "Error-prone",
      "Repetitive"
    ],
    "Howdoesitwork": [
      "Tracking ESG Landscape : Track evolving ESG reporting & compliance standards",
      "Generating ESG Insights : Summarize ESG regulatory documents. Customize read-outs and insights on key ESG metrics around daily activities",
      "Accelerating ESG reporting: Accelerate ESG reporting for regulatory filings, draft ESG-focused investor presentations and sustainability related marketing campaigns"

    ],
    "Technologychoices": [
      "deployment",
      "LLM: OpenAI models"
    ]
  }

  return (
    <div className="main-container">
      <div className='In-main-container'>
        <p style={{ marginTop: "30px", fontSize: "28px", marginLeft: "20px", fontWeight: "550", color: "#AD8BFF", fontFamily: "Graphik", width: "1200px", fontSize: "15px" }}>ESG compliance and reporting | Regulatory Authoring Platform</p>

        <div style={{marginTop:"30px", marginLeft:"140px"}}><img className="i-icon" src={Iicon} onClick={handleToggleModal} /></div>

        {showModal && (
          <Info
            onClose={() => setShowModal(false)} infocontent={infocontent}
          />
        )}
        <div>

        </div>

      </div>
      <div className='container-iframe'>
      <iframe id="kmsembed-1_b4p3wlq2"  width="700" height="400" src="https://mediaexchange.accenture.com/embed/secure/iframe/entryId/1_b4p3wlq2/uiConfId/27188232/st/0" class="kmsembed" allowfullscreen webkitallowfullscreen mozAllowFullScreen allow="autoplay *; fullscreen *; encrypted-media *" referrerPolicy="no-referrer-when-downgrade" frameborder="0" title="Regulatory Documentation Authoring platform - Demo video"></iframe>
    </div>
    </div>
  )
}

export default Esg