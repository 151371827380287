import './Timeseries.css';

const AccordianTableForecast = (props) =>{

    // const { data } = props?.responseData;
    const { responseData } = props;
    const { columns = [], data = [] } = props.responseData || {};

    if (responseData === undefined) {
        return (
            <div>
                <span style={{ color: 'white', display: 'flex', justifyContent: 'center', marginTop: '10px' }}>Data not present</span>
            </div>
        );
    }

    console.log('table response:',props?.responseData)
    return(<>
        {props?.responseData?.length === 0 ? 
            <div>
                <span style={{color:'white',display:'flex', justifyContent:'center', marginTop:'10px'}}>Data is not present</span>        
            </div>
        
            : 
            <div className="table-container">
            <table className="accordianTable" style={{borderRadius:'10px'}}>
                <thead>
                   

                    <tr>
                    <th rowspan="2">Timestamp</th>
                    <th colspan="2">Pressure</th>
                    <th colspan="2">Temperature</th>
                    <th colspan="2">Volume</th>
                    </tr>
                    <tr>
                    <th className="second-row">Actual</th>
                    <th className="second-row">Forecast</th>
                    <th className="second-row">Actual</th>
                    <th className="second-row">Forecast</th>
                    <th className="second-row">Actual</th>
                    <th className="second-row">Forecast</th>
                    </tr>

                        {/* <tr>
                        {columns?.map((column, index) => (
                            <th key={index}>{column}</th>
                        ))}
                        </tr> */}
                </thead>
                <tbody>
                        {data?.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                                {row?.map((cell, cellIndex) => (
                                     <td key={cellIndex}>
                                        {typeof cell === 'number' ? cell.toFixed(2) : cell}
                                     </td>
                                ))}
                            </tr>
                        ))}
                </tbody>
            
            </table>
            </div>      
        }         
    </>);
};

export default AccordianTableForecast;