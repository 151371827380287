import React, { useState, useEffect, useCallback } from 'react';
import Login from './login';
import { BrowserRouter as Router, Routes, Route, Link, useNavigate, useLocation } from 'react-router-dom';
import SOP from './SOP';
import SOPMining from './SOPMining'
import Sales from './SaleAssist';
import DetectionTabs from './DetectionTabs';
import Optimization from '../src/Optimization/otimization';
import './App.css';
import Utility from './Utility';
import primer_header from './images/localHeader.svg';
import oil from './images/oil_barrel_FILL0_wght400_GRAD0_opsz24 1.svg';
import utilities from './images/ev_charger_FILL0_wght400_GRAD0_opsz24 1.svg';
import chemical from './images/science_FILL0_wght400_GRAD0_opsz24 1.svg';
import mining from './images/outdoor_grill_FILL0_wght400_GRAD0_opsz24 1.svg';
import Doa from './Drilling_Operation_Assistant/Doa';
import CorrosionPage from './CorrosionPage';
import Vegetation from './Vegetation';
import OsduSearch from './OsduSearch';
import Scrm from './Scrm';
import Energy from './Energy';
import Sap from './Sap';
import Mining from './Mining';
import Compiq from './CompIQ';
import Chemical from './Chemical';
import SidebarLink from './SideBarLink';
import Sustainability from './sustainabilityMain';
import Apm from './Apm';
import SupplychainTower from './supplychainTower'
import Contract_Authoring from './Contract_Authoring';
import Contract_Risk_Assessment from './Contract_Risk_Assessment';
import Customer360 from './customer360';
import toggle from './images/Industry.svg';
import Bplam from './BPLAM/bplam1';
import Tabs from './BPLAM/Tabs';
import CustomerCare from './CustomerCare/CustomerCareHome';
import accn from './images/Acn_icon.svg';
import Internal from './Internal';
import SOPNew from './SOP_Chemical';
import sapicon from './images/sapic.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faIceCream } from '@fortawesome/free-solid-svg-icons';
import accenture_header from './images/accenture_header.svg';
import notification_header from './images/notification.svg';
import user_header from './images/account_circle.svg';
import logot from './images/logout.svg';
import SafetyVigilance from './Safety_Vigilance/new';
import { MsalProvider, useMsal } from '@azure/msal-react';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { EventType } from '@azure/msal-browser';
import { b2cPolicies } from './Auth/authConfig';
import { compareIssuingPolicy } from './Auth/claimUtils';
import ExtVideo from './contract';
import MarketWatcher from './MarketWatcher';
import AssetMate from './AssetMate';
import FieldForge from './FieldForge';
import Esg from './esg';
import FieldCompanion from './FieldCompanion';
import Saleslead from './saleslead';
import Smart from './smartcontract';
import LaboratoryResearchAssistant from './LaboratoryResearchAssistant';
import Refinery_Home from './Refinery_Inspection_Bot/Refinery_Home';
import ESGDomainModel from './esgDomainModel';
import Petronas from './petronas';
import Retail from './Retail_Management/Retail';
import Demo from './Demo';
import Timeseries from '../src/Timeseries/TimeseriesMain';
import Trading from "../src/Trading/Trading";

const App = () => {
    const [selectedIndustry, setSelectedIndustry] = useState('');
    const [industryCollapsed, setIndustryCollapsed] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const internal = true;
    const isScrm = location.pathname === '/Scrm';
    const test = () =>{
        (<iframe id="kmsembed-1_0te6y7on" width="400" height="300" src="https://mediaexchange.accenture.com/embed/secure/iframe/entryId/1_0te6y7on/uiConfId/27188232/st/0" class="kmsembed" allowfullscreen webkitallowfullscreen mozAllowFullScreen allow="autoplay *; fullscreen *; encrypted-media *" referrerPolicy="no-referrer-when-downgrade" frameborder="0" title="Gen AI Market Watcher for Commodity Trading"></iframe>)
      }
    function truncateText(input) {
        if (input.length > 10) {
            return input.substring(0, 10) + '...';
        }
        return input;
    }
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();

    const handleLogoutRedirect = () => {
        instance.logoutRedirect();
    };
    return (
        <div className=" main-m">
            <div >
                <div className="primary" style={{ position: "fixed", zIndex: "10000" }}>
                <img className='accentureHeader' src={accenture_header} />
                <p className='genaiHeader'>GenAI <span style={{ fontWeight: "300" }}>Sight</span></p>
                <div className='header-icon'>
                  <div className='icon-Header-circle'>
                    <img className='icon-Header-notification' src={notification_header} /></div>
                  <button type="button" className='user-header-box username-container' data-toggle="tooltip" data-placement="left" title={activeAccount && activeAccount.username ? activeAccount.username : 'Unknown'} >

                    <img className='icon-Header' src={user_header} style={{ marginTop: "1px" }} />
                    <span style={{ fontSize: "10px", marginTop: "3px", marginRight: "5px", width: "80px" }} >
                      {activeAccount && activeAccount.username ? truncateText(activeAccount.username) : 'Admin'}</span>
                  </button>
                  <div className='icon-Header-circle' style={{ marginLeft: "8px", cursor: "pointer" }}>
                    <img className='icon-Header-logout' src={logot} title="logout" onClick={handleLogoutRedirect} /></div>
                </div>
              </div>
                <div className='col-sm-12 panels-m'>
                    <div className={`col-sm-2 left-panel-m ${industryCollapsed ? 'collapsed-left-panel' : ''}`} >
                        <div>
                            <img src={toggle} className='sidebar-toggle' onClick={() => {
                                setIndustryCollapsed(!industryCollapsed);
                            }} />
                            <SidebarLink
                                to="/"
                                selectedIndustry={selectedIndustry}
                                setSelectedIndustry={setSelectedIndustry}
                                industryCollapsed={industryCollapsed}
                                setIndustryCollapsed={setIndustryCollapsed}
                                iconSrc={oil}
                                label="Oil & Gas"
                            />
                            <SidebarLink
                                to="/utility"
                                selectedIndustry={selectedIndustry}
                                setSelectedIndustry={setSelectedIndustry}
                                industryCollapsed={industryCollapsed}
                                setIndustryCollapsed={setIndustryCollapsed}
                                iconSrc={utilities}
                                label="Utilities"
                            />
                            <SidebarLink
                                to="/chemical"
                                selectedIndustry={selectedIndustry}
                                setSelectedIndustry={setSelectedIndustry}
                                industryCollapsed={industryCollapsed}
                                setIndustryCollapsed={setIndustryCollapsed}
                                iconSrc={chemical}
                                label="Chemical"
                            />
                            <SidebarLink
                                to="/mining"
                                selectedIndustry={selectedIndustry}
                                setSelectedIndustry={setSelectedIndustry}
                                industryCollapsed={industryCollapsed}
                                setIndustryCollapsed={setIndustryCollapsed}
                                iconSrc={mining}
                                label="Mining"
                            />
                            <SidebarLink
                                to="/internal"
                                selectedIndustry={selectedIndustry}
                                setSelectedIndustry={setSelectedIndustry}
                                industryCollapsed={industryCollapsed}
                                setIndustryCollapsed={setIndustryCollapsed}
                                iconSrc={accn}
                                label="Cross Industry"
                                internal={internal}
                            />
                                <SidebarLink
                          to="/sap"
                          selectedIndustry={selectedIndustry}
                          setSelectedIndustry={setSelectedIndustry}
                          industryCollapsed={industryCollapsed}
                          setIndustryCollapsed={setIndustryCollapsed}
                          iconSrc={sapicon}
                          label="SAP"
                        />
                            {/* <p style={{ width: "20px", color:"grey", backgroundColor: "transparent", border:"solid", borderColor:"transparent"}} onClick={() => {
                    setIndustryCollapsed(!industryCollapsed);
                  }}>{">"}{">"}</p> */}
                        </div>
                    </div>
                    <div className={`col-sm-10 middle-panel-m ${isScrm ? 'right-scrm-section' : ''}`} style={{ marginTop: "40px", marginBottom: "5px" }}>
                        <Routes>
                            <Route path="/" element={<Energy closeSideBar={() => { setIndustryCollapsed(true); }} />} />
                            <Route path="/utility" element={<Utility closeSideBar={() => { setIndustryCollapsed(true); }} />} />
                            <Route path="/Mining" element={<Mining closeSideBar={() => { setIndustryCollapsed(true); }} />} />
                            <Route path="/chemical" element={<Chemical closeSideBar={() => { setIndustryCollapsed(true); }} />} />
                            <Route path="/internal" element={<Internal closeSideBar={() => { setIndustryCollapsed(true); }} />} />
                            <Route path="/CorrosionPage" element={<CorrosionPage />} />
                            <Route path="/detectionresult/*" element={<DetectionTabs />} />
                            <Route path="/Sustainability" element={<Sustainability />} />
                            <Route path="/compiq" element={<Compiq />} />
                            <Route path="/Apm" element={<Apm />} />
                            <Route path="/OsduSearch" element={<OsduSearch />} />
                            <Route path="/SOP" element={<SOP />} />
                            <Route path="/SOPUtility" element={<SOPMining />} />
                            <Route path="/sap" element={<Sap closeSideBar={() => { setIndustryCollapsed(true); }} />} />
                            <Route path="/drilling" element={<Doa />} />
                            <Route path="/Scrm" element={<Scrm />} />
                            <Route path="/SalesAssistance" element={<Sales />} />
                            <Route path="/VegetationManagement" element={<Vegetation />} />
                            <Route path="/Customer360" element={<Customer360 />} />
                            <Route path="/Bplam" element={<Bplam />} />
                            <Route path="/CustomerCare" element={<CustomerCare />} />
                            <Route path="/tabs-screen" element={<Tabs />} />
                            <Route path="/SOPChemical" element={<SOPNew />} />
                            <Route path="/Optimization" element={<Optimization />} />
                            <Route path="/SafetyVigilance" element={<SafetyVigilance />} />
                            <Route path="/Retail" element={<Retail />} />
                            <Route path="/contract" element={<ExtVideo inputVideoUrl={test} heading="Oil&Gas"/>} />
                            <Route path="/MarketWatcher" element={<MarketWatcher inputVideoUrl={test} heading="Oil&Gas"/>} />
                            <Route path="/AssetMate" element={<AssetMate inputVideoUrl={test} heading="Oil&Gas"/>} />
                            <Route path="/FieldForge" element={<FieldForge inputVideoUrl={test} heading="Oil&Gas"/>} />
                            <Route path="/esg" element={<Esg inputVideoUrl={test} heading="Oil&Gas"/>} />
                            <Route path="/FieldCompanion" element={<FieldCompanion inputVideoUrl={test} heading="Oil&Gas"/>} />
                            <Route path="/saleslead" element={<Saleslead inputVideoUrl={test} heading="Oil&Gas"/>} />
                            <Route path="/smartcontarct" element={<Smart inputVideoUrl={test} heading="Oil&Gas"/>} />
                            <Route path="/LaboratoryResearchAssistant" element={<LaboratoryResearchAssistant inputVideoUrl={test} heading="Oil&Gas"/>} />
                            <Route path="/ESGDomainModel" element={<ESGDomainModel inputVideoUrl={test} heading="Oil&Gas"/>} />
                            <Route path="/petronas" element={<Petronas />} />
                            <Route path="/CorrosionDemo" element={<Demo title="Corrosion Detection"/>} />
                            <Route path="/supplychainTowerDemo" element={<SupplychainTower/>} />
                            <Route path="/RefineryInspectionBot" element={<Refinery_Home />} />
                            <Route path="/OptimizationDemo" element={<Demo title="Optigenius"/>} />
                            <Route path="/SustainabilityDemo" element={<Demo title="Sustainability"/>} />
                            <Route path="/ApmDemo" element={<Demo title="APM"/>} />
                            <Route path="/RefineryInspectionBotDemo" element={<Demo title="Refinery Inspection Assistant"/>} />
                            <Route path="/Timeseries" element={<Timeseries />} />
                            <Route path="/trading" element={<Trading/>}/>
                            <Route path="/contractAuthoring" element={<Contract_Authoring/>}/>
                            <Route path="/contractRiskAssessment" element={<Contract_Risk_Assessment/>}/>
                        </Routes>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default App;
