import React, { useState } from "react";

const AddDescription = ({ onClose,onSubmit}) => {
  const [fieldName, setFieldName] = useState(null);
  const [description, setDescription] = useState(null);
  const [newFields, setNewFields] = useState({});

  const onFormSubmit = () => {
    newFields[fieldName] = description;
    if(fieldName && description){
      onSubmit(newFields)
    }
  };

  const handleFieldChange = (e) =>{
    setFieldName(e.target.value);
  };

  const handleDescriptionChange = (e) =>{
    setDescription(e.target.value);
  };

  return (
    <div className="modal-overlay-doa">
      <div className="modal-doa">
        <div className="modal-content-doa">
          <label className="generate-label-doa" style={{color: "#a9acb4"}}>Please Enter New Field </label>
          <input
            type="text"
            className="form-inputs"
            value={fieldName}
            maxLength={40}
            required
            onChange={handleFieldChange}
          />
          <label className="generate-label-doa" style={{color: "#a9acb4"}}>Please Enter Description</label>
          <input
            type="text"
            className="form-inputs"
            value={description}
            required
            onChange={handleDescriptionChange}
          />
          <div>
            <button
              type="submit"
              className="form-submit upload-button-trade"
              variant="primary"
              onClick={onFormSubmit}
            >
              Save
            </button>
            <button
              type="submit"
              className="form-submit upload-button-trade"
              variant="primary"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddDescription;
