import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const CustomerCareAccordion = ({ title, children, customHeader }) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div className="accordion">
      <div className="accordion-header" onClick={() => setIsOpen(!isOpen)}>
      {customHeader ? customHeader : <span>{title}</span>}
      <FontAwesomeIcon icon={isOpen ? faChevronDown : faChevronRight} className="arrow-icon-call" />
      </div>
      {isOpen && <div className="accordion-body">{children}</div>}
    </div>
  );
};

export default CustomerCareAccordion;
