import React from 'react';
import './Loader.css';

const Loader = () => {
    return (
        <div className="loader">
            {[...Array(8)].map((_, index) => (
                <div key={index} className={`loader__dash loader__dash--${index + 1}`}></div>
            ))}
        </div>
    );
};

export default Loader;
