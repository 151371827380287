import './CustomerCareHome.css';

const CustomerInformationTable = (props) => {

console.log('resposne in table:', props?.responseData);
const { columns, data } = props.responseData?.table;

    return(
        <div className='col-sm-12 customer-table-box'>
            <div className='col-sm-12'>
                
            </div>
                <table class="customer-table">
                    <thead>
                        <tr>
                            {columns.map((column, index) => (
                                <th key={index}>{column}</th>
                            ))}
                        </tr>
                    {/* <tr>
                        <th>Invoice Name</th>
                        <th>Reading Date</th>
                        <th>Consumption</th>
                        <th>Energy Charges</th>
                        <th>Rate</th>
                    </tr> */}
                    </thead>
                    <tbody>
                    {data.map((row, rowIndex) => (
                     <tr key={rowIndex} >
                        {row.map((cell, cellIndex) => (
                            <td key={cellIndex} >{cell}</td>
                            ))}
                        </tr>
                    ))}
                    </tbody>
                </table>
        </div>
    );
};

export default CustomerInformationTable;