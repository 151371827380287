import React, { useState, useEffect, useNavigate } from 'react';
import Iicon from './images/Group 3440.svg'
import Info from './Info.js';

// import ReactPlayer from 'react-player';

function FieldCompanion({ inputVideoUrl }) {
  const [showModal, setShowModal] = useState(false);
  const handleToggleModal = () => {
    setShowModal(!showModal);
  };

  const infocontent = {
    "description": [
      "A Gen AI solution for Asset management that scans work orders, operating manuals and troubleshooting guidelines and generates job-specific safety briefings and guidelines for operations, maintenance and troubleshooting."
    ],
    "BusinessValueProposition": [
      "Improves operational safety",
      "Reduces unplanned downtime",
      "Enhances workforce productivity"
    ],
    "problemStatement": [
      "There is need for asset management solution that could help in identifying key maintenance steps for specific equipment and recommend suitable safety devices, thus aiming to enhance accessibility, accuracy, and efficiency in providing essential details for equipment maintenance and safety protocols."
    ],
    "Howdoesitwork": [
      "Safety assessment: Summarize safety guidelines (including simultaneous operations) and augment risk assessments and safety planning with weather and location data",
      "Execution and troubleshooting: Optimize for most effective operational and maintenance steps and troubleshooting guidelines, while continuously checking spares and resource availability",
      "Report generation: Automate the generation of close-out reports, summarizing as-found condition, executed actions, and updates to safety protocols, reducing repetitive tasks for front-line workers"
    ],
    "Technologychoices": [
      " "
    ]
  }

  return (
    <div className="main-container">
      <div className='In-main-container'>
        <p style={{ marginTop: "30px", fontSize: "28px", marginLeft: "20px", fontWeight: "550", color: "#AD8BFF", fontFamily: "Graphik", width: "1000px", fontSize: "15px" }}>Field Worker Companion | FieldCompanion</p>

        <div style={{marginTop:"30px", marginLeft:"100px"}}><img className="i-icon" src={Iicon} onClick={handleToggleModal} /></div>

        {showModal && (
          <Info
            onClose={() => setShowModal(false)} infocontent={infocontent}
          />
        )}
        <div>

        </div>
        
      </div>
      <div className='container-iframe'>
      <iframe id="kmsembed-1_4btn0w6y"  width="700" height="400" src="https://mediaexchange.accenture.com/embed/secure/iframe/entryId/1_4btn0w6y/uiConfId/27188232/st/0" class="kmsembed" allowfullscreen webkitallowfullscreen mozAllowFullScreen allow="autoplay *; fullscreen *; encrypted-media *" referrerPolicy="no-referrer-when-downgrade" frameborder="0" title="FieldCompanion | GenAI for Field Asset Management"></iframe>
    </div>
    </div>
  )
}

export default FieldCompanion