import React,{useEffect, useState} from 'react';
import EquipmentDtlTable from './EquipmentDtlTable';
import SwitchButton from './SwitchButton';
import AreaChart from './AreaChart';
import graphIcon from '../../src/images/graphTime.png';
import tableIcon from '../../src/images/TableTime.png';
import graphIcon1 from '../../src/images/graphTime1.png';
import tableIcon1 from '../../src/images/TableTime1.png';
import settingsIcon from '../../src/images/settingsTime.png';
import Accordion from 'react-bootstrap/Accordion';
import AccordianTable from './AccordianTable';
import axios from 'axios';
import AccordianTableForecast from './AccordianTableForecast';

const InsightsTab = () =>{

    const [insightTab, setInsightTab] = useState('input');
    const [viewBtn, setViewBtn] = useState('graph');
    const [settingState, setSettingState] = useState(false);
    const [tempState, setTempState] = useState([]);
    const [pressureState, setPressureState] = useState([]);
    const [volumeState, setVolumeState] = useState([]);
    const [actualTable, setActualTable] = useState([]);
    const [forecastTable, setForecastTable] = useState([]);
    const [tempStateActual, setTempStateActual] = useState([]);
    const [pressureStateActual, setPressureStateActual] = useState([]);
    const [volumeStateActual, setVolumeStateActual] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const [disableBtn, setDisableBtn] = useState(false);
    const [ state, setState ] = useState({
        isEnable: false
      });
    
      const [values, setValues] = useState([
        { label: 'Pressures (kPa)', actualValue: 3392,value: 3392},
        { label: 'Temperature (C)', actualValue: 1000, value: 1000 },
        { label: 'Volume (m3/h)', actualValue: 3312,value: 3312 }
      ]);

      const toggleSwitchButton =() => {
        setState({ isEnable: !state.isEnable });
        console.log('Toggle value:',state.isEnable);   
        if(state.isEnable === false){
            handleTestmodelapi();   
        }            
      }

      const handleTestmodelapi = async() =>{
        setShowLoader(true);
        //   if(state.isEnable === false){

        try{
            const generateRequest = {             
                status:'apply'
            }
           const response = await axios.post('http://10.0.0.4:3010/testmodel',generateRequest);
           setTempState(response?.data?.response_data?.forecasted_line?.TemperatureArea);
           setPressureState(response?.data?.response_data?.forecasted_line?.pressureArea);
           setVolumeState(response?.data?.response_data?.forecasted_line?.VolumeArea);
           setActualTable(response?.data?.response_data?.actual_table)
           setForecastTable(response?.data?.response_data?.forecasted_table);
           setTempStateActual(response?.data?.response_data?.actual_data_line_graph?.TemperatureArea);
           setPressureStateActual(response?.data?.response_data?.actual_data_line_graph?.pressureArea);
           setVolumeStateActual(response?.data?.response_data?.actual_data_line_graph?.VolumeArea);
           console.log('actual_table data:',response?.data?.response_data?.actual_table);
        }
        catch(error){
           console.error('Error during the apply request:', error);             
           if (error.response) {
               // Server responded with a status other than 2xx
               console.error('Response error:', error.response.data);           
           } else if (error.request) {
               // Request was made but no response was received
               console.error('No response received:', error.request);   
           } else {
               // Something happened in setting up the request
               console.error('Error setting up request:', error.message);       
           }
        }finally{
           setShowLoader(false);
        }
    }

    const handleTabChange = (tab) =>{
        setInsightTab(tab);
    }

    const handleBtnChange = (btn) =>{
        setViewBtn(btn);
       // setSettingState(!settingState);
        setSettingState(false);
    }

    const handleSettingChange = (setting) =>{
      //  setState(prevState => ({ ...prevState, isEnable: setting }));
        console.log('setting value:',setting);
      //  setViewBtn('setting');
        setSettingState(!setting);
    }

    const handleIncrement = (index) => {
        const newValues = [...values];
        newValues[index].value += 1;
        setValues(newValues);
      };

    const handleDecrement = (index) => {
        const newValues = [...values];
        newValues[index].value -= 1;
        setValues(newValues);
      };
    
     const handleChange = (index, event) => {
        const newValues = [...values];
        newValues[index].value = parseFloat(event.target.value);
        setValues(newValues);
      }; 

      const handleApply = async(param) => {
        try{
            console.log('inside apply: ',values[0].value);
            const generateRequest = {
                ctemp : values[1].value,
                cpressure : values[0].value,
                cvolume: values[2].value,
                cequipment:"EQP1002",
                status:param
            }
            const response = await axios.post('http://10.0.0.4:3010/callazapi',generateRequest);
            const result = response.data.data;
            console.log('response from apply button: ',result );
            setDisableBtn(false);
        } catch (error) {
        console.error('Error during the apply request:', error);             
        if (error.response) {
            // Server responded with a status other than 2xx
            console.error('Response error:', error.response.data);           
        } else if (error.request) {
            // Request was made but no response was received
            console.error('No response received:', error.request);   
        } else {
            // Something happened in setting up the request
            console.error('Error setting up request:', error.message);       
        }
    }
      };

      const Loading = () => {
        // scrollToBottom();
        return (
          <div className='loader'>
            <div className='loader-text'></div>
            <div className='dot red'></div>
            <div className='dot green'></div>
            <div className='dot blue'></div>
          </div>
        );
      };

      useEffect(()=>{
       // handleTestmodelapi();
        //const values ={}
        handleApply("default");

      },[]);

      useEffect(()=>{
        console.log('tempstate data:',tempState);
      },[tempState,pressureState,volumeState]);

    return(
        <>
        <div className="col-sm-12 insightTab">
            <div className="col-sm-12 eqp-head-style">
                <span className="boldFont-style">EQUIPMENT DETAILS</span>
            </div>
            <div className='col-sm-12 '>
                <EquipmentDtlTable></EquipmentDtlTable>
            </div>
            <div className='col-sm-12 allDtlDiv mt-1'>
               <span className='allDetailsTxt'>All details</span>
            </div>
            <div className="col-sm-12 eqp-head-style">
                <span className="boldFont-style">FLOW STREAMS</span>                
            </div>
            <div className='col-sm-12 inOutTab'>
            <div className='col-sm-6 tab-flow-style'>
                <div className="tabsTime ">
                                <button
                                    className={`tabTime-button ${insightTab === 'input' ? 'active' : ''}`}
                                    onClick={() => handleTabChange('input')}
                                >
                                    Input
                                </button>
                                <button
                                    className={`tabTime-button ${insightTab === 'output' ? 'active' : ''}`}
                                    onClick={() => handleTabChange('output')}
                                >
                                    Output
                                </button>
                           
                </div>
            </div>
            </div>
          
            <div className='col-sm-12'>
                <div className="">
                    {insightTab === 'input' && <>
                        <div className='col-sm-12'>
                            <div className='col-sm-12 mt-3' >
                                <div className='col-sm-12' style={{display:'flex'}}>
                                    <div className='col-sm-9'>
                                        <SwitchButton disableBtn={disableBtn}  type="button" active={state.isEnable} clicked={()=>toggleSwitchButton()}>Functional</SwitchButton>
                                    </div>
                                    <div className='col-sm-3'>
                                        <button
                                            className={`graphViewBtn`}
                                            style={{backgroundColor : viewBtn === 'graph'? '#8F60FF' : ''  }}      
                                            onClick={()=> handleBtnChange('graph')}
                                        ><img src={viewBtn === 'graph' ? graphIcon : graphIcon1} alt='' className='viewICon'></img></button>
                                        <button
                                            className={`tableViewBtn`}
                                            style={{backgroundColor : viewBtn === 'table'? '#8F60FF' : ''  }}                                        
                                            onClick={()=> handleBtnChange('table')}
                                        ><img src={viewBtn === 'table' ? tableIcon1 : tableIcon} alt='' className='viewICon'></img></button>
                                    </div>
                                </div>
                               
                                                              
                                <div className='col-sm-12 accordianBox mt-2' >
                                    <Accordion defaultActiveKey="0" className="custom-accordion">
                                        <Accordion.Item eventKey='0'>
                                            <Accordion.Header>Crude to Heater<img src={settingsIcon} className='settingsIcon' alt='' 
                                            //onClick={()=>handleSettingChange(false)}
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                handleSettingChange(settingState);
                                            }} 
                                            ></img></Accordion.Header>
                                            <Accordion.Body>     
                                                {viewBtn === 'graph' && !showLoader && settingState === false &&(
                                                        <>
                                                            <div className='col-sm-12 mt-2' style={{display:'flex'}}>
                                                                    <div className='col-sm-7'>
                                                                        <span className='live-style'>LIVE</span>
                                                                        <span className='toggleText'>6 days</span>
                                                                        <span className='toggleText'>1 month</span>
                                                                        <span className='toggleText'>3 months</span>
                                                                    </div>
                                                                    {state.isEnable &&(
                                                                        <div className='col-sm-3'  style={{display:'flex'}}>
                                                                            <div className='activeToggleForcastCircle'></div>
                                                                            <span className='activeToggletext'>Forecasted</span>
                                                                        </div>   
                                                                        )
                                                                    }
                                                                    <div className='col-sm-2'  style={{display:'flex'}}>
                                                                        <div className='activeToggleCircle'></div>
                                                                        <span className='activeToggletext'>Actual</span>
                                                                    </div>   
                                                            </div>

                                                            <div className='col-sm-12 mt-2'>
                                                                {state.isEnable && !showLoader && pressureState !== undefined && pressureState.length !== 0 &&(
                                                                    <AreaChart  responseData={pressureState} comingFrom='query' titleTxt='Pressure (kPa)'></AreaChart>
                                                                )}
                                                                {!state.isEnable && !showLoader && pressureStateActual !== undefined && pressureStateActual.length !== 0 &&(                                        
                                                                    <AreaChart responseData={pressureStateActual} comingFrom='dashboard' titleTxt='Pressure (kPa)'></AreaChart>
                                                                )}    

                                                            </div>
                                                            <div className='col-sm-12 mt-2'>
                                                                {state.isEnable && !showLoader && tempState !== undefined && tempState.length !== 0 &&(                                                                  
                                                                    <AreaChart responseData={tempState}  comingFrom='query' titleTxt='Temperature (C)'></AreaChart>
                                                                )}
                                                                {!state.isEnable && !showLoader && tempStateActual !== undefined && tempStateActual.length !== 0 &&(                                        
                                                                    <AreaChart  responseData={tempStateActual} comingFrom='dashboard' titleTxt='Temperature (C)'></AreaChart>
                                                                )}                                    
                                                            </div>
                                                            <div className='col-sm-12 mt-2' style={{marginBottom:'10px'}}>
                                                                {state.isEnable && !showLoader && volumeState !== undefined && volumeState.length !== 0 &&(
                                                                    <AreaChart responseData={volumeState} comingFrom='query' titleTxt='Volume (m3/h)'></AreaChart>
                                                                )}
                                                                {!state.isEnable && !showLoader && volumeStateActual !==undefined && volumeStateActual.length !== 0 &&(                                        
                                                                    <AreaChart  responseData={volumeStateActual} comingFrom='dashboard' titleTxt='Volume (m3/h)'></AreaChart>
                                                                )}                                        
                                                            </div>  

                                                            {tempState=== undefined && tempStateActual === undefined && pressureState=== undefined
                                                            && pressureStateActual=== undefined && volumeState === undefined && volumeStateActual === undefined 
                                                            || 
                                                            tempState.length === 0 && tempStateActual.length === 0 && pressureState.length === 0
                                                            && pressureStateActual.length === 0 && volumeState.length === 0 && volumeStateActual.length === 0 
                                                            && (
                                                                <div>
                                                                    <span style={{ color: 'white', display: 'flex', justifyContent: 'center', marginTop: '10px' }}>Data not present</span>
                                                                </div>
                                                            )}

                                                        </>
                                                )}      

                                                {viewBtn === 'table' && !showLoader && settingState === false && state.isEnable === false &&(
                                                    <>
                                                          <AccordianTable responseData={actualTable}></AccordianTable>                                                         
                                                    </>  
                                                )}         

                                                {viewBtn === 'table' && !showLoader && settingState === false && state.isEnable === true &&(
                                                    <>
                                                          <AccordianTableForecast responseData={forecastTable}></AccordianTableForecast>                                                         
                                                    </>  
                                                )}   

                                                {settingState === true &&(
                                                    <div className='col-sm-12 settingBox'>
                                                        <div className='col-sm-12 settingHeader'>
                                                            <div className='col-sm-4'>KPI</div>
                                                            <div className='col-sm-4'>ACTUAL</div>
                                                            <div className='col-sm-4'>CONFIGURE</div>
                                                        </div>
                                                        {values.map((item, index) => (
                                                            <div
                                                            key={index}
                                                            className='col-sm-12 settingdata'
                                                            style={
                                                                index === values.length - 1
                                                                ? { borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }
                                                                : {}
                                                            }
                                                            >
                                                            <div className='col-sm-4'>{item.label}</div>
                                                            <div className='col-sm-4'>{item.actualValue}</div>
                                                            <div className='col-sm-4' style={{ display: 'flex', alignItems: 'center' }}>
                                                                <button className="minusBtn" onClick={() => handleDecrement(index)}>-</button>
                                                                <input
                                                                type='number'
                                                                value={item.value}
                                                                onChange={(event) => handleChange(index, event)}
                                                                style={{ width: '50px', textAlign: 'center' }}
                                                                />
                                                                <button className="plusBtn" onClick={() => handleIncrement(index)}>+</button>
                                                            </div>
                                                            </div>
                                                        ))}
                                                        <div className='col-sm-12 mt-2 settingAplyBtn'>
                                                            <button onClick={()=>handleApply("apply")}>Apply</button>
                                                        </div>
                                                    </div>
                                                )}           

                                                 {showLoader && <Loading />}        
                                         
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>

                                <div className='col-sm-12'>
                                  

                                          
                                </div>
                            </div>
                        </div>
                        
                    </>}
                    {insightTab === 'output' && <p></p>}
                </div>
            </div>

        </div>
        </>
    );
};

export default InsightsTab;