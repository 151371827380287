import React, { useState, useEffect, useNavigate } from 'react';
import Iicon from './images/Group 3440.svg'
import Info from './Info.js';

import ReactPlayer from 'react-player';

function Saleslead({ inputVideoUrl }) {
  const [showModal, setShowModal] = useState(false);
  const handleToggleModal = () => {
    setShowModal(!showModal);
  };

  const infocontent = {
    "description": [
      "sales lead assistant that automates the sales lifecycle, from identifying potential customers to providing relevant content and support throughout the buying journey",
      "By leveraging real-time insights and predictive analytics, the assistant aims to increase seller productivity, win rates, and customer engagement"
    ],
    "BusinessValueProposition": [
      "Increase Revenue",
      "Improves customer engagement",
      "Increases seller productity and win%"

    ],
    "problemStatement": [
      "inefficient lead prospecting and qualification: Manual processes for identifying and qualifying leads are time-consuming and often inaccurate, leading to wasted effort and missed opportunities",
      "Lack of personalized engagement and content: Generic communication and a one-size-fits-all approach fail to resonate with individual customers, hindering lead conversion and engagement",
      "Limited visibility and insights into the sales pipeline: Manual tracking and scattered data make it difficult to monitor progress, predict outcomes, and optimize the sales process."
    ],
    "Howdoesitwork": [
      "Automated Prospecting and Qualification",
      "Personalized Engagement and Content Delivery",
      "Sales Pipeline Management and Insights"

    ],
    "Technologychoices": [
      "deployment",
      "LLM: OpenAI models"
    ]
  }

  return (
    <div className="main-container">
      <div className='In-main-container'>
        <p style={{ marginTop: "30px", fontSize: "28px", marginLeft: "20px", fontWeight: "550", color: "#AD8BFF", fontFamily: "Graphik", width: "1000px", fontSize: "15px" }}>Sales Lead Assistant | Marketing Intelligence Assistant</p>

        <div style={{marginTop:"30px", marginLeft:"100px"}}><img className="i-icon" src={Iicon} onClick={handleToggleModal} /></div>

        {showModal && (
          <Info
            onClose={() => setShowModal(false)} infocontent={infocontent}
          />
        )}
        <div>

        </div>

      </div>
      <ReactPlayer
        url="https://www.youtube.com/watch?v=BtV_3U32Imc"
        controls={true}
        width="80%"
        height="75%"
        style={{ margin: "10px 20px" }}
      />
      {/* <iframe id="kmsembed-1_iat16f3l" width="700" height="300" src="https://mediaexchange.accenture.com/embed/secure/iframe/entryId/1_iat16f3l/uiConfId/27188232/st/0" class="kmsembed" allowfullscreen webkitallowfullscreen mozAllowFullScreen allow="autoplay *; fullscreen *; encrypted-media *" referrerPolicy="no-referrer-when-downgrade"  frameborder="0" title="Cost & Productivity Reinvention with Gen AI"></iframe> */}
    </div>
  )
}

export default Saleslead