import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const Linechart = ({ rawData }) => {
    // Group data by severity
    const groupedData = {};
    rawData.forEach(item => {
        let severity = item.Severity || "Low"; // If Severity is empty, consider it as "Low"
        severity = severity.charAt(0).toUpperCase() + severity.slice(1).toLowerCase(); // Normalize severity
        if (!groupedData[severity]) {
            groupedData[severity] = [];
        }
        groupedData[severity].push({
            x: new Date(item.Date).getTime(), // Store timestamp for sorting
            y: item.Records,
            severity: severity
        });
    });

    const getColor = (severity) => {
        switch (severity) {
            case 'Low':
                return 'green';
            case 'Moderate':
                return 'rgba(255, 255, 0, 1)';
            case 'High':
                return '#FF3D00';
            case 'Critical':
                return 'maroon';
            default:
                return 'white';
        }
    };

    const options = {
        chart: {
            type: 'line',
            width: 550,
            height: 180,
            margin: [20, 1, 10, 1],
            backgroundColor: 'transparent'
        },
        title: {
            text: null
        },
        xAxis: {
            type: 'datetime',
            tickColor: 'white',
            labels: {
                style: {
                    color: 'white',
                    fontSize: '10px'
                },
                formatter: function () {
                    return Highcharts.dateFormat('%m/%d/%Y', this.value);
                }
            }
        },
        yAxis: {
            title: {
                text: 'Records',
                style: {
                    color: 'white',
                    fontSize: '14px'
                }
            },
            tickColor: 'white',
            labels: {
                style: {
                    color: 'white',
                    fontSize: '10px'
                }
            }
        },
        tooltip: {
            headerFormat: '<span style="font-size: 10px">{point.key}</span><br/>',
            pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
            backgroundColor: '#f5f5f5',
            borderRadius: 10,
            padding: 10,
            style: {
                color: 'black'
            }
        },
        legend: {
            enabled: true,
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'top',
            itemStyle: {
                color: 'white',
                fontSize: '11px',
                fontWeight: 'normal'
            },
            itemMarginBottom: 2,
            symbolWidth: 10,
            symbolHeight: 10,
            margin: 0,
            padding: 0,
            itemDistance: 10,
            labelFormatter: function () {
                return this.name;
            }
        },
        series: Object.keys(groupedData).map((severity, index) => ({
            name: severity,
            data: groupedData[severity],
            color: getColor(severity)
        }))
    };

    return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default Linechart;
