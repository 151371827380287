import React from 'react';
import Iframe from 'react-iframe'; // if you are using react-iframe
import './App.css';

const App = () => {
  return (
    <div className='sale'>

      <div className='container-iframe1'>
        <iframe  width="800" height="450" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2Fr2zw8ucPw6gNS29uH4Ei0Z%2FPetronas%3Ftype%3Ddesign%26node-id%3D74-317%26t%3DqdWHzVaVkzU3BOTM-1%26scaling%3Dscale-down%26page-id%3D0%253A1%26starting-point-node-id%3D2%253A408%26mode%3Ddesign" allowFullScreen></iframe>
      </div>
    </div>
  );
}

export default App;
