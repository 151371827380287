// import React from "react";
// import ReactDOM from "react-dom";

// import { BrowserRouter } from "react-router-dom";

// import "./index.css";
// import App from './App';
// import { MsalProvider } from "@azure/msal-react";
// import { Configuration,  PublicClientApplication } from "@azure/msal-browser";


// const msalConfig ={
//   auth: {
//     clientId: '0d7414e6-d3fc-4517-bcc2-591e5d0788bf',
//     authority: 'https://login.microsoftonline.com/a1c14176-c15f-4f4c-92ec-a532cdef5aa1',
//     redirectUri: 'http://localhost:3000'
//   }
// };

// const msalInstance = new PublicClientApplication(msalConfig);
// ReactDOM.render(
//       <BrowserRouter>
//        <MsalProvider instance={msalInstance}>
//         <App />
//         </MsalProvider>
//       </BrowserRouter>,
//   document.getElementById('root')
// );

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import { BrowserRouter } from 'react-router-dom';
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { msalConfig } from './Auth/authConfig';

import 'bootstrap/dist/css/bootstrap.min.css';
// import './styles/index.css';

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback((event) => {
    if (
        (event.eventType === EventType.LOGIN_SUCCESS ||
            event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
            event.eventType === EventType.SSO_SILENT_SUCCESS) &&
        event.payload.account
    ) {
        msalInstance.setActiveAccount(event.payload.account);
    }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <App instance1={msalInstance} />
        </BrowserRouter>
    </React.StrictMode>
);