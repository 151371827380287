import React, { useState } from 'react';

const Dropdown = ({ options, onSelect }) => {
  const [selectedOption, setSelectedOption] = useState(options[0]);

  const handleSelect = (option) => {
    setSelectedOption(option);
    onSelect(option);
  };

  return (
    <div className="dropdown" style={{  color: 'white',fontSize:'13px',marginTop:'10px' ,marginLeft:'35%',fontWeight:'500'}}>
      <select value={selectedOption} onChange={(e) => handleSelect(e.target.value)} style={{ backgroundColor: 'transparent', color: 'white', border: 'none', outline: 'none' }}>
        {options.map((option, index) => (
          <option key={index} value={option}>{option}</option>
        ))}
      </select>
    </div>
  );
};

export default Dropdown;
