import React, { useState,useEffect } from 'react';
import NewComponent from './new1'; // Import the component from new1.js
import LineChart from './LineChart';
import {Oval} from 'react-loader-spinner'; 
import Dropdown from './dropdown';
import Dropdown1 from './dropdown1';
import Dropdown2 from './dropdown2';
import Table from './table';
import TileCarousel from './tiles';

function TabComponent() {
  const [activeTab, setActiveTab] = useState(1);
  const [selectedTimePeriod, setSelectedTimePeriod] = useState('This Week');
  const [weekStartDate, setWeekStartDate] = useState('');
  const [loading, setLoading] = useState(true);
  const [weekEndDate, setWeekEndDate] = useState('');
  const [monthStartDate, setMonthStartDate] = useState('');
  const [monthEndDate, setMonthEndDate] = useState('');
  const [parameters, setParameters] = useState([0, 0, 0]);
  const [apiData1, setApiData1] = useState(null);
  const [apiData2, setApiData2] = useState(null);
  const [apiData3, setApiData3] = useState(null);
  const [apiData4, setApiData4] = useState(null);
  const [apiData5, setApiData5] = useState(null);
  const [apiData6, setApiData6] = useState(null);
  useEffect(() => {
    let startDate;
    let EndDate;
    if (selectedTimePeriod === 'This Week') {
      // Calculate start and end dates for this week
      const today = new Date();
      const firstDayOfWeek = new Date(today.getFullYear(), today.getMonth(), 1);
      const lastDayOfWeek = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      setWeekStartDate(firstDayOfWeek.toISOString().slice(0, 10));
      startDate=firstDayOfWeek.toISOString().slice(0, 10);
      setWeekEndDate(lastDayOfWeek.toISOString().slice(0, 10));
      EndDate=lastDayOfWeek.toISOString().slice(0, 10);
    } else if (selectedTimePeriod === 'This Month') {
      // Calculate start and end dates for this month
      const today = new Date();
      const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
      const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      setMonthStartDate(firstDayOfMonth.toISOString().slice(0, 10));
      setMonthEndDate(lastDayOfMonth.toISOString().slice(0, 10));
    }
    fetchData(startDate,EndDate)
  }, [selectedTimePeriod]);

  
    const fetchData = async (startDate,endDate) => {
        try {
            // let startDate, endDate;

            // if (selectedTimePeriod === 'This Week') {
            //     startDate = weekStartDate;
            //     endDate = weekEndDate;
            // } else if (selectedTimePeriod === 'This Month') {
            //     startDate = monthStartDate;
            //     endDate = monthEndDate;
            // } 
            //setLoading(true);
            console.log("qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq",loading)
            // Call API 1
            const response1 = await fetch('https://industrygenai.accenture.com/sv/dashboard/status', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  from_date:startDate,
                  to_date:endDate
                })
            });
            const data1 = await response1.json();
            console.log("!11111",data1)
            // Extract values from apiData1
            const resolved = data1.find(item => item.hasOwnProperty('Resolved'))?.Resolved || 0;
            const inProgress = data1.find(item => item.hasOwnProperty('In-Progress'))?.['In-Progress'] || 0;
            const newCases = data1.find(item => item.hasOwnProperty('New'))?.New || 0;

            // Set parameters in the order of 'Resolved', 'In-Progress', 'New'
            setParameters([resolved, inProgress, newCases]);
            setApiData1(data1);

            // Call API 2
            const response2 = await fetch('https://industrygenai.accenture.com/sv/dashboard/severity', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  from_date:startDate,
                  to_date:endDate
                })
            });
            const data2 = await response2.json();
            console.log("!2222222",data2)
            const processData = (data) => {
              const updatedData = [...data];

            // Initialize count for "Low" severity
            let lowCount = 0;

            // Loop through data to count severity occurrences and update the count for "Low"
            updatedData.forEach(item => {
                if (item.Severity === "Low" || item.Severity === "") {
                    lowCount += parseInt(item.New);
                }
            });

            // // Update the count for "Low" severity in the first occurrence
            // const lowIndex = updatedData.findIndex(item => item.Severity === "Low");
            // updatedData[lowIndex].New = lowCount.toString();

            return updatedData;
          };
      
          const processedData = processData(data2);
      
            setApiData2(processedData);
            const response3 = await fetch('https://industrygenai.accenture.com/sv/dashboard/severitydist', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  from_date:startDate,
                  to_date:endDate
                })
            });
            const data3 = await response3.json();
            console.log("!333",data3)
            const processedData2 = processData(data3);
      
            setApiData3(processedData2);
            const response4 = await fetch('https://industrygenai.accenture.com/sv/dashboard/safetyengineer', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  from_date:startDate,
                  to_date:endDate
                })
            });
            const data4 = await response4.json();
            console.log("!444444",data4)
            setApiData4(data4);
            setLoading(false);
           
            // Repeat the same pattern for the rest of the APIs
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


console.log("11111111111111",apiData1)
console.log("22222222222222222",apiData2)
console.log("33333333333333333",apiData3)
console.log("444444444444444444444",apiData4)
  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  const CircleProgress = ({ radius, strokeWidth, parameters }) => {
    // Normalize parameters to ensure they fit within the available space
    const total = parameters.reduce((acc, curr) => acc + curr, 0);
    const normalizedParameters = parameters.map(value => value / total);
  
    const colors = ['#6956E5', ' #FB896B', '#86EEF3']; // Colors for each progress bar
    const legend = [
      { name: 'Resolved', value: normalizedParameters[0].toFixed(2) },
      { name: 'In-Progress', value: normalizedParameters[1].toFixed(2) },
      { name: 'New', value: normalizedParameters[2].toFixed(2) },
    ]; // Legend for each progress bar with parameter values
  
    const legendSpacing = 10; // Space between legends
  
    return (
      <div style={{ display: 'flex', flexDirection: 'column',marginLeft:'10px' }}>
        {legend.map((item, index) => (
          <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
            {/* Progress bar */}
            <div
              style={{
                width: `${normalizedParameters[index] * 100}%`,
                height: strokeWidth,
                backgroundColor: colors[index],
                marginRight: 10,
                position: 'relative',
                cursor: 'pointer'
              }}
              title={`${item.name}: ${item.value}`}
            ></div>
            {/* Legend */}
            <div style={{ color: colors[index] }}>
            {item.name}: {item.value}
          </div>
          </div>
        ))}
      </div>
    );
  };
  
  
 
  const handleTimePeriodSelect = (value) => {
    setSelectedTimePeriod(value);
  };
  // const parameters = [0.3, 0.6, 0.9]; // Progress for all three parameters

  return (
    <div>
      <div className="tabs">

        <button
          className={activeTab === 1 ? "active" : ""}
          onClick={() => handleTabClick(1)}
        >
          Safety Dashboard
        </button>
        <button
          className={activeTab === 2 ? "active" : ""}
          onClick={() => handleTabClick(2)}
        >
          Surveillance Stream
        </button>
      </div>
      <div>
            {loading && ( // Display loader only if loading is true
                <div className="loader-overlay">
                    <Oval type="Oval" color="#00BFFF" height={50} width={50} />
                </div>
            )}
            {/* Your other components */}
        </div>
      <div className="tab-content">
      
        {activeTab === 1 && (
          <div>
            <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '20px', marginTop: '10px' }}>
            <div style={{ width: "390px",display:'flex',flexDirection:'column', height: "220px", borderRadius: '10px', backgroundColor: 'rgba(59, 65, 102, 1)' }} className="box">
              <div style={{display:'flex',flexDirection:'row',marginTop:'0px'}}>
                    <div  style={{marginTop:'0px',width:'100%',color:'white',marginLeft:'-110px',fontWeight:'500'}}>Case </div>
                    <div  style={{marginTop:'0px',width:'100%',marginRight:'150px',color:'white',marginLeft:'5px',fontWeight:'500'}}> Status</div>
                    
                  
                    </div>
                    <div style={{width:'90%',marginTop:'20px'}}>
              {parameters&&<CircleProgress radius={40} strokeWidth={6} parameters={parameters}/>}
</div>
              </div>
             <div style={{ width: "390px",display:'flex',flexDirection:'column', height: "220px", borderRadius: '10px', backgroundColor: 'rgba(59, 65, 102, 1)' }} className="box">
              <div style={{display:'flex',flexDirection:'row',marginTop:'0px'}}>
                    <div  style={{marginTop:'0px',width:'100%',color:'white',marginLeft:'-110px',fontWeight:'500'}}>Case </div>
                    <div  style={{marginTop:'0px',width:'100%',marginRight:'150px',color:'white',marginLeft:'5px',fontWeight:'500'}}> Severity</div>
                    
                  
                    </div>
                    <div>
                    {apiData2 && (
    <table style={{ width: '250%', marginLeft: '-100px', marginRight: '10px', paddingLeft: '0px', marginTop: '10px', borderCollapse: 'collapse' }}>
        <thead style={{ color: 'white', fontSize: '13px', borderBottom: '1px solid white' }}>
            <tr style={{ paddingBottom: '30px' }}>
                <th style={{ paddingBottom: '5px' }}>Severity</th>
                <th style={{ paddingBottom: '5px' }}>New</th>
                <th style={{ paddingBottom: '5px' }}>In-Progress</th>
                <th style={{ paddingBottom: '5px' }}>Resolved</th>
            </tr>
        </thead>
        <tbody style={{ padding: '20px' }}>
            {apiData2.filter(item => item.Severity !== '').map((item, index) => (
                <tr style={{ padding: '20px', borderTop: '1px solid white', fontSize: '13px', color: 'white' }} key={index}>
                    <td style={{ margin: '10px', color: 'black', color: 'white' }}>
                        <div style={{
                            margin: '5px',
                            color: 'black',
                            paddingLeft: '10px',
                            borderRadius: '15px',
                            backgroundColor:
                                item.Severity === 'Low' ? '#FEFFD9' :
                                    item.Severity === 'Moderate' ? '#FEFFD9' :
                                        item.Severity === 'High' ? '#FFDBCC' :
                                            item.Severity === 'Critical' ? '#FFC4AB' : '',
                            border:
                                item.Severity === 'Low' ? '1px solid #DBFF00' :
                                    item.Severity === 'Moderate' ? '1px solid orange' :
                                        item.Severity === 'High' ? '1px solid #FF7A00' :
                                            item.Severity === 'Critical' ? '1px solid #FF3D00' : ''
                        }}>
                            {item.Severity}
                        </div>
                    </td>
                    <td style={{ padding: '5px', marginBottom: '-10px' }}>{item.New}</td>
                    <td>{item['In-Progress']}</td>
                    <td>{item.Resolved}</td>
                </tr>
            ))}
        </tbody>
    </table>
)}

        </div>
              </div>
              <div style={{ width: "360px", height: "220px", borderRadius: '10px', backgroundColor: 'rgba(59, 65, 102, 1)' }} className="box">
                <div style={{display:'flex',flexDirection:'row',marginTop:'-130px'}}>
                <div  style={{marginTop:'-30px',color:'white',marginLeft:'20px',fontWeight:'500'}}>Top </div>
                <div style={{color:'white',fontWeight:'500',marginTop:'-30px',marginLeft:'5px'}}>3</div>
                <div style={{color:'white',fontWeight:'500',marginTop:'-30px',marginLeft:'5px',marginRight:'50px'}}>Areas</div>
                <Dropdown  options={['This Week', 'This Month']} onSelect={handleTimePeriodSelect} />
                </div>
              <TileCarousel />
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '20px' }}>
              <div style={{ width: "640px",display:'flex',flexDirection:'column', height: "240px", borderRadius: '10px', backgroundColor: 'rgba(59, 65, 102, 1)' }} className="box">
              <div style={{display:'flex',flexDirection:'row',marginTop:'0px'}}>
                    <div  style={{marginTop:'0px',width:'100%',color:'white',marginLeft:'-260px',fontWeight:'500'}}>Severity </div>
                    <div  style={{marginTop:'0px',width:'100%',marginRight:'150px',color:'white',marginLeft:'15px',fontWeight:'500'}}> Distribution</div>
                  
                  
                    </div>
                    <div>
              {apiData3&&<LineChart rawData={apiData3}/>}</div>
              </div>
              <div style={{ width: "510px", height: "240px", display:'flex',flexDirection:'column',borderRadius: '10px', backgroundColor: 'rgba(59, 65, 102, 1)' }} className="box">
              <div style={{display:'flex',flexDirection:'row',marginTop:'0px'}}>
                    <div  style={{marginTop:'0px',width:'100%',color:'white',marginLeft:'-50px',fontWeight:'500'}}>Safety </div>
                    <div  style={{marginTop:'0px',width:'100%',marginRight:'0px',color:'white',marginLeft:'5px',fontWeight:'500'}}> Engineer's</div>
                    <div  style={{marginTop:'0px',width:'100%',marginRight:'190px',color:'white',marginLeft:'5px',fontWeight:'500'}}> Performance</div>
                   
                  
                    </div>
                {apiData4&&<Table data={apiData4}/>}
              </div>
            </div>
          </div>
        )}
        {activeTab === 2 && (
          <div>
            <NewComponent /> {/* Render NewComponent when the second tab is active */}
          </div>
        )}
      </div>
    </div>
  );
}

export default TabComponent;
