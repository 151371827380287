// 2 comp - history & upload
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import chatbot from '../images/Group 3439.svg';
import Iicon from '../images/Group 3440.svg'
import plus from '../images/iconplus.svg';
import refresh from '../images/Group 3139.svg';
import './Refinery.css';
// import Container from 'react-bootstrap/Container';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
import Chatbot from './Chatbot';
import equipement from '../images/refinery_equipemt.png';
// import Nav from 'react-bootstrap/Nav';
import toggle from '../images/Industry.svg';
import { Container, Row, Col, Navbar, Nav, NavDropdown, Offcanvas, Form, Button, Stack } from 'react-bootstrap';
import close1 from '../images/Group 3206.svg';
import browser from '../images/upload_FILL0_wght400_GRAD0_opsz24 1.svg'
import Metadata from './Metadata'
import RefineryReport from './Refinery';
import tick from '../images/tick.svg';

function Refinery() {
    const navigate = useNavigate();
    const handlePrevNav = (event) => {
        navigate('/');
    };
    const handleNewInspection = () => {
        setShowMetadata(false);
        setShowQuestion(false);
        //Todo: clear states
    };
    // const history = [{ equipment: "Air-Cooled Heat Exchanger", timestamp: "28/01/2024 10.00.05 AM" },
    // { equipment: "Fired Heater", timestamp: "28/01/2024 12.00.05 AM" },
    // { equipment: "Shell & Tube Heat Exchanger", timestamp: "28/01/2024 4.00.05 PM" },
    // { equipment: "Fired Heater", timestamp: "28/01/2024 12.00.05 AM" },
    // { equipment: "Regeneration Tower", timestamp: "28/01/2024 1.00.05 PM" }];

    const [history, setHistory] = useState([{ equipment: "Air-Cooled Heat Exchanger", timestamp: "28/01/2024 10.00.05 AM" }]);

    const [modalVisible, setModalVisible] = useState(false);

    const handleIconClick = () => {
        setModalVisible(true);
    };

    const closeModal = () => {
        setModalVisible(false);
        setShowQuestion(false);
    };
    const [fileUploaded, setFileUploaded] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [formData, setFormData] = useState(null);
    const [metdataLoading, setmetdataLoading] = useState(true);
    const [currMetadata, setCurrMetadata] = useState(null);
    const getFormattedTimestamp = () => {
        const currentDate = new Date();
        const formattedDate = currentDate.toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });

        const formattedTime = currentDate.toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: true,
        });

        const formattedTimestamp = `${formattedDate} ${formattedTime}`;
        return formattedTimestamp;
    };
    const handleCurrMetadata=(type)=>{
        const time = getFormattedTimestamp();
        const final ={
            type: type,
            time: time
        }
        setCurrMetadata(final);
    }

    const handleImageUpload = async (event) => {
        const file = event.target.files[0];
        if(file && file.name){
        const fileExtension = file.name.split('.').pop().toLowerCase();

        if (fileExtension === 'png' || fileExtension === 'jpeg' || fileExtension === 'jpg') {
            const formData = new FormData();
            formData.append('image_file', file);
            const reader = new FileReader();

            reader.onload = function () {
                const base64Image = reader.result;
                localStorage.setItem('uploadedImage', base64Image);
                console.log("base64image", base64Image)
            };

            reader.readAsDataURL(file);
            setFormData(formData)
            console.log(formData);
            setSelectedImage(URL.createObjectURL(file));
            console.log(URL.createObjectURL(file));
            setModalVisible(true);
            setFileUploaded(true);
            setTimeout(() => {
                setFileUploaded(false);
                setModalVisible(false);
            }, 2000);

        } else {
            alert('Please upload a PNG, JPEG, or JPG file.');
        }}
        else {
            console.log('File name not found...');
        }

    };
    const [showMetadata, setShowMetadata] = useState(false);
    const [metadata, setmetadata] = useState(null);
    const [selectbase64Image, setselectbase64Image] = useState(null);
    const ProcessImage = async () => {
        try {
            setShowMetadata(true);
            const response = await fetch('https://industrygenai.accenture.com/inspection/imagepredict', {
                method: 'POST',
                body: formData,
            });
            // const response = { ok: true }
            console.log(response);
            if (response.ok) {
                const blob = await response.blob();
                async function blobToBase64(blob) {
                    return new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.onloadend = () => resolve(reader.result);
                        reader.onerror = reject;
                        reader.readAsDataURL(blob);
                    });
                }
                const base64data = await blobToBase64(blob);
                setselectbase64Image(base64data);
                const metadata = response.headers.get('Metadata');
                console.log(metadata);
                if (metadata) {
                    const parseddata = JSON.parse(metadata)
                    console.log(parseddata);
                    // const parseddata = { "EquipmentName": ["Cone Roof Tank", "Flare Gas Scrubber", "Cooling Tower"], "Tokens_Used": "5", "Timestamp_Start": "2024-02-0316:17:40", "Timestamp_End": "2024-02-0316:17:48", "LLM_Model_Name": "GPT4Vision" }
                    if (parseddata && parseddata.EquipmentName && parseddata.EquipmentName[0]) {
                        const dropdownResponse = await fetch('https://industrygenai.accenture.com/inspection/dropdownpredict', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({ equip_name: parseddata.EquipmentName[0] }),
                        });
                        console.log(dropdownResponse);
                        if (response.ok) {
                            const dropdown = await dropdownResponse.json();
                            // const dropdown = {
                            //     "Dropdown_List": {
                            //         "EquipmentID": [
                            //             "TNK0000121",
                            //             "TNK0000122",
                            //             "TNK0000125"
                            //         ],
                            //         "EquipmentType": [
                            //             "Cone Roof Tank"
                            //         ],
                            //         "LLM_Model_Name": "GPT4Vision",
                            //         "ReportType": [
                            //             "Visual Inspection Report"
                            //         ],
                            //         "Site": [
                            //             "Refinery - A",
                            //             "Refinery - B"
                            //         ],
                            //         "Timestamp_End": "2024-02-0410:43:14",
                            //         "Timestamp_Start": "2024-02-0410:43:11"
                            //     },
                            //     "Equipment_Name": "Cone Roof Tank"
                            // }
                            if (dropdown && !dropdown.Exception) {
                                const data = {
                                    equipementType: parseddata.EquipmentName,
                                    dropdown: dropdown
                                }
                                console.log(data);
                                setmetadata(data);
                                handleCurrMetadata(parseddata.EquipmentName[0])
                            }
                            else {
                                throw new Error('Empty dropdown data');
                            }
                        } else {
                            throw new Error('Fetch dropdown processing failed');
                        }

                    } else {
                        throw new Error('Empty metdata');
                    }
                } else {
                    throw new Error('Empty metdata string');
                }
                setmetdataLoading(false);
            } else {
                throw new Error('Image processing failed');
            }

        } catch (error) {
            console.error(error);
        }
    }
    const [showQuestion, setShowQuestion] = useState(false);
    const [processLoading, setProcessLoading] = useState(true);
    const [question_report, setQuestion_report] = useState([]);
    const [additionaInfo, setadditionaInfo] = useState([]);
    const [finalMetadata, setFinalMetadata] = useState({});    
    const [uploadTagReport, setuploadTagReport] = useState([]);

    const ProcessReport = async (filledMetadata, formDataList, question_report) => {
        setuploadTagReport(question_report)
        console.log(formDataList);
        setShowQuestion(true);
        try {
            console.log("callQuestPredict")
            setFinalMetadata(filledMetadata)
            const response = await fetch('https://industrygenai.accenture.com/inspection/questpredict', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "input_meta": {
                        "equip_name": filledMetadata.equipmenttype,
                        "reporttype": filledMetadata.reportType
                    }, "tag": 2
                }),
            });

            if (response.ok) {
                const questionResponse = await response.json();
                // const questionResponse = {
                //     "Equipment_Name": "Cone Roof Tank",
                //     "Question_List": {
                //         "LLM_Model_Name": "GPT4Vision",
                //         "Questions": [
                //             "Additional Equipment/Design Data",
                //             "Additional Equipment/Design Data#****#Nameplate Present",
                //             "Additional Equipment/Design Data#****#Repair(s)/Clamp(s)/Sleeve(s) Present",
                //             "Additional Equipment/Design Data#****#Tank Label",
                //             "Additional Inspection Notes",
                //             "Contractor Information",
                //             "Contractor Information#****#Inspector Certification",
                //             "Contractor Information#****#Technician Name",
                //             "Equipment General",
                //             "Equipment General#****#Access Structure(s)",
                //             "Equipment General#****#Biological Growth",
                //             "Equipment General#****#Flange(s) and Bolting",
                //             "Equipment General#****#Foundation",
                //             "Equipment General#****#Nozzle(s)/Manway(s)",
                //             "Equipment General#****#Rivets",
                //             "Equipment General#****#Secondary Containment Area",
                //             "Equipment General#****#Shell",
                //             "Equipment General#****#Shell  Mounted Vent(s)",
                //             "Equipment General#****#Shell Appurtenances",
                //             "Equipment General#****#Weld(s)",
                //             "Equipment General#****#Wind Girder",
                //             "Equipment Inspection Access",
                //             "Equipment Inspection Access#****#Inspection Access",
                //             "Equipment Insulation",
                //             "Equipment Insulation#****#Insulation Issues",
                //             "Loss of Containment",
                //             "Loss of Containment#****#Historical Leakage/Seepage",
                //             "Loss of Containment#****#Leakage",
                //             "Loss of Containment#****#Seepage",
                //             "Roof",
                //             "Roof#****#EFR",
                //             "Roof#****#Fixed",
                //             "Roof#****#Geodesic Dome",
                //             "Roof#****#IFR"
                //         ],
                //         "Timestamp_End": "2024-02-0411:12:05",
                //         "Timestamp_Start": "2024-02-0411:11:59"
                //     }
                // }
                console.log(questionResponse);
                if (questionResponse && !questionResponse.Exception) {
                    if (formDataList && Array.isArray(formDataList) && formDataList.length !== 0) {
                        const response = await handleadditionalpredict(formDataList);
                        console.log(response);
                        setadditionaInfo(response)
                    }
                    if (questionResponse.Question_List && questionResponse.Question_List.Questions &&
                        Array.isArray(questionResponse.Question_List.Questions)
                        && questionResponse.Question_List.Questions.length !== 0) {
                        const questions = questionResponse.Question_List.Questions
                        const outputData = questions.reduce((result, str) => {
                            const [parent, child] = str.split("#****#");
                            const existingParent = result.find(item => item.name === parent);
                            if (!existingParent && child) {
                                result.push({ name: parent, child: [child], path: [str] });
                            } else {
                                if (child) {
                                    existingParent.child.push(child);
                                    existingParent.path.push(str);
                                }
                            }

                            return result;
                        }, []);
                        setQuestion_report(outputData);
                        console.log(outputData);
                        setProcessLoading(false);
                    } else {
                        throw new Error('Question Prediction processing failed- empty input');
                    }
                }
            } else {
                throw new Error('Question Prediction processing failed');
            }

        }
        catch (error) {
            console.log(error);
        }
    }

    const handleadditionalpredict = async (formDataList) => {
        console.log("Calling handleadditionalpredict");
        const responses = [];
        for (const formData of formDataList) {
            try {
                const response = await fetch('https://industrygenai.accenture.com/inspection/additionalimagepredict', {
                    method: 'POST',
                    body: formData,
                });
                // const response = { ok: true }
                if (response.ok) {
                    const blob = await response.blob();
                    async function blobToBase64(blob) {
                        return new Promise((resolve, reject) => {
                            const reader = new FileReader();
                            reader.onloadend = () => resolve(reader.result);
                            reader.onerror = reject;
                            reader.readAsDataURL(blob);
                        });
                    }
                    const base64data = await blobToBase64(blob);
                    const url = URL.createObjectURL(blob);
                    // const url = URL.createObjectURL(file)
                    const metadata = response.headers.get('Metadata');
                    const question = formData.get('question')
                    console.log(question);
                    // const metadata = {
                    //     "Condition": "Action Recommended",
                    //     "Notes": "The image shows a significant biological growth along the structure which could lead to corrosion and weakening of the tank's integrity over time. Immediate cleaning and treatment are necessary to prevent further damage.",
                    //     "Tokens_Used": "25", "Timestamp_Start": "2024-02-0411:41:06", "Timestamp_End": "2024-02-0411:41:14",
                    //     "LLM_Model_Name": "GPT4Vision", "question": question
                    // }
                    const parseddata = JSON.parse(metadata);
                    console.log(parseddata);
                    parseddata["image"] = base64data;
                    parseddata["question"] = question;
                    parseddata["url"] = url;
                    console.log(parseddata);
                    responses.push(parseddata);

                } else {
                    throw new Error('Image processing failed');
                }

            } catch (error) {
                console.error(error);
            }
        }

        return responses;
    };

    const Loading = () => {
        return (
            <div className="loader-sv">
                <div className="dot-cq-sv red-c-sv"></div>
                <div className="dot-cq-sv green-c-sv"></div>
                <div className="dot-cq-sv blue-c-sv"></div>
            </div>
        );
        ;
    };
    const FullscreenLoading = () => {
        return (
            <div className='loader-screen-ref'>
                <div className="loader-ref">
                    <div className="dot-cq-sv red-c-sv"></div>
                    <div className="dot-cq-sv green-c-sv"></div>
                    <div className="dot-cq-sv blue-c-sv"></div>
                </div>
                <div className='para-first-ref'>
                    <p className='para-first-ref'>LLM is started analyzing the inputs</p>
                </div>
            </div>
        );
    };
    const FullscreenLoading2 = () => {
        return (
            <div className='loader-screen-ref2'>
                <div className="loader-ref2">
                    <div className="dot-cq-sv red-c-sv"></div>
                    <div className="dot-cq-sv green-c-sv"></div>
                    <div className="dot-cq-sv blue-c-sv"></div>
                </div>
                <div className='para-first-ref2'>
                    <p className='para-first-ref2'>LLM is started analyzing the inputs <br></br> and preparing the report questionnaire</p>
                </div>
            </div>
        );
    };
    return (
        <div className='main-ria'>
            {/* <div className='Heading-nav-ria' style={{ position: "absolute", left: "7%", top: "9%" }}> */}
                {/* <span onClick={handlePrevNav} style={{ color: '#AD8BFF' }}>Oil & Gas</span> /  Refinery Inspection Assistance  */}
                {/* </div> */}
            {/* <div className='info-icon-doa' style={{ position: "absolute", left: "86%", top: "8%", cursor: "pointer"}}>
                <img className='plus-icon-ria' src={plus} />
                <p className='inspection-heading-ria' onClick={handleNewInspection}>NEW INSPECTION</p>
            </div> */}
            {showQuestion ? (processLoading ? <FullscreenLoading2 /> : <RefineryReport showQuestion={showQuestion} question_report={question_report} additionaInfo={additionaInfo} finalMetadata={finalMetadata} selectedImage={selectedImage} selectbase64Image={selectbase64Image} uploadTagReport={uploadTagReport} />) : <Container className='bot-ria-new'>
                <Row xl style={{ width: "100vw" }}>
                    <Col className='stackcol-ria gradient-ria' sm={3} >
                        <p className='report-heading-ria' style={{ marginTop: "20px" }}> Inspection History</p>
                        <Stack className='stack-ria-div'>
                            {currMetadata &&
                                <div className="p-1 stack-ria" style={{ background: 0 === 0 ? 'linear-gradient(131.99deg, #8E2DE2 6.23%, #6A19D8 85.46%)' : 'transparent' }}>
                                    <p className='item-equip-ria'> {currMetadata.type} </p>
                                    <p className='item-timestamp-ria'> {currMetadata.time} </p>
                                </div>
                            }
                            {history.map((item, index) => (
                                <div className="p-1 stack-ria" key={index} style={{ background: 'transparent' }}>
                                    {/* <div className="p-1 stack-ria" key={index}> */}
                                    <p className='item-equip-ria'> {item.equipment} </p>
                                    <p className='item-timestamp-ria'> {item.timestamp}</p>
                                    {/* <p> {item.equipment} <br /> {item.timestamp}</p> */}
                                </div>
                            ))}
                        </Stack>
                    </Col>
                    <Col className='gradient-ria2' sm={9}>
                        {showMetadata ? (metdataLoading ? <FullscreenLoading /> : <Metadata ProcessReport={ProcessReport} metadata={metadata} selectedImage={selectedImage} selectbase64Image={selectbase64Image} handleCurrMetadata={handleCurrMetadata} />) :
                            <div className='container-upload-ria'>
                                
                                <div className="right-sub-container-ria right-sub-container-ria-diplay">
                                    <div className='upload-file-container'>
                                        <p className='upload-image'>Upload Images</p>
                                        <div className='upload-image-header'>
                                            <div className='drag-n-drop-files-containers'>
                                                <div><img className="browse-logo" src={browser} /></div>
                                                <div className='placeholders'>
                                                    <div className='Placeholder1'> Drag and Drop files here</div>

                                                </div>

                                            </div>
                                            <div className='or-option'>OR</div>
                                            <div className='browse-files-containers-ria'>
                                                {/* <div><img className="browse-logo" src={browse}/></div> */}
                                                <div className='placeholders'>
                                                    <div className='Placeholder4'> Choose Files<br /> from your System </div>
                                                </div>

                                                <label htmlFor="imageUpload" className="upload-file-button-ria">
                                                    <p className='browse-text'>Browse Files</p> </label>
                                                <input className="file-class-c"
                                                    type="file"
                                                    id="imageUpload"
                                                    accept=".png, .jpeg, .jpg"
                                                    onChange={handleImageUpload}
                                                />
                                            </div>

                                            <br />
                                            <br />
                                           
                                        </div>
                                        {modalVisible && 
                                        <p className='analysis-head2-file-ria'>File uploaded successfully! <img src={tick} style={{width:"18px", marginBottom:"3px"}}/> </p>
                                        }
                                    </div>
                                </div>
                                <button className='upload-ria-button' onClick={ProcessImage}>Proceed</button>
                            </div>}
                    </Col>
                </Row>
            </Container>}

        </div>

    )
}

export default Refinery