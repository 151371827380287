import React, { useState, useEffect, useNavigate } from 'react';
import Iicon from './images/Group 3440.svg'
import Info from './Info.js';

// import ReactPlayer from 'react-player';

function AssetMate({inputVideoUrl}) {
  const [showModal, setShowModal] = useState(false);
  const handleToggleModal = () => {
    setShowModal(!showModal);
  };

  const infocontent = {
    "description": [
      "Market Watcher summarizes key market trends, generates a diverse set of market sentiment analyses and enables traders to interact with the tool using natural language.",
      "“Due to unexpected maintenance reducing our refinery output by 20%, what provisions in our contracts can we utilize to manage the gasoline deficit?”",
      " The market watcher for commodity trading tool can streamline this process by highlighting those trends and anomalies.",
      "Aggregates vital metrics, a price trends, demand supply, and inventory from any number of sources like Reuters, Bloomberg,  CNN and X"
    ],
    "BusinessValueProposition": [
      "Reduces compliance risk",
      "Improves P&L margin",
      "Enhances trader performance",
      "Reduces revenue leakage",
      "Enhances trader performance",
      "Reduces DSO",
      "Soundness, Accountability,Transparency,Liability & Compliance"
    ],
    "problemStatement": [
      "In The world of modern trading moves, at breakneck speed, traders are challenged to process vast amounts of data, stay informed on global events, and interpret shifting market sentiments.",
      "Processing and scanning large amount of data with accuracy is a challenging and time-consuming activity",
      "Traders are also challenged by the need to frequently shift their attention between short term and long term trades within their portfolios. ",
      " In trading, information is typically sourced from analysts who must manually compile and analyze data. "
    ],
    "Howdoesitwork": [
      "data analysis,",
      "decision making",
      " insight discovery across numerous industry verticals"
    ],
    "Technologychoices": [
      "GenAI",
      "Containerized cloud",
      "deployment",
      "Cloud agnostic",
      "LLM: OpenAI models"
    ]
  }

  return (
    <div className="main-container">
      <div className='In-main-container'>
      <p style={{ marginTop:"30px", fontSize: "28px", marginLeft: "20px", fontWeight: "550",color: "#AD8BFF", fontFamily: "Graphik", width:"1000px", fontSize:"15px"}}>AssetMate</p>

        <div style={{marginTop:"30px", marginLeft:"100px"}}><img className="i-icon" src={Iicon} onClick={handleToggleModal} /></div>

        {showModal && (
          <Info
            onClose={() => setShowModal(false)} infocontent={infocontent}
          />
        )}
        <div>

        </div>

      </div>
      <div className='container-iframe'>
      <iframe id="kmsembed-1_0te6y7on" width="700" height="400" src="https://mediaexchange.accenture.com/embed/secure/iframe/entryId/1_6fsp1zuc/uiConfId/27188232/st/0" class="kmsembed" allowfullscreen webkitallowfullscreen mozAllowFullScreen allow="autoplay *; fullscreen *; encrypted-media *" referrerPolicy="no-referrer-when-downgrade"  frameborder="0" title="Gen AI Market Watcher for Commodity Trading"></iframe>
    </div>
    </div>
  )
}

export default AssetMate