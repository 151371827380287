// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader {
    display: inline-block;
    width: 25px;
    height: 25px;
    position: relative;
}

.loader__dash {
    transform-origin: 15px 15px;
    animation: spin 1s linear infinite;
    background-color: radial-gradient(50% 50% at 50% 50%, rgba(237, 185, 0, 0.12) 0%, rgba(245, 134, 31, 0) 100%);
}

.loader__dash:after {
    content: " ";
    display: block;
    position: absolute;
    top: 2px;
    left: 15px;
    width: 3px;
    height: 7px;
    border-radius: 20%;
    background-color: #FFFFCC;
}

.loader__dash--1 { transform: rotate(0deg); animation-delay: -0.7s; }
.loader__dash--2 { transform: rotate(45deg); animation-delay: -0.6s; }
.loader__dash--3 { transform: rotate(90deg); animation-delay: -0.5s; }
.loader__dash--4 { transform: rotate(135deg); animation-delay: -0.4s; }
.loader__dash--5 { transform: rotate(180deg); animation-delay: -0.3s; }
.loader__dash--6 { transform: rotate(225deg); animation-delay: -0.2s; }
.loader__dash--7 { transform: rotate(270deg); animation-delay: -0.1s; }
.loader__dash--8 { transform: rotate(315deg); animation-delay: 0s; }


@keyframes spin {
    0% { opacity: 1; }
    100% { opacity: 0; }
}
`, "",{"version":3,"sources":["webpack://./src/Trading/Loader.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,2BAA2B;IAC3B,kCAAkC;IAClC,6GAA6G;AACjH;;AAEA;IACI,YAAY;IACZ,cAAc;IACd,kBAAkB;IAClB,QAAQ;IACR,UAAU;IACV,UAAU;IACV,WAAW;IACX,kBAAkB;IAClB,yBAAyB;AAC7B;;AAEA,mBAAmB,uBAAuB,EAAE,sBAAsB,EAAE;AACpE,mBAAmB,wBAAwB,EAAE,sBAAsB,EAAE;AACrE,mBAAmB,wBAAwB,EAAE,sBAAsB,EAAE;AACrE,mBAAmB,yBAAyB,EAAE,sBAAsB,EAAE;AACtE,mBAAmB,yBAAyB,EAAE,sBAAsB,EAAE;AACtE,mBAAmB,yBAAyB,EAAE,sBAAsB,EAAE;AACtE,mBAAmB,yBAAyB,EAAE,sBAAsB,EAAE;AACtE,mBAAmB,yBAAyB,EAAE,mBAAmB,EAAE;;;AAGnE;IACI,KAAK,UAAU,EAAE;IACjB,OAAO,UAAU,EAAE;AACvB","sourcesContent":[".loader {\n    display: inline-block;\n    width: 25px;\n    height: 25px;\n    position: relative;\n}\n\n.loader__dash {\n    transform-origin: 15px 15px;\n    animation: spin 1s linear infinite;\n    background-color: radial-gradient(50% 50% at 50% 50%, rgba(237, 185, 0, 0.12) 0%, rgba(245, 134, 31, 0) 100%);\n}\n\n.loader__dash:after {\n    content: \" \";\n    display: block;\n    position: absolute;\n    top: 2px;\n    left: 15px;\n    width: 3px;\n    height: 7px;\n    border-radius: 20%;\n    background-color: #FFFFCC;\n}\n\n.loader__dash--1 { transform: rotate(0deg); animation-delay: -0.7s; }\n.loader__dash--2 { transform: rotate(45deg); animation-delay: -0.6s; }\n.loader__dash--3 { transform: rotate(90deg); animation-delay: -0.5s; }\n.loader__dash--4 { transform: rotate(135deg); animation-delay: -0.4s; }\n.loader__dash--5 { transform: rotate(180deg); animation-delay: -0.3s; }\n.loader__dash--6 { transform: rotate(225deg); animation-delay: -0.2s; }\n.loader__dash--7 { transform: rotate(270deg); animation-delay: -0.1s; }\n.loader__dash--8 { transform: rotate(315deg); animation-delay: 0s; }\n\n\n@keyframes spin {\n    0% { opacity: 1; }\n    100% { opacity: 0; }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
