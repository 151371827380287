import React, { props } from 'react';
import safety_vigilance from './images/safety_vigilance.svg';
import { Link } from 'react-router-dom';
import './App.css';
import BPLAM from './images/image 21.svg';
import petronas from './images/petronas2.jpg';
import optimboard from './images/optim-board.svg';
import appInsightIcon from './images/appInsight_icon.png';
import customer_care from './images/customer_care.svg';


const isMainScreen = true;
const App = (props) => {
  // Energy.js

  // Other code for Energy.js

  return (
    <div className='energy-m'>
      <div className='sub-H'>
        <div className='head1'>Home</div>
        <div className='head'>Planning & Strategy</div>
        <div className='head'>Core Operations</div>
        <div className='head'>Financial Management</div>
        <div className='head'>Supply Chain</div>
        <div className='head'>ESG</div>
        <div className='head'>Enterprise Functions</div>
      </div >
      <div className=' icons-m'>
        <Link to='/bplam' className='icon-container1-m'>
          <img className='img-class-m2' src={BPLAM} alt="primary header" onClick={props.closeSideBar} />  <p className='landing-text'>BPLAM</p>
        </Link>
        <Link to='/SafetyVigilance' className='icon-container1-m'>
          <img className='img-class-m2' src={safety_vigilance} alt="primary header" onClick={props.closeSideBar} />  <p className='landing-text'>Safety Vigilance</p>
        </Link>
        <Link to='/petronas' className='icon-container1-m'>
          <img className='img-class-m2' src={petronas} alt="primary header" onClick={props.closeSideBar} />  <p className='landing-text'>Innovate2Innovate</p>
        </Link>
        <Link to='/Optimization' className='icon-container1-m'>
          <img style={{height:'unset'}} className='img-class-m' src={optimboard} alt="primary header"  onClick={props.closeSideBar}/>  <p className='landing-text-drill'>OptiGenius</p>
        </Link>
      </div>
      <div className=' icons-m'>
        <Link to='http://52.157.248.166/AppInsight360' className='icon-container1-m'>
            <img className='img-class-m' src={appInsightIcon} alt="primary header"  onClick={props.closeSideBar}/>  <p className='landing-text-drill'>App Insight 360</p>
          </Link>
          <Link to='/CustomerCare' className='icon-container1-m'>
          <img className='img-class-m2' src={customer_care} alt="primary header" onClick={props.closeSideBar} />  <p className='landing-text'>AI Customer Care Voice Assistant</p>
        </Link>
      </div>
    </div>
  );
};

export default App;
