import React, { useEffect, useState } from "react";
// Table from react-bootstrap
import { Table } from "react-bootstrap";
// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// To make rows collapsible
import "bootstrap/js/src/collapse.js";
import loaderImage from "../images/loader_forecast_Model.png";

const ForecastTab = () => {
  const [isTableOpen, setTableOpen] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [apiData, setAPIData] = useState();
  let [loaderVariable,setLoaderVariable]= useState(5);
  const [loaderMessage,setLoaderMessage] = useState("Running Code");

  useEffect(()=>{
    setTimeout(function() {
      if(loaderVariable<100){
        setLoaderVariable(loaderVariable*10);
        setLoaderMessage('Compiling Results');
      }
     
    }, 10000)
  },[])

  const apiCallForCreateModel = () => {
    let options = {
      method: "GET",
    };
    setShowLoader(true);
    fetch("http://10.0.0.4:3010/trainmodel", options)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setShowLoader(false);
          setAPIData([data]);
          console.log("API Called", data);
        }else{
          setShowLoader(false);
         // remove this
        }
      });
    setTableOpen(!isTableOpen);
  };

  function toggleDetails() {
    const collapseHeader = document.getElementById("collapse-header");
    const collapseRow = document.getElementById("collapse-row");
    const cssObjForDisplayHeader = window.getComputedStyle(collapseHeader, null);
    const cssObjForDisplayRow = window.getComputedStyle(collapseRow, null);

    let displayHeader = cssObjForDisplayHeader.getPropertyValue("display");
    let displayRow = cssObjForDisplayRow.getPropertyValue("display");
    collapseHeader.style.display = displayHeader == "none" ? "table-row" : "none";
    collapseRow.style.display = displayRow == "none" ? "table-row" : "none";
  }

  return (
    <>
      {!showLoader && !isTableOpen && (
        <div>
          <div className="forecast-container">
            <h3>Forecast model</h3>
            <p style={{ color: "#a2a3ab" }}>
              {" "}
              There is no forecasting model created
            </p>
          </div>
          <div className="forecast-container">
            <h3>Model benchmarking</h3>
            <div className="sub-heading">
              <p>There are no model benchmarking results available</p>
              <button
                className="create-button"
                onClick={() => apiCallForCreateModel()}
              >
                Create Model
              </button>
            </div>
          </div>
        </div>
      )}
      {!showLoader && isTableOpen && (
        <div>
          <div className="forecast-container">
            <h3>Forecast model</h3>
            <p style={{ color: "#a2a3ab" }}>
              {" "}
              There is no forecasting model created
            </p>
          </div>

          <div className="forecast-container">
            <h3>Model benchmarking</h3>
            <div className="sub-heading margin-heading">
              <button
                className="create-button"
                onClick={() => apiCallForCreateModel()}
              >
                Create Model
              </button>
            </div>
            {/* TABLE GOES HERE */}
            <div class="forecast-table-container">
              <table id="myTable">
                <thead>
                  <tr class="header">
                    <th>#</th>
                    <th>Created Date</th>
                    <th>Created By</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {apiData.map((value, key) => {
                    return (
                      <>
                        <tr key={key} id="target" className="row-color">
                          <td>{key}</td>
                          <td>{value["Train_Message"]["Creation Time"]}</td>
                          <td>{value["Train_Message"]["Created By"]}</td>
                          <td>
                            <button
                              data-toggle="collapse"
                              data-target="#hidden-table"
                              onClick={() => toggleDetails()}
                            >
                              Results
                            </button>
                          </td>
                        </tr>
                        {/* Collapsible table comp starts here */}
                        <tr id ="collapse-header" className="collapse-table-header">
                                <th>#</th>
                                <th>Forecast Model</th>
                                <th>Accuracy Rate</th>
                                <th></th>
                              </tr>
                              <tr id="collapse-row" className="collapse-table-row">
                                <td>{key}</td>
                                <td>{value["Train_Message"]["Best Model"]}</td>
                                <td>
                                  {value["Train_Message"]["Best Model Score"]}
                                  <div class="progress-bar">
                                    <div className="progress"></div>
                                  </div>
                                </td>
                                <div className="publish-button-container">
                                <button
                                  className="publish-button"
                                  // onClick={() => toggleDetails()}
                                >
                                  Publish
                                </button>
                              </div>
                              </tr>
                       {/* collapsibe table comp ends here */}
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>

            {/* TABLE COMP ENDS HERE */}
          </div>
        </div>
      )}

      {/* loader comp starts here */}
      {showLoader && (
        <div>
          <img class="api-loader" src={loaderImage} />
          <h3 className="loader-text">Gen AI is building forecasting model</h3>
          <div className="App">
    </div>
        </div>
      )}

      {/* loader comp ends here */}
    </>
  );
};

export default ForecastTab;

const ProgressBar = ({ bgcolor, completed }) => {
  const containerStyles = {
    height: 20,
    width: "100%",
    backgroundColor: "#e0e0de",
    borderRadius: 50,
    margin: 50,
  };

  const fillerStyles = {
    height: "100%",
    width: `${completed}%`,
    backgroundColor: bgcolor,
    borderRadius: "inherit",
    textAlign: "right",
  };

  const labelStyles = {
    padding: 5,
    color: "white",
    fontWeight: "bold",
  };

  return (
    <div style={containerStyles}>
      <div style={fillerStyles}>
        <span style={labelStyles}>{`${completed}%`}</span>
      </div>
    </div>
  );
};
