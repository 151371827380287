import React, { useState, useRef } from 'react';
import Slider from 'react-slick';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const TileCarousel = ({  }) => {
  const sliderRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const tiles = [
    <div style={{marginLeft:'20px'}}>
        <div style={{display:'flex',flexDirection:'row',color:'black',fontSize:'12px',marginTop:'40px',fontWeight:'500'}}>
            <div style={{backgroundColor:'#D7DAFF',borderRadius:'15px',padding:'5px',width:'45%'}}>
                <div style={{paddingBottom:'5px',paddingLeft:'20px',fontSize:'15px'}}>1</div>
                <div style={{padding:'10px'}}>GGS-Warehouse</div>
            </div>
            <div style={{backgroundColor:'#FFE1BE',borderRadius:'15px',padding:'5px',fontSize:'13px',marginLeft:'5px',width:'45%'}}>
                <div  style={{paddingBottom:'5px',paddingLeft:'20px',fontSize:'15px'}}>65</div>
                <div style={{padding:'7px'}}>Total Cases</div>
            </div>
        </div>
        <div style={{display:'flex',flexDirection:'row',color:'black',fontSize:'12px',marginTop:'5px',fontWeight:'500'}}>
        <div style={{backgroundColor:'#FFDEDE',borderRadius:'15px',padding:'5px',width:'30%'}}>
                <div style={{paddingBottom:'5px',paddingLeft:'20px',fontSize:'15px'}}>20</div>
                <div style={{padding:'10px'}}>New</div>
            </div>
            <div style={{backgroundColor:'#DCFCE7',borderRadius:'15px',padding:'5px',fontSize:'12px',marginLeft:'5px',width:'30%'}}>
                <div  style={{paddingBottom:'5px',paddingLeft:'20px',fontSize:'15px'}}>30</div>
                <div style={{padding:'7px'}}>In-Progress</div>
            </div>
            <div style={{backgroundColor:'#F3E8FF',borderRadius:'15px',padding:'5px',fontSize:'12px',marginLeft:'5px',width:'30%'}}>
                <div  style={{paddingBottom:'5px',paddingLeft:'20px',fontSize:'15px'}}>15</div>
                <div style={{padding:'7px'}}>Resolved</div>
            </div>
        </div>
    </div>,
    <div style={{marginLeft:'20px'}}>
    <div style={{display:'flex',flexDirection:'row',color:'black',fontSize:'12px',marginTop:'40px',fontWeight:'500'}}>
        <div style={{backgroundColor:'#D7DAFF',borderRadius:'15px',padding:'5px',width:'45%'}}>
            <div style={{paddingBottom:'5px',paddingLeft:'20px',fontSize:'15px'}}>2</div>
            <div style={{padding:'10px'}}>HP compression unit</div>
        </div>
        <div style={{backgroundColor:'#FFE1BE',borderRadius:'15px',padding:'5px',fontSize:'12px',marginLeft:'5px',width:'45%'}}>
            <div  style={{paddingBottom:'5px',paddingLeft:'20px',fontSize:'15px'}}>65</div>
            <div style={{padding:'7px'}}>Total Cases</div>
        </div>
    </div>
    <div style={{display:'flex',flexDirection:'row',color:'black',fontSize:'12px',marginTop:'5px',fontWeight:'500'}}>
    <div style={{backgroundColor:'#FFDEDE',borderRadius:'15px',padding:'5px',width:'30%'}}>
            <div style={{paddingBottom:'5px',paddingLeft:'20px',fontSize:'15px'}}>20</div>
            <div style={{padding:'10px'}}>New</div>
        </div>
        <div style={{backgroundColor:'#DCFCE7',borderRadius:'15px',padding:'5px',fontSize:'12px',marginLeft:'5px',width:'30%'}}>
            <div  style={{paddingBottom:'5px',paddingLeft:'20px',fontSize:'15px'}}>30</div>
            <div style={{padding:'7px'}}>In-Progress</div>
        </div>
        <div style={{backgroundColor:'#F3E8FF',borderRadius:'15px',padding:'5px',fontSize:'12px',marginLeft:'5px',width:'30%'}}>
            <div  style={{paddingBottom:'5px',paddingLeft:'20px',fontSize:'15px'}}>15</div>
            <div style={{padding:'7px'}}>Resolved</div>
        </div>
    </div>
</div>,
    <div style={{marginLeft:'20px'}}>
    <div style={{display:'flex',flexDirection:'row',color:'black',fontSize:'12px',marginTop:'40px',fontWeight:'500'}}>
        <div style={{backgroundColor:'#D7DAFF',borderRadius:'15px',padding:'5px',width:'45%'}}>
            <div style={{paddingBottom:'5px',paddingLeft:'20px',fontSize:'15px'}}>3</div>
            <div style={{padding:'10px'}}>Drilling Rig</div>
        </div>
        <div style={{backgroundColor:'#FFE1BE',borderRadius:'15px',padding:'5px',fontSize:'12px',marginLeft:'5px',width:'45%'}}>
            <div  style={{paddingBottom:'5px',paddingLeft:'20px',fontSize:'15px'}}>65</div>
            <div style={{padding:'7px'}}>Total Cases</div>
        </div>
    </div>
    <div style={{display:'flex',flexDirection:'row',color:'black',fontSize:'12px',marginTop:'5px',fontWeight:'500'}}>
    <div style={{backgroundColor:'#FFDEDE',borderRadius:'15px',padding:'5px',width:'30%'}}>
            <div style={{paddingBottom:'5px',paddingLeft:'20px',fontSize:'15px'}}>20</div>
            <div style={{padding:'10px'}}>New</div>
        </div>
        <div style={{backgroundColor:'#DCFCE7',borderRadius:'15px',padding:'5px',fontSize:'12px',marginLeft:'5px',width:'30%'}}>
            <div  style={{paddingBottom:'5px',paddingLeft:'20px',fontSize:'15px'}}>30</div>
            <div style={{padding:'7px'}}>In-Progress</div>
        </div>
        <div style={{backgroundColor:'#F3E8FF',borderRadius:'15px',padding:'5px',fontSize:'12px',marginLeft:'5px',width:'30%'}}>
            <div  style={{paddingBottom:'5px',paddingLeft:'20px',fontSize:'15px'}}>15</div>
            <div style={{padding:'7px'}}>Resolved</div>
        </div>
    </div>
</div>,
    // Add more tiles as needed
  ];
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    afterChange: current => setCurrentSlide(current),
  };

  const goToNext = () => {
    sliderRef.current.slickNext();
  };

  const goToPrev = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <div style={{width:'84%',marginLeft:'-215px'}} className="tile-carousel-container">
      <Slider ref={sliderRef} {...settings}>
        {tiles.map((tile, index) => (
          <div key={index} className="tile">
            {tile}
          </div>
        ))}
      </Slider>
      
    </div>
  );
};

export default TileCarousel;
