import React, { useState } from 'react';
import InsightsTab from './InsightsTab';
import ForecastTab from './ForecastTab';
import timeseriesImg from '../../src/images/timeseriesImg.png';
import zoomInImg from '../../src/images/zommIn.png';
import zoomOutImg from '../../src/images/zommOut.png';

import './Timeseries.css';

const TimeseriesMain = () => {
    const [zoomLevel, setZoomLevel] = useState(1);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [activeTab, setActiveTab] = useState('tab1');
    const [selectedValue, setSelectedValue] = useState('');
   

    const handleZoomIn = () => {
        setZoomLevel(prevZoom => Math.min(prevZoom + 0.1, 3)); // Maximum zoom level 3x
    };

    const handleZoomOut = () => {
        setZoomLevel(prevZoom => Math.max(prevZoom - 0.1, 0.5)); // Minimum zoom level 0.5x
    };

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    const handleDropdownChange = (event) => {
        setSelectedValue(event.target.value);
      };

    return (
        <>
            <div className='col-sm-12'>
            <div className="col-sm-12">
               <select className='timeseries-dropdown' id="dropdown" value={selectedValue} onChange={handleDropdownChange}>
                    <option value="" disabled hidden>Select an option</option>
                    <option value="BaunariRef">Baurani IOL Refinery</option>
                    <option value="MangolreRef">Mangalore IOL Refinery</option>
                    <option value="MangolreRef">Mumbai IOL Refinery</option>
               </select>
               <div className='col-sm-12'>
                    <span className='timeseries-ref-name'>Baurani IOL Refinery</span>
               </div>
            </div>
            <div className="col-sm-12 image-container-timeseries">
                <img
                    src={timeseriesImg}
                    alt=''
                    className='time-img-style'
                    style={{ transform: `scale(${zoomLevel})` }}
                    onClick={toggleCollapse}
                />
                <div className="controls">
                    <img
                        src={zoomInImg}
                        alt='Zoom In'
                        className='zoom-button'
                        onClick={handleZoomIn}
                    />
                    <img
                        src={zoomOutImg}
                        alt='Zoom Out'
                        className='zoom-button'
                        onClick={handleZoomOut}
                    />               
                </div>

                {isCollapsed && (
                    <div className="collapsible">
                       
                        <div className="collapsible-content">
                            {/* <button className="close-button" onClick={toggleCollapse}>✖</button> */}
                            <div className="tabsTime">
                                <button
                                    className={`tabTime-button ${activeTab === 'tab1' ? 'active' : ''}`}
                                    onClick={() => handleTabChange('tab1')}
                                >
                                    Insights
                                </button>
                                <button
                                    className={`tabTime-button ${activeTab === 'tab2' ? 'active' : ''}`}
                                    onClick={() => handleTabChange('tab2')}
                                >
                                    Forecast Model
                                </button>
                           
                            </div>
                                <div className="">
                                    {activeTab === 'tab1' && <InsightsTab></InsightsTab>}
                                    {activeTab === 'tab2' && <ForecastTab></ForecastTab>}
                                </div>
                        </div>
                    </div>
                )}
            </div>
            </div>
          
        </>
    );
};

export default TimeseriesMain;
