import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const SeverityHistoryChart = ({ data }) => {
    // Transform data to fit Highcharts format and select only 5 data points
    const seriesData = data.map(item => ({
      x: new Date(item.Timestamp_Start).getTime(), // Convert timestamp to milliseconds
      y: item.Severity_Score,
      caseId: item.Case_Id,
    }));
  
    // Calculate the interval dynamically based on data length
    const interval = Math.ceil(seriesData.length / 5);
  
    // Highcharts configuration options
    const options = {
      chart: {
        type: 'line',
        style: {
          fontFamily: 'Arial', // Set font family
        },
        backgroundColor: 'transparent', // Set background color to transparent
        height: 250, // Set height of the chart
        width: 600, // Set width of the chart
      },
      title: {
        text: 'Generated Case Severity History',
        style: {
          color: '#ffffff', // Set font color to white
          fontSize:'12px'
        },
      },
      xAxis: {
        type: 'datetime', // Timestamp as x-axis
        title: {
          text: 'Time',
          style: {
            color: '#ffffff', // Set font color to white
          },
        },
        labels: {
          style: {
            color: '#ffffff', // Set font color to white
          },
        },
      },
      yAxis: {
        title: {
          text: 'Severity Score',
          style: {
            color: '#ffffff', // Set font color to white
          },
        },
        labels: {
          style: {
            color: '#ffffff', // Set font color to white
          },
        },
      },
      series: [
        {
          name: 'Severity History',
          data: seriesData,
          connectNulls: true, // Connect null values with a line
        },
      ],
      tooltip: {
        formatter: function() {
          return `Severity: ${this.y}<br>Time: ${Highcharts.dateFormat('%H:%M:%S', this.x)}<br>Case ID: ${this.point.caseId}`;
        },
      },
    };
  
    return <HighchartsReact highcharts={Highcharts} options={options} />;
  };
  
  export default SeverityHistoryChart;
  