import React from 'react';
import Iframe from 'react-iframe'; // if you are using react-iframe
import './App.css';

const App = () => {
  return (
    <div className='sale'>
    <div style={{color:'white',marginLeft:'20px',marginTop:'20px',fontSize:'15px'}}>Contract Risk Assessment:</div>
      <div className='container-iframe1'>
        <iframe  width="800" height="450" src="https://mediaexchange.accenture.com/media/1_lqnuu5xv" allowFullScreen></iframe>
      </div>
    </div>
  );
}

export default App;