import React, { useState, useEffect, useNavigate } from 'react';
import Iicon from './images/Group 3440.svg'
import Info from './Info.js';

// import ReactPlayer from 'react-player';

function Smart({ inputVideoUrl }) {
  const [showModal, setShowModal] = useState(false);
  const handleToggleModal = () => {
    setShowModal(!showModal);
  };

  const infocontent = {
    "description": [
      " LLM backed contract generation and contract compliance tracking can greatly reduce the redundant work whilst increasing risk mitigation capability and overall efficiency in the complexity of a chemical industry "
    ],
    "BusinessValueProposition": [
      "Efficiency gain: Less authoring time for SMEs leading to many hours of effort saving per year",
      "Improve Delivery Time: Drafting a clear, concise, and comprehensive contract for multiple downstream/upstream vendors",
      "Customer Experience: Reduced buroecracy and faster completion of deals, revealed time for new business."
    ],
    "problemStatement": [
      "The current manual process of reviewing Request for Proposals (RFPs) and drafting corresponding contracts involves significant time and effort. There is a need to automate and streamline the contract generation process to improve efficiency, reduce manual workload, and enhance accuracy in aligning contracts with RFP requirements."
    ],
    "Howdoesitwork": [
      "Creating customized template contract for relevant to specific end-user chemical markets or product group",
      "Raising red flags when non-compliance in legal documents is identified and proposing solutions.",
      "Identifying changes, highlighting potential issues or discrepancies, and generate a summary of the key terms of the contract.",
      "Regulatory requirements relevant for the chemical industry",
      "GPT embedding and multi layered clustering to group similar Contract sections."


    ],
    "Technologychoices": [
      "deployment",
      "LLM: OpenAI models"
    ]
  }
  return (
    <div className="main-container">
      <div className='In-main-container'>
        <p style={{ marginTop: "30px", fontSize: "28px", marginLeft: "20px", fontWeight: "550", color: "#AD8BFF", fontFamily: "Graphik", width: "1000px", fontSize: "15px" }}>ESG compliance and reporting | Regulatory Authoring Platform</p>

        <div style={{ marginTop: "30px", marginLeft: "100px" }}><img className="i-icon" src={Iicon} onClick={handleToggleModal} /></div>

        {showModal && (
          <Info
            onClose={() => setShowModal(false)} infocontent={infocontent}
          />
        )}
        <div>

        </div>

      </div>
      <div className='container-iframe'>
      <iframe id="kmsembed-1_cg2zjdai" width="700" height="400" src="https://mediaexchange.accenture.com/embed/secure/iframe/entryId/1_cg2zjdai/uiConfId/27188232/st/0" class="kmsembed" allowfullscreen webkitallowfullscreen mozAllowFullScreen allow="autoplay *; fullscreen *; encrypted-media *" referrerPolicy="no-referrer-when-downgrade" frameborder="0" title="Smart Contract Management"></iframe>
    </div>
    </div>
  )
}

export default Smart