import React, {useState, useEffect} from 'react';
import './CallHistory.css';
import recIcon from "../images/recIcon.svg";
import calIcon from "../images/callIcon.svg";
import directory from "../images/directory.svg";
import emailIcon from "../images/emailIcon.svg";
import teamsIcon from "../images/teams-meeting-show.svg";

const CallHistory = () => {
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer(prevTimer => prevTimer + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs < 10 ? '0' : ''}${secs}`;
  };

  return (
    <div className="caller-row-ongoing-call-info">
      <div className="caller-row">
        <span className="caller-row-caller-name">John Doe</span>
        <span className="caller-row-call-details">
          {/* <span className="caller-row-rec">Rec</span> */}
          <img src={recIcon}></img>
          <span className="caller-row-timer">Rec  {formatTime(timer)}</span>
        </span>
      </div>
      <div className="call-img-sec">
      <div className="parcel-item">
        <img src={calIcon} alt="Phone Icon" className="caller-row-icon" />
        <span className="caller-row-info">+1 234 567 890</span>
      </div>
      <div className="parcel-item">
        <img src={directory} alt="Phone Icon" className="caller-row-icon" />
        <span className="caller-row-info">--</span>
      </div>
      <div className="parcel-item">
        <img src={emailIcon} alt="Email Icon" className="caller-row-icon" />
        <span className="caller-row-info">john.doe@example.com</span>
      </div>
      <img src={teamsIcon} className='teams-icon-meeting'></img>
      </div>
      
    </div>
  );
};

export default CallHistory;
