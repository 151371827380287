import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import chatbot from '../images/Group 3439.svg';
import Iicon from '../images/Group 3440.svg'
import question from '../images/Group 3593.svg';
import refresh from '../images/Group 3139.svg';
import './Refinery.css';
// import Container from 'react-bootstrap/Container';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
import Chatbot from './Chatbot';
import equipement from '../images/refinery_equipemt.png';
// import Nav from 'react-bootstrap/Nav';
import toggle from '../images/Industry.svg';
import { Container, Row, Col, Navbar, Nav, NavDropdown, Offcanvas, Form, Button, Stack, Dropdown, InputGroup } from 'react-bootstrap';
import left from '../images/keyboard_double_arrow_left_ria.svg';
import right from '../images/keyboard_double_arrow_right_ria.svg';
import upload from '../images/Group 3296.svg';
import close1 from '../images/Group 3206.svg';
import camera_icon from '../images/camera_icon.svg';


function Refinery(props) {
    const navigate = useNavigate();
    const handlePrevNav = (event) => {
        navigate('/');
    };
    const renderchatResponse = (message) => {
        try {
            scrollToBottom();
            // console.log(message);
            if (message.user) {
                return (
                    <div className="user-message-v">
                        <span>{message.message}</span>
                    </div>
                );
            }
            else if (message.message == "Hi! How can I assist you today?") {
                return (
                    <div className="user-message-v">
                        <span>{message.message}</span>
                    </div>
                );
            }
            else {
                if (message && message.message) {
                    const response_data = message.message;
                    console.log(response_data);
                    console.log("answer");
                    return (
                        <div className="bot-text-response-d">
                            <span>{response_data}</span>
                        </div>
                    );
                } else {
                    return null;
                }
            }
        } catch (error) {
            console.error(error);
        }

    };

    const scrollContainerRef = useRef(null);

    const scrollToBottom = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight;
        }
    };
    const question_list = [];

    const [question_report, setQuestion_report] = useState([]);

    const getParent = () => {
        const inputArray = ["Contractor Information#****#Inspector Certification", "Additional Equipment / Design Data#****#Inspector Certification", "Additional Equipment / Design Data#****#Techinician Name",
            "Loss of Containment#****#Inspector Certification", "Equipment Insulation#****#Inspector Certification", "Equipment General#****#Inspector Certification", "Roof#****#Inspector Certification"];
        const predicted_input = [
            {
                parent: "Contractor Information#****#Inspector Certification",
                condition: "Action Recommended",
                notes: "take neccesary action "
            }
        ]


        const outputData = inputArray.reduce((result, str) => {
            const [parent, child] = str.split("#****#");
            const existingParent = result.find(item => item.name === parent);
            if (!existingParent) {
                result.push({ name: parent, child: [child] });
            } else {
                existingParent.child.push(child);
            }

            return result;
        }, []);
        setQuestion_report(outputData);
        console.log(outputData);
    }
    const [selectedTile, setSelectedTile] = useState(props.question_report[0].name);
    const handleTileClick = (tileId) => {
        setSelectedTile(tileId);
    };
    const CustomSidebar = ({ isOpen, onClose }) => {
        return (
            <div className={`custom-sidebar ${isOpen ? 'open' : 'close'}`}>
                {/* <img className="close-button" src={close1} onClick={onClose} /> */}
                <div style={{ display: "flex" }}><img src={toggle} className='sidebar-toggle-ria' onClick={onClose} />
                    <p className='report-heading-ria'> Report Content</p>
                </div>
                <Stack style={{ overflow: "auto", height: "47vh" }}>
                    {props.question_report.map((item, index) => (
                        <div className="p-2 parent-report-ria" key={index} style={{ background: selectedTile === item.name ? 'linear-gradient(131.99deg, #8E2DE2 6.23%, #6A19D8 85.46%)' : "transparent" }}
                            onClick={() => { setParent(item.name); setnav(index); handleTileClick(item.name) }}>
                            {item.name}
                        </div>
                    ))}
                </Stack>
            </div>
        );
    };
    const [isSidebarOpen, setSidebarOpen] = useState(false);
    const [parent, setParent] = useState(props.question_report[0].name);

    const handleToggleSidebar = () => {
        setSidebarOpen(!isSidebarOpen);
        // getParent()
    }
    const getFormattedTimestamp = () => {
        const currentDate = new Date();
        const formattedDate = currentDate.toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });

        const formattedTime = currentDate.toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: true,
        });

        const formattedTimestamp = `${formattedDate} ${formattedTime}`;
        return formattedTimestamp;
    };

    const [finalOutput, setFinalOutput] = useState([]);
    const [init, setInit] = useState(true);
    useEffect(() => {
        console.log("*************useeffect")
        if (init) {
            const info = props.additionaInfo
            info.map((item, index) => {
                const pathToUpdate = item.question;
                const isPathExists = finalOutput.some(item => item.path === pathToUpdate);
                if (!isPathExists) {
                    console.log("Addinng finaloutput")
                    const [parent, child] = pathToUpdate.split("#****#");
                    finalOutput.push({ parent: parent, child: child, condition: item.Condition, notes: item.Notes, image: [item.image], timeStamp: item.Timestamp_End, path: pathToUpdate, url: item.url });
                }
            })
            setInit(false)
        }
        console.log(finalOutput);

    }, [props.additionaInfo, finalOutput]);
    const handledataChange = (event) => {
        console.log("Handle Condition change")
        console.log(event);
        const { id, value } = event.target;
        console.log(id);
        console.log(value);
        const data = JSON.parse(id)
        const pathToUpdate = data.path;
        const timestamp = getFormattedTimestamp();
        if (Array.isArray(finalOutput) && finalOutput.length !== 0) {
            console.log("existing array")
            const updatedInput = finalOutput.map(item => {
                if (item.path === pathToUpdate) {
                    console.log("updating");
                    return { ...item, condition: value, timeStamp: timestamp };
                }
                return item;
            });
            const isPathExists = updatedInput.some(item => item.path === pathToUpdate);

            if (!isPathExists) {
                console.log("nopathexists")
                updatedInput.push({ parent: data.parent, child: data.child, condition: value, notes: "", image: [], timeStamp: timestamp, path: pathToUpdate, });
            }

            console.log(updatedInput);
            setFinalOutput(updatedInput);
        }
        else {
            console.log("new Element")
            const updatedInput = [];
            updatedInput.push({ parent: data.parent, child: data.child, condition: value, notes: "", image: [], timeStamp: timestamp, path: pathToUpdate });

            console.log(updatedInput);
            setFinalOutput(updatedInput);
        }
    }
    const handleNotesChange = (event) => {
        console.log("Handle Notes change")
        console.log(event);
        const { id, value } = event.target;
        console.log(id);
        console.log(value);
        const data = JSON.parse(id)
        const pathToUpdate = data.path;
        const timestamp = getFormattedTimestamp();
        if (Array.isArray(finalOutput) && finalOutput.length !== 0) {
            console.log("existing array")
            const updatedInput = finalOutput.map(item => {
                if (item.path === pathToUpdate) {
                    console.log("updating");
                    return { ...item, notes: value, timeStamp: timestamp };
                }
                return item;
            });
            const isPathExists = updatedInput.some(item => item.path === pathToUpdate);

            if (!isPathExists) {
                console.log("nopathexists")
                updatedInput.push({ parent: data.parent, child: data.child, condition: "", notes: value, image: [], timeStamp: timestamp, path: pathToUpdate });
            }

            console.log(updatedInput);
            setFinalOutput(updatedInput);
        }
        else {
            console.log("new Element")
            const updatedInput = [];
            updatedInput.push({ parent: data.parent, child: data.child, condition: "", notes: value, image: [], timeStamp: timestamp, path: pathToUpdate });

            console.log(updatedInput);
            setFinalOutput(updatedInput);
        }
    }
    const [nav, setnav] = useState(0);
    const conditions = ["Acceptable", "Action Recommended", "N/A", "Not Inspected"];
    const [loadingIndex, setLoadingIndex] = useState(null);
    const [fileuploaded, setfileuploaded] = useState(false);
    const [fileuploading, setfileuploading] = useState(false);
    const handleFileChange = async (event, question, index) => {
        console.log("Calling handleadditionalpredict");
        setLoadingIndex(index);
        const responses = [];
        try {
            setfileuploading(true);
            const file = event.target.files[0];
            const fileExtension = file.name.split('.').pop().toLowerCase();

            if (fileExtension === 'png' || fileExtension === 'jpeg' || fileExtension === 'jpg') {
                const formData = new FormData();
                formData.append('image_file', file);

                formData.append('equip_name', props.finalMetadata.equipmenttype);
                formData.append('question', question);
                console.log(formData);

                const response = await fetch('https://industrygenai.accenture.com/inspection/additionalimagepredict', {
                    method: 'POST',
                    body: formData,
                });
                // const response = { ok: true }
                if (response.ok) {
                    const blob = await response.blob();
                    async function blobToBase64(blob) {
                        return new Promise((resolve, reject) => {
                            const reader = new FileReader();
                            reader.onloadend = () => resolve(reader.result);
                            reader.onerror = reject;
                            reader.readAsDataURL(blob);
                        });
                    }
                    const base64data = await blobToBase64(blob);
                    const url = URL.createObjectURL(file)
                    const metadata = response.headers.get('Metadata');
                    // const metadata = {
                    //     "Condition": "Action Recommended",
                    //     "Notes": "The  shows a significant biological growth along the structure which could lead to corrosion and weakening of the tank's integrity over time. Immediate cleaning and treatment are necessary to prevent further damage.",
                    //     "Tokens_Used": "25", "Timestamp_Start": "2024-02-0411:41:06", "Timestamp_End": "2024-02-0411:41:14",
                    //     "LLM_Model_Name": "GPT4Vision", "question": question
                    // }
                    if (metadata) {
                        const parseddata = JSON.parse(metadata)
                        //ToDO: add parsing and parsed data everywhere
                        console.log(parseddata);
                        responses.push(parseddata);
                        const pathToUpdate = question;
                        const [parent, child] = pathToUpdate.split("#****#");
                        if (Array.isArray(finalOutput) && finalOutput.length !== 0) {
                            console.log("existing array")
                            const updatedInput = finalOutput.map(item => {
                                if (item.path === pathToUpdate) {
                                    console.log("updating");
                                    return { ...item, condition: parseddata.Condition, notes: parseddata.Notes, image: [base64data], timeStamp: parseddata.Timestamp_End, url: url };
                                }
                                return item;
                            });
                            const isPathExists = updatedInput.some(item => item.path === pathToUpdate);

                            if (!isPathExists) {
                                console.log("nopathexists")
                                updatedInput.push({ parent: parent, child: child, condition: parseddata.Condition, notes: parseddata.Notes, image: [base64data], timeStamp: parseddata.Timestamp_End, path: pathToUpdate, url: url });
                            }

                            console.log(updatedInput);
                            setFinalOutput(updatedInput);
                        }
                        else {
                            console.log("new Element")
                            const updatedInput = [];
                            updatedInput.push({ parent: parent, child: child, condition: parseddata.Condition, notes: parseddata.Notes, image: [base64data], timeStamp: parseddata.Timestamp_End, path: pathToUpdate, url: url });

                            console.log(updatedInput);
                            setFinalOutput(updatedInput);
                        }
                        console.log(finalOutput);
                        setfileuploading(false);
                        console.log('File uploaded successfully!')
                        setfileuploaded(true);
                        setTimeout(() => {
                            setfileuploaded(false);
                        }, 2000);
                    } else {
                        throw new Error('Additional Image Prediction processing failed- empty input');
                    }
                } else {
                    throw new Error('Image processing failed');
                }
            } else {
                alert('Please upload a PNG, JPEG, or JPG file.');
            }
        } catch (error) {
            console.error(error);
        }

        return responses;
    };
    const [showsubmitModal, setshowsubmitModal] = useState(false);
    const handleSubmit = async () => {
        console.log(finalOutput);
        setshowsubmitModal(true);
        callActionRecommendedAPI();
    }
    const [recommendationResponse, setRecommendationResponse] = useState([]);
    const [actionrecommendLoading, setActionrecommendLoading] = useState(false);
    const callActionRecommendedAPI = async () => {
        setActionrecommendLoading(true)
        try {
            const request = {
                questions: finalOutput
            }
            const response = await fetch('https://industrygenai.accenture.com/inspection/actionrecommend', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(request),
            });
            // const response = [
            //     {
            //         "Repair(s)/Clamp(s)/Sleeve(s) Present": "Output: Implement immediate and long-term repair solutions."
            //     }
            // ]
            if (response.ok) {
                const recommendationResp = await response.json();
                setRecommendationResponse(recommendationResp);
                setActionrecommendLoading(false)

            } else {
                console.error('Download failed');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }
    const framefinalResponse = (finalOutput, question_report) => {
        const frameFullResponse = [];
        try {
            const timestamp = getFormattedTimestamp();
            question_report.map((item, index) => {
                console.log(item);
                const parent = item.name;
                item.child.map(
                    (item, index) => {
                        console.log(item);
                        const child = item;
                        const matchingInfo = finalOutput.find(item => item.child === child && item.parent == parent);
                        const value_data = matchingInfo && { Condition: matchingInfo.condition, Notes: matchingInfo.notes, image: matchingInfo.image }
                        const tempresponse = {
                            "parent": parent,
                            "child": child,
                            "condition": value_data && value_data.Condition || "Acceptable",
                            "notes": value_data && value_data.Notes || "OK",
                            "image": value_data && value_data.image || [],
                            "timeStamp": timestamp
                        }
                        frameFullResponse.push(tempresponse);
                    }
                )
            }
            )
            console.log(frameFullResponse);
        }
        catch {
            console.lof("error at framefinalResponse");
        }

        return frameFullResponse;
    }
    const [pdfLoading, setPdfLoading] = useState(false);
    const callPDFapi = async () => {
        // const res = framefinalResponse(finalOutput, props.question_report);
        // console.log(res);
        console.log(finalOutput);
        setPdfLoading(true);
        const modifiedArray = finalOutput.map(obj => {
            const newObj = { ...obj };
            delete newObj.path;
            return newObj;
        });
        console.log(modifiedArray);
        const today = new Date();
        const options = { month: 'short', day: 'numeric', year: 'numeric' };
        const formattedDate = today.toLocaleDateString('en-US', options);
        const pdfRequest1 = {
            "metadata": {
                "reportType": props.finalMetadata.reportType,
                "equipmentID": props.finalMetadata.equipmentID,
                "site": props.finalMetadata.site,
                "equipmentType": props.finalMetadata.equipmenttype,
                "submittedby": "Admin",
                "date": formattedDate,
                "email": "admin@accenture.com",
                "name": "Admin",
                "position": "Inspection Officer",
                "input_image": props.selectbase64Image,
                "questionnaire": modifiedArray || []
            },
            "actiondata": recommendationResponse
        }
        try {
            const response = await fetch('https://industrygenai.accenture.com/inspection/genpdf', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(pdfRequest1),
            });
            if (response.ok) {
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'output.pdf';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
                setPdfLoading(false);
            } else {
                console.error('Download failed');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }
    const [savedDB, setsavedDB] = useState(false);
    const callSaveDB = async () => {
        // const res = framefinalResponse(finalOutput, props.question_report);
        // console.log(res);
        //ToDo: Remove the image From the request
        console.log(finalOutput);
        setPdfLoading(true);

        const modifiedArray = finalOutput.map(obj => {
            const newObj = { ...obj };
            delete newObj.path;
            return newObj;
        });
        console.log(modifiedArray);
        const today = new Date();
        const options = { month: 'short', day: 'numeric', year: 'numeric' };
        const formattedDate = today.toLocaleDateString('en-US', options);
        const pdfRequest1 = {
            "metadata": {
                "reportType": props.finalMetadata.reportType,
                "equipmentID": props.finalMetadata.equipmentID,
                "site": props.finalMetadata.site,
                "equipmentType": props.finalMetadata.equipmenttype,
                "submittedby": "Admin",
                "date": formattedDate,
                "email": "admin@accenture.com",
                "name": "Admin",
                "position": "Inspection Officer",
                "input_image": props.selectbase64Image,
                "questionnaire": modifiedArray || []
            },
            "actiondata": recommendationResponse
        }
        try {
            const response = await fetch('https://industrygenai.accenture.com/inspection/saverecords', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(pdfRequest1),
            });
            if (response.ok) {
                const dbResponse = await response.json();
                if (dbResponse.Status == "200") {
                    console.log("Saved to DB");
                    setPdfLoading(false);
                    setsavedDB(true)
                    setTimeout(() => {
                        setsavedDB(false);
                    }, 2000);
                }
                else {
                    console.log("Error while saving to DB");
                    setPdfLoading(false);
                }
            } else {
                console.error('Download failed');
            }
        } catch (error) {
            console.error('Error:', error);
        }

    }

    const Loading = () => {
        return (
            <div className="loader-sv">
                <div className="dot-cq-sv red-c-sv"></div>
                <div className="dot-cq-sv green-c-sv"></div>
                <div className="dot-cq-sv blue-c-sv"></div>
            </div>
        );
        ;
    };
    const LoadingModal = () => {
        return (
            <div className="loader-ref-modal">
                <div className="dot-cq-sv red-c-sv"></div>
                <div className="dot-cq-sv green-c-sv"></div>
                <div className="dot-cq-sv blue-c-sv"></div>
            </div>
        );
        ;
    };
    const [showFullImage, setShowFullImage] = useState(false);
    const [fullImageURL, setFullImageURL] = useState(null);
    const viewImage = (url) => {
        setShowFullImage(true);
        setFullImageURL(url);
    }
    const [showPreview, setShowPreview] = useState(false);
    const handlePreview = () => {
        setShowPreview(true);
    }

    const [selectedItems, setSelectedItems] = useState([]);

    const handleCheckboxChange = (event) => {
        const { value } = event.target;

        if (selectedItems.includes(value)) {
            setSelectedItems(selectedItems.filter(item => item !== value));
        } else {
            setSelectedItems([...selectedItems, value]);
        }
    };

    return (
        <> {props.showQuestion &&
            <div className='main-ria'>
                {/* <div className='Heading-nav-ria' style={{ position: "absolute", left: "7%", top: "10%" }}>
                    <span onClick={handlePrevNav} style={{ color: '#AD8BFF' }}>Oil & Gas</span> /  Refinery Inspection Assistance </div> */}
                {/* <div className='info-icon-doa' style={{ position: "absolute", left: "90%", top: "9%" }}>
                <img className='doa-icon' src={question} />
                <img className="doa-icon" src={Iicon} />
                <img className="doa-icon" src={refresh} />
            </div> */}
                <Container className='bot-ria'>
                    <Row className="justify-content-md-center">
                        <Col className='resizable left-sub-container-ref question-section-ria' sm={8}>
                            <Row className='row1-ria'>
                                <Col sm={4}>
                                    <img className="equipement-display" src={props.selectedImage} />
                                    {/* {
                                onimgview && <div className="modal-img-corrosion">
                                    <div className='modal-corrosion-img'>
                                        <div>
                                            <img src={close1} className="close-img-corrosion" variant="primary" onClick={() => setonimgview(false)} />
                                            <img className='chatbot-img-full' src={selectedImage} alt='Sample' /> </div></div></div>
                            } */}
                                </Col>
                                <Col sm={7}>
                                    <table className='table-ria' style={{ marginLeft: "10px" }}>
                                        <tbody>
                                            <tr>
                                                <td><span style={{ fontWeight: "450" }}>{`Equipment Type \u00A0 \u00A0 :\u00A0 \u00A0`}</span>{props.finalMetadata.equipmenttype}</td>
                                            </tr>
                                            <tr >
                                                <td><span style={{ fontWeight: "450" }}>{`Site\u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0:\u00A0 \u00A0`}</span> {props.finalMetadata.site}</td>
                                            </tr>
                                            <tr >
                                                <td><span style={{ fontWeight: "450" }}>{`Equipment ID\u00A0 \u00A0 \u00A0 \u00A0 \u00A0 :\u00A0 \u00A0`}</span>{props.finalMetadata.equipmentID}</td>
                                            </tr>
                                            <tr >
                                                <td><span style={{ fontWeight: "450" }}>{`Report Type\u00A0 \u00A0  \u00A0 \u00A0 \u00A0 \u00A0 \u00A0:\u00A0 \u00A0`}</span>{props.finalMetadata.reportType}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>
                            <Row style={{ }} className='row1-ria1'>
                                <div style={{ position:'relative', borderRadius: "10px", marginTop: "1%"  }}>
                                    <div className='custom-sidebar-close'>
                                        <img src={toggle} className='sidebar-toggle-ria1' onClick={handleToggleSidebar} />
                                    </div>
                                    <div id="mySpecificDiv">
                                        {isSidebarOpen && <CustomSidebar isOpen={isSidebarOpen} onClose={handleToggleSidebar} />}
                                    </div>
                                    <div className='child-report-ria'>

                                        <div className={`childreport-div-ria ${isSidebarOpen ? 'open style-2' : 'style-2'}`}>
                                            <Stack>
                                                {props.question_report.map((item, index) => {
                                                    if (item.name === parent) {
                                                        const path = item.path;
                                                        const parent = item.name;
                                                        const url = item.url;
                                                        // const child = item.child;
                                                        // console.log(path);
                                                        return (
                                                            item.child.map((item, index) => {
                                                                const matchingInfo = finalOutput.find(item => item.path === path[index]);
                                                                const value_data = matchingInfo
                                                                    ? { Condition: matchingInfo.condition, Notes: matchingInfo.notes, url: matchingInfo.url }
                                                                    : { Condition: '', Notes: '', url: null };
                                                                const currentPath = path[index];
                                                                return (
                                                                    <div className="p-2 final-stack-ria" key={index}>
                                                                        <p>{item}</p>
                                                                        {showFullImage && <div className="modal-img-corrosion">
                                                                            <div className='modal-ria-img'>
                                                                                <div>
                                                                                    <img src={close1} className="close-img-corrosion" variant="primary" onClick={() => setShowFullImage(false)} />
                                                                                    <img className='chatbot-img-full-ria' src={fullImageURL} alt='Sample' />
                                                                                </div>
                                                                            </div>
                                                                        </div>}
                                                                        {value_data.url ? <div className='upload-ria' onClick={() => { viewImage(value_data.url); }}><label className="ria-file-upload">
                                                                            <span className="ria-file-upload-span">
                                                                                <img src={camera_icon} className='uploadicon' />
                                                                            </span>
                                                                        </label> </div> :
                                                                            <div className='upload-ria'><label className="ria-file-upload">
                                                                                <input type="file" multiple onChange={(event) => handleFileChange(event, path[index], index)} accept=".png, .jpeg, .jpg" />
                                                                                {/* <span> <img src={upload} className='uploadicon' /> </span> */}
                                                                                {fileuploaded ? (loadingIndex == index && <div class="tick-circle"></div>) : (fileuploading && loadingIndex == index ? <span className='ria-file-upload-load'><div class="spinner-border spinner-border-sm " role="status">
                                                                                </div></span> : <span className="ria-file-upload-span"><img src={upload} className='uploadicon' /> </span>)}
                                                                            </label></div>}

                                                                        <select id={JSON.stringify({ path: path[index], parent: parent, child: item })} value={value_data.Condition ? value_data.Condition : null} className='dropdown-dd' onChange={handledataChange}>
                                                                            <option value="" disabled selected style={{ display: "none" }}>Conditions</option>
                                                                            {conditions.map((item, index) => (<>
                                                                                <option key={index}>{item}
                                                                                </option></>
                                                                            ))}
                                                                        </select>
                                                                        {<InputGroup className='InputGroup-ria' onChange={handleNotesChange}>
                                                                            <Form.Control as="textarea" aria-label="With textarea" placeholder="Notes..." id={JSON.stringify({ path: path[index], parent: parent, child: item })} value={value_data.Notes ? value_data.Notes : null} />
                                                                        </InputGroup>}
                                                                    </div>
                                                                )
                                                            })
                                                        )
                                                    }
                                                })}
                                                <div className='p-2 position-nav-ria'>
                                                    {/* {nav != 0 && <button className='nav-button-ria' onClick={() => {
                                                        setParent(props.question_report[nav - 1].name);
                                                        setnav(nav - 1)
                                                    }}><img src={left} /> Previous </button>}
                                                    {nav < props.question_report.length - 1 && <button className='nav-button-ria' onClick={() => {
                                                        setParent(props.question_report[nav + 1].name);
                                                        setnav(nav + 1)
                                                    }}> Next <img src={right} /></button>} */}
                                                    <div className='nav-button-container1-ria'>
                                                        {nav != 0 && <button className='nav-button-ria' onClick={() => {
                                                            setParent(props.question_report[nav - 1].name);
                                                            setnav(nav - 1);
                                                            handleTileClick(props.question_report[nav - 1].name);
                                                        }}><img className='arrow-pic-ria' src={left} /> Previous </button>}
                                                        {nav < props.question_report.length - 1 && <button className='nav-button-ria' onClick={() => {
                                                            setParent(props.question_report[nav + 1].name);
                                                            setnav(nav + 1);
                                                            handleTileClick(props.question_report[nav + 1].name);
                                                        }}> Next <img className='arrow-pic-ria' src={right} /></button>}
                                                        {nav === props.question_report.length - 1 && <button className='nav-button-ria' onClick={handleSubmit}> Submit</button>}
                                                        {/* {nav === props.question_report.length - 1 && <button className='nav-button-ria' onClick={handlePreview}> Preview</button>} */}
                                                    </div>


                                                    {showsubmitModal && (
                                                        <div className="modal-overlay-ria">
                                                            <div className='modal-ria'>
                                                                <div className='analysis-backdrop'>
                                                                    <p className='analysis-head2'> Visual Inspection Report
                                                                        <img src={close1} className="close-icon-ria" variant="primary" onClick={() => setshowsubmitModal(false)} /></p>
                                                                </div>
                                                                <div className='modal-content-ria'>
                                                                    <div className='shade-ria'>
                                                                        {actionrecommendLoading || pdfLoading ? <LoadingModal /> :
                                                                            <><button className='final-button-ria' onClick={callSaveDB}> Save to Database </button>
                                                                                <button className='final-button-ria' onClick={callPDFapi}> Download PDF</button></>}
                                                                    </div>
                                                                </div>
                                                                {savedDB && <p style={{ marginLeft: "80px" }}>Saved to database Successfully...</p>}
                                                            </div>
                                                        </div>
                                                    )}

                                                    {/* {showPreview && (
                                                        <div className="modal-overlay-ria">
                                                            <div className='modal-ria'>
                                                                <div className='analysis-backdrop'>
                                                                    <p className='analysis-head2'> External Inspection Report
                                                                        <img src={close1} className="close-icon-ria" variant="primary" onClick={() => setshowsubmitModal(false)} /></p>
                                                                </div>
                                                                <div className='modal-content-ria'>
                                                                    <div style={{ marginLeft: "10px", marginBottom: "10px" }}>
                                                                        <Dropdown className="DropDown-ria" >
                                                                            <Dropdown.Toggle id="dropdown-basic">
                                                                               Select questions
                                                                            </Dropdown.Toggle>
                                                                            <Dropdown.Menu>
                                                                                <div key={`default-checkbox`} className="mb-3" style={{ marginLeft: "5px" }}>
                                                                                    <Form.Check
                                                                                        type="checkbox"
                                                                                        id={`default-checkbox`}
                                                                                        label={`default checkbox`}
                                                                                        value="1"
                                                                                        checked={selectedItems.includes("1")}
                                                                                        onChange={handleCheckboxChange}
                                                                                    />
                                                                                    
                                                                                </div>
                                                                            </Dropdown.Menu>
                                                                        </Dropdown><Dropdown className="DropDown-ria" >
                                                                            <Dropdown.Toggle id="dropdown-basic">
                                                                            Conditions
                                                                            </Dropdown.Toggle>
                                                                            <Dropdown.Menu>
                                                                                        {conditions.map((item, index) => (<>
                                                                                            <option key={index}>{item}
                                                                                            </option></>
                                                                                        ))}
                                                                                    </Dropdown.Menu>
                                                                        </Dropdown>
                                                                    </div>
                                                                    <div className='shade-ria'>
                                                                        {actionrecommendLoading || pdfLoading ? <LoadingModal /> :
                                                                            <><button className='final-button-ria' onClick={callSaveDB}> Save to Database </button>
                                                                                <button className='final-button-ria' onClick={callPDFapi}> Download PDF</button></>}
                                                                    </div>
                                                                </div>
                                                                {savedDB && <p style={{ marginLeft: "80px" }}>Saved to database Successfully...</p>}
                                                            </div>
                                                        </div>
                                                    )} */}
                                                </div>
                                            </Stack>
                                        </div>

                                    </div>
                                </div>
                            </Row>
                        </Col>

                        <Col sm={4} >
                        <Row style={{position:"relative", marginLeft:"0px"}}>
                            <Chatbot renderchatResponse={renderchatResponse} endpoint="https://industrygenai.accenture.com/inspection/chatbot" questions={question_list} />
                            </Row>
                        </Col>
                    </Row>
                </Container>

            </div>}</>

    )
}

export default Refinery