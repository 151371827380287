import React from 'react';
import { ProgressBar } from 'react-bootstrap'; // Assuming you're using Bootstrap for progress bars
import { FaUser } from 'react-icons/fa'; // User icon

const EngineerTable = ({ data }) => {
    
  return (
    <table style={{ width: '95%',marginLeft:'20px',marginRight:'10px',paddingLeft:'50px',marginTop:'30px', borderCollapse: 'collapse' }} >
      <thead style={{color:'white',fontSize:'13px',borderBottom:'1px solid white'}}>
        <tr style={{paddingBottom:'30px'}}>
          <th style={{paddingBottom:'5px'}}>Engineer</th>
          <th style={{paddingBottom:'5px'}}>Total Cases</th>
          <th style={{paddingBottom:'5px'}}>Resolved Cases</th>
          <th style={{paddingBottom:'5px'}}>Completion (%)</th>
        </tr>
      </thead>
      <tbody style={{padding:'20px',color:'white'}}>
        {data.map((engineer, index) => {
          const engineerName = engineer["Safety Engineer"] || "Pool"; // If engineer value is blank, use "Pool"
          return (
            <tr key={index}>
              <td style={{padding:'5px',marginBottom:'-10px',borderTop:'1px solid white'}}>
                <FaUser /> {engineerName}
              </td>
              <td style={{borderTop:'1px solid white'}}>{engineer["Total Case"]}</td>
              <td style={{borderTop:'1px solid white'}}>{engineer["Resolved"]}</td>
              <td style={{borderTop:'1px solid white'}}>
                <ProgressBar
                  now={parseFloat(engineer["Ratio"])}
                  label={`${parseFloat(engineer["Ratio"]).toFixed(2)}%`}
                  variant="success"
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default EngineerTable;
