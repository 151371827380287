import React, { useState, useRef, useEffect } from 'react';
import axios from "axios";
import user from "../images/userMes.svg";
import bot from "../images/botMes.svg";
import './ChatBotDiscussion.css';
import AudioRecorder from './AudioChatbot';
import CustomAudioPlayer from './CustomAudioPlayer';

const ChatbotDiscussion = ({ audioResponse }) => {
  const chatMessagesRef = useRef(null);
  const [chatMessages, setChatMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [urlVal, setUrlVal] = useState('');
  const [inputValue, setInputValue] = useState({ transcript: { input_request: "Hi", output_response: "Hello" } });
  const [selectedBusinessPartner, setSelectedBusinessPartner] = useState('');

  const businessPartnerIDs = [
    { id: '6056238', name: '6056238' },
    { id: '6063204', name: '6063204' }
  ];

  const clearChatMessages = () => {
    setChatMessages([]);
  };

  const handleBusinessPartnerChange = (event) => {
    setSelectedBusinessPartner(event.target.value);
  };

  const Loading = () => {
    return (
      <div className="loader">
        <div className="dot red"></div>
        <div className="dot green"></div>
        <div className="dot blue"></div>
      </div>
    );
  };

  const handleApiResponse = (responseText) => {
    setInputValue(responseText);
    audioResponse(responseText);
  };

  const handleUrlResponse = (urlText) => {
    setUrlVal(urlText);
  };

  useEffect(() => {
    if (inputValue.transcript.input_request === "Hi" && inputValue.transcript.output_response === "Hello") {
      return;
    }

    const fetchChatResponse = async () => {
      const userMessage = inputValue.transcript.input_request;

      // Update chat messages with the user's initial message
      setChatMessages((prevMessages) => [
        ...prevMessages,
        { user: true, message: userMessage, recommendation: false, init: true },
      ]);

      try {
        const botResponse = inputValue.transcript.output_response;

        const botMessage = {
          user: false,
          url: urlVal,
          message: botResponse,
          recommendation: false,
          init: true,
        };

        // Update chat messages with the bot's response
        setChatMessages((prevMessages) => [...prevMessages, botMessage]);
      } catch (error) {
        console.error('Error fetching chat response:', error);
      }
    };

    fetchChatResponse();
  }, [inputValue]);

  return (
    <div className="chat-messages discussion" ref={chatMessagesRef}>

<div className="partner-dropdown">
        {/* <label htmlFor="business-partner">Select Business Partner: </label> */}
        <select
          id="business-partner"
          className='partner-background'
          name="business-partner"
          value={selectedBusinessPartner}
          onChange={handleBusinessPartnerChange}
        >
          <option value="" disabled>Select Business Partner</option>
          {businessPartnerIDs.map((partner) => (
            <option key={partner.id} value={partner.id}>
              {partner.name}
            </option>
          ))}
        </select>
      </div>
      <br/>


      {chatMessages?.map((message, index) => (
        <div
          key={index}
          className={`message-row ${message.user ? "user-message" : "bot-message"}`}
        >
          <div className="message-content">
            <div className="message-info">
              <img
                src={message.user ? user : bot}
                alt="Avatar"
                className="avatar"
              />
              <div className="sender-name">
                <span>{message.user ? "You" : "Chatbot"}</span>
                <div className="message-text">
                  {message.message}
                </div>
                {message.url && (
                  <div className="audio-player">
                    <CustomAudioPlayer src={message.url} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
      {loading && <Loading />}
      <AudioRecorder onApiResponse={handleApiResponse}  onUrlResponse={handleUrlResponse} setLoading={setLoading} businessPartnerID={selectedBusinessPartner} clearChatMessages={clearChatMessages}      />
    </div>
  );
};

export default ChatbotDiscussion;
