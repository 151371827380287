import React from 'react'
import Sop from './Sustainability/Sustainability'

function SOP_Chemical() {
    const questions = [
      'Give information about the ESG highlights of shell',
      'Give information about the spills, clean-up, Prevention for shell and chevron',
      'what are the various waste management strategies undertaken by shell and chevron?',
      'give shells and chevron safety and core values',
      'what are personal safety measurements taken in shell and chevron?',
      'Give information about the spills, clean-up, Prevention for shell',
      'what are the various waste management strategies undertaken by shell?',
      'give detail about Green house gas(GHG) Emissions of Shell and Chevron',
      'What environmental certifications shell and chevron have obtained?',
      'give shells safety and core values',
      'give performance overview of shell in 2022',
      'Report Review Panel recommendations of shell',
      'what are personal safety measurements taken in shell?'
    ];
  return (
    <Sop  upload_endpoint= "https://industrygenai.accenture.com/srg/uploadFiles" getpdf_endpoint = "https://industrygenai.accenture.com/srg/get-pdf" chatbot_endpoint= "https://industrygenai.accenture.com/srg/chatBot" questions = {questions}/>
  )
}

export default SOP_Chemical