import React, { useEffect } from 'react';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';


const Map = () => {
  useEffect(() => {
    // Initialize the map
    const map = L.map('map', {
      center: [19.019642, 72.898353],
      crs: L.CRS.EPSG3857,
      zoom: 10,
      zoomControl: true,
      preferCanvas: false,
    });

    // const tileLayer = L.tileLayer(
    //   'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    //   {
    //     attribution:
    //       'Data by © <a target="_blank" href="http://openstreetmap.org">OpenStreetMap</a>, under <a target="_blank" href="http://www.openstreetmap.org/copyright">ODbL</a>.',
    //     detectRetina: false,
    //     maxNativeZoom: 18,
    //     maxZoom: 18,
    //     minZoom: 0,
    //     noWrap: false,
    //     opacity: 1,
    //     subdomains: 'abc',
    //     tms: false,
    //   }
    // ).addTo(map);

    const tileLayer = L.tileLayer(
      'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      {
        attribution:
          'Data by <a target="_blank" href="http://openstreetmap.org">OpenStreetMap</a>, under <a target="_blank" href="http://www.openstreetmap.org/copyright">ODbL</a>.',
        maxZoom: 18,
        minZoom: 0,
        //subdomains: ['a', 'b', 'c'], // Use an array of subdomains
      }
    ).addTo(map);
    

    const polylineCoordinates = [
      [
        [19.019642, 72.898353],
        [19.1706, 73.0819],
      ],
      [
        [19.019642, 72.898353],
        [19.1706, 73.0819],
      ],
      [
        [19.019642, 72.898353],
        [19.3795, 72.896],
      ],
      [
        [19.019642, 72.898353],
        [19.3795, 72.896],
      ],
      [
        [19.019642, 72.898353],
        [19.3795, 72.896],
      ],
      [
        [19.019642, 72.898353],
        [19.3464, 72.8081],
      ],
      [
        [19.019642, 72.898353],
        [19.3464, 72.8081],
      ],
      [
        [19.019642, 72.898353],
        [19.3464, 72.8081],
      ],
      [
        [19.019642, 72.898353],
        [19.4419, 72.7763],
      ],
      [
        [19.019642, 72.898353],
        [19.4419, 72.7763],
      ],
      [
        [19.019642, 72.898353],
        [19.4419, 72.7763],
      ],
      [
        [19.019642, 72.898353],
        [19.234, 72.8566],
      ],
      [
        [19.019642, 72.898353],
        [19.234, 72.8566],
      ],
      [
        [19.019642, 72.898353],
        [19.234, 72.8566],
      ],
      [
        [19.019642, 72.898354],
        [19.246, 72.845],
      ],
      [
        [19.019642, 72.898355],
        [19.246, 72.845],
      ],
      [
        [19.019642, 72.898356],
        [19.246, 72.845],
      ],
      [
        [19.019642, 72.898357],
        [19.2852, 72.8993],
      ],
      [
        [19.019642, 72.898357],
        [19.2852, 72.8993],
      ],
      [
        [19.019642, 72.898357],
        [19.2852, 72.8993],
      ],
      [
        [19.019642, 72.898357],
        [19.2801, 72.9261],
      ],
      [
        [19.019642, 72.898357],
        [19.2801, 72.9261],
      ],
      [
        [19.019642, 72.898357],
        [19.2801, 72.9261],
      ],
      [
        [19.019642, 72.898357],
        [19.2239, 72.9771],
      ],
      [
        [19.019642, 72.898357],
        [19.291, 72.7855],
      ],
      [
        [19.019642, 72.898357],
        [19.291, 72.7855],
      ],
      [
        [19.019642, 72.898357],
        [19.291, 72.7855],
      ],
      [
        [19.019642, 72.898357],
        [19.2961, 72.8172],
      ],
      [
        [19.019642, 72.898357],
        [19.2961, 72.8172],
      ],
      [
        [19.019642, 72.898357],
        [19.2961, 72.8172],
      ],
      [
        [19.019642, 72.898357],
        [19.3902, 72.7829],
      ],
      [
        [19.019642, 72.898357],
        [19.3902, 72.7829],
      ],
      [
        [19.019642, 72.898357],
        [19.3902, 72.7829],
      ],
      [
        [19.019642, 72.898357],
        [19.4425, 72.8793],
      ],
      [
        [19.019642, 72.898357],
        [19.4425, 72.8793],
      ],
      [
        [19.019642, 72.898357],
        [19.4425, 72.8793],
      ],
      [
        [19.019642, 72.898357],
        [19.3911, 72.8292],
      ],
      [
        [19.019642, 72.898357],
        [19.3911, 72.8292],
      ],
      [
        [19.019642, 72.898357],
        [19.3911, 72.8292],
      ],
      [
        [19.019642, 72.898357],
        [19.2669, 73.0355],
      ],
      [
        [19.019642, 72.898357],
        [19.2669, 73.0355],
      ],
      [
        [19.019642, 72.898357],
        [19.2639, 73.0885],
      ],
      [
        [19.019642, 72.898357],
        [19.2639, 73.0885],
      ],
      [
        [19.019642, 72.898357],
        [19.2119, 73.1128],
      ],
      [
        [19.019642, 72.898357],
        [19.2119, 73.1128],
      ],
      [
        [19.019642, 72.898357],
        [19.2119, 73.1128],
      ],
      [
        [19.019642, 72.898357],
        [18.9713, 73.1317],
      ],
      [
        [19.019642, 72.898357],
        [18.9713, 73.1317],
      ],
      [
        [19.019642, 72.898357],
        [19.3463, 73.1365],
      ],
      [
        [19.019642, 72.898357],
        [19.3463, 73.1365],
      ],
    ];

    polylineCoordinates.forEach((coordinates) => {
      const polyline = L.polyline(coordinates, {
        bubblingMouseEvents: true,
        color: 'purple',
        dashArray: null,
        dashOffset: null,
        fill: false,
        fillColor: 'purple',
        fillOpacity: 0.2,
        fillRule: 'evenodd',
        lineCap: 'round',
        lineJoin: 'round',
        noClip: false,
        opacity: 1.0,
        smoothFactor: 1.0,
        stroke: true,
        weight: 3,
      }).addTo(map);
    });
  }, []); // Empty dependency array ensures that the effect runs once when the component mounts

  return <div id='map' style={{ height: '200px' }}></div>;
};

export default Map;
