import React, { useEffect, useRef, useState } from 'react';
import Highcharts, { color } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HC_exporting from 'highcharts/modules/exporting';
import HC_exportData from 'highcharts/modules/export-data';
import HC_fullScreen from 'highcharts/modules/full-screen';
import Modal from './Modal';
HC_exporting(Highcharts);
HC_exportData(Highcharts);
HC_fullScreen(Highcharts);

const AreaChart = (props) => {

  const chartRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isZoomed, setIsZoomed] = useState(false);

  const handleCustomSizeToggle = () => {
    console.log("Custom size toggle event triggered");
    setIsModalOpen(!isModalOpen);
  };
  
  useEffect (() => {
    console.log('response in Area chart:',props?.responseData);
  },[props?.responseData]);

  const resetZoom = () => {
    if (chartRef.current) {
      chartRef.current.chart.zoomOut();
    }
    setIsZoomed(false);
  };

  try{
    const chartOptions = (isModal = false) => ({
      chart: {
        type: 'area',
        width: isModal ? 700 : (props?.comingFrom === 'dashboard' ? 350 : 350),
        height: isModal ? 380 : (props?.comingFrom === 'dashboard' ? '35%' : '35%'),
        // width: props.comingFrom === 'dashboard' ? 350 : '350',
        //  height: props.comingFrom === 'dashboard' ? '35%' :'35%',
         marginRight:10,
         backgroundColor: '#161838',
         borderRadius:'10px',
         zoomType: 'x',
         events: {
          selection: function (event) {
            if (event.resetSelection) {
              setIsZoomed(false);
            } else {
              setIsZoomed(true);
            }
          }
        }
      },
      title: {
        text: props.titleTxt,
        align:'left',
        style:{
            color:'#FFFFFF',
            fontSize:'10px'
        }
      },
      credits: {
          enabled:false
      },
      exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: [
            'customSizeToggle'           
          ]
        }
      }, 
      menuItemDefinitions: {
        customSizeToggle: {
          text: 'View Graph',
          onclick: handleCustomSizeToggle,
        },
      },

    },
      legend :{
        enabled:false
      },
      plotOptions: {
        area:{
            marker: {
                enabled: true,
                symbol: 'circle',
                radius: 2,
                states: {
                    hover: {
                        enabled: true
                    }
                }
                }   
        },
        line: {
          dataLabels: {
            enabled: false
          },        
          marker: {
            enabled: true,
            symbol: 'circle',
            radius: 2,
            states: {
                hover: {
                    enabled: true
                }
            }
            }    
        },
      },
      colors:['#137FEB','#0CF994','#A100FF'],  
      xAxis: {       
        allowDecimals: false,     
        labels:{
            style:{
                color:'#FFFFFF'
            },
        },
        categories: props?.comingFrom === 'query' ?  props?.responseData?.data?.map((item) => item[0]) : props?.responseData?.data?.map((item) => item[0]),
       
        title: {       
        },
       },
      yAxis: {
        visible:false,
        gridLineWidth: 0 ,
        labels:{
            style:{
                color:'#FFFFFF'
            }
        },
        title: {
            text: ''
        //   text: props.comingFrom === 'dashboard' ?  props.responseData.response_data.area.columns[1]
        //         : props.responseData.response_data.area.columns[1],
        },
      },
      
      
      series: props.comingFrom === 'dashboard' ?    
    [{
      name: 'USA',
      type:'line',
      data:props?.responseData?.data?.map((item) => item[1])  
    }]
      : 
      [{
        type: 'line',
        name: props?.responseData?.columns[1],    
        data:props?.responseData?.data?.map((item) => item[1]),
    }, {
        type: 'line',
        name: props?.responseData?.columns[2],
        labels: false,       
        data:props?.responseData?.data?.map((item) => item[2]),
    }] 
    });
  
     return (
      <>
        {props?.responseData && (
          <>
             <div ref={chartRef} className={props?.comingFrom === 'dashboard' ? 'areaChartContainer' :'areaChartContainer'}>
              <HighchartsReact
                        highcharts={Highcharts}
                        // options={{                
                        //  ...chartOptions
                        // }}
                        options={               
                        chartOptions()
                        }
                        ref={chartRef}
                      />
                        {isZoomed && <button onClick={resetZoom}>Reset Zoom</button>}
              </div>
              <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={chartOptions(true)}
                />
              </Modal>
          </>
        )}
     
       </>
    );
  }
  catch (error) {
    console.error('Error in Multi line chart component:', error);
    // Render a placeholder or error message
    return <div></div>;
  }
 
};

export default AreaChart;
