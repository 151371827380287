// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.sop-file-upload {
  display: inline-block;
  position: relative;
  font-family: Arial, sans-serif;
  /* overflow: hidden; */
  margin-right: 5px;
  margin-top: 5px;
  /* margin-left: 78%; */
  padding-bottom: 5px;
}

.sop-file-upload input[type="file"] {
  font-size: 0px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.sop-file-upload span {
  padding: 6px 4px 8px 0px;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid rgba(116, 123, 169, 1);
  color: rgba(255, 255, 255, 1);
  /* width:100px; */
  /* height:20px; */
  /* margin-left: 60px; */
  /* background-color: rgba(116, 123, 169, 0.3); */
}

.sop-file-upload:hover span {
  background: rgba(116, 123, 169, 1);
}


.sop-info-icon {
width: 30px;
cursor: pointer;
margin-right: 5px;
}`, "",{"version":3,"sources":["webpack://./src/SOP_NewUI/SOP_New.css"],"names":[],"mappings":";AACA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,8BAA8B;EAC9B,sBAAsB;EACtB,iBAAiB;EACjB,eAAe;EACf,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,OAAO;EACP,MAAM;EACN,UAAU;AACZ;;AAEA;EACE,wBAAwB;EACxB,eAAe;EACf,kBAAkB;EAClB,wCAAwC;EACxC,6BAA6B;EAC7B,iBAAiB;EACjB,iBAAiB;EACjB,uBAAuB;EACvB,gDAAgD;AAClD;;AAEA;EACE,kCAAkC;AACpC;;;AAGA;AACA,WAAW;AACX,eAAe;AACf,iBAAiB;AACjB","sourcesContent":["\n.sop-file-upload {\n  display: inline-block;\n  position: relative;\n  font-family: Arial, sans-serif;\n  /* overflow: hidden; */\n  margin-right: 5px;\n  margin-top: 5px;\n  /* margin-left: 78%; */\n  padding-bottom: 5px;\n}\n\n.sop-file-upload input[type=\"file\"] {\n  font-size: 0px;\n  position: absolute;\n  left: 0;\n  top: 0;\n  opacity: 0;\n}\n\n.sop-file-upload span {\n  padding: 6px 4px 8px 0px;\n  cursor: pointer;\n  border-radius: 5px;\n  border: 1px solid rgba(116, 123, 169, 1);\n  color: rgba(255, 255, 255, 1);\n  /* width:100px; */\n  /* height:20px; */\n  /* margin-left: 60px; */\n  /* background-color: rgba(116, 123, 169, 0.3); */\n}\n\n.sop-file-upload:hover span {\n  background: rgba(116, 123, 169, 1);\n}\n\n\n.sop-info-icon {\nwidth: 30px;\ncursor: pointer;\nmargin-right: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
